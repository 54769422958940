import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-geotagger',
  templateUrl: './geotagger.component.html',
  styleUrls: ['./geotagger.component.scss']
})
export class GeoTaggerComponent implements OnInit {

  categoryList=[];
  imageDetailList = [];
  complaintList = [];
  items = [];
  allPageOfItems = [];
  pageOfItems: Array<any>;
  isList:boolean=true;
  selectedCategory:string=null;
  id: number;
  responseModel: any;
  reverse: boolean = false;
  sortText:string;
  closeResult = '';
  imageId: number;
  searchText: string = "";
  loggedinUserId: string = "";
  userId: string = "";

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private commonMethods : CommonMethods, private modalService: NgbModal)
   { 
   debugger
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
  }

  ngOnInit() {
    this.getGeoTaggerImageList();
    this.getGeoTaggerCategoryList();
  }

  getGeoTaggerCategoryList(){
    this.customHttpService.getData("/GeoTagger/GetCategoryList").subscribe(data => {
      //console.log(data);
      if (data.isSuccess) {
      this.categoryList=data.responseData;
      }
    });
  }

  getGeoTaggerImageList(){
    //API Call
    this.customHttpService.getData("/GeoTagger/GetGeoTaggerList").subscribe(data => {
      //console.log(data);
      if (data.isSuccess) {
        this.imageDetailList=data.responseData;
          this.items = data.responseData;
          this.allPageOfItems = data.responseData;
      }
    });
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    var target = event.target;
    var parent = target.parentElement;
    //console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    var sortResponse= this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event, column,isASc,dataTyp);
    this.sortText=sortResponse.sortText; 
    this.reverse=sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  open(content, id) {
    debugger;
    this.imageId = id;
    this.loggedinUserId;
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deleteMap(){
    debugger;
    this.deleteImage(this.imageId,this.loggedinUserId);
    this.getGeoTaggerImageList();
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

  deleteImage(id:number, userId:string){
    var model ={
      "Id":id,
      "LoginId":userId
    }
    debugger;
    this.customHttpService.postData("/GeoTagger/DeleteImage",model).subscribe(data => {
      console.log(data);
      if (data.isSuccess) {
        this.responseModel=data.responseData;
        this.ngOnInit();
      }
      });
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  search(value: string, searchBy: string): void {
    this.isList=false;
    if (searchBy == '') {
    if (value.length >= 3) {
      this.pageOfItems = this.allPageOfItems.filter((val) => val.imageName.toLowerCase().includes(value.toLowerCase()) ||
      (val.customerId != null && val.customerId.toLowerCase().includes(value.toLowerCase())) ||
      (val.imageFileUrl != null && val.imageFileUrl.toLowerCase().includes(value.toLowerCase())) ||
      (val.createdDateDisplayValue != null && val.createdDateDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
      (val.createdBy != null && val.createdBy.toLowerCase().includes(value.toLowerCase()))
        );        
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
  }
  else {
    this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase()))); 
  }
    this.items = this.pageOfItems;
    this.isList=true;
  }

  getNewImage(){
    var url = "geotagger/detail/0";
    window.open(url, "_blank");
  }

  getImageDetail(id: number){
    debugger;
    var url = "geotagger/detail/" + id;
    window.open(url, "_blank");
  }

  checkLoading() {
   return true;
  }

}
