import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { months } from 'moment';
//import { isNumber, toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
 
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('-');
      debugger;
      return {day: parseInt(dateParts[2]), month: parseInt(dateParts[1]), year: parseInt(dateParts[0])};
    //   if (dateParts.length === 1 && isNumber(dateParts[0])) {
    //     return {day: toInteger(dateParts[0]), month: null, year: null};
    //   } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
    //     return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null};
    //   } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
    //     return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2])};
    //   }
    }
    return null;
  }
 
  format(date: NgbDateStruct): string {
      if(date!=null){
          debugger;
      }
    return date ?
        `${(date.day) ? (date.day) : ''} ${(date.month) ? (this.months[ date.month-1]) : ''} ${date.year}` :
        '';
  }

   months:any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
}


