import { Component,ViewChild, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import {SummaryCardComponent} from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{DatePipe }from '@angular/common';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
  providers: [DatePipe]
})
export class AttendanceComponent implements OnInit {
  category: string = "";
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData:any=[];
  items = [];
  pageOfItems: Array<any>;
  hbarChartLabels: any=[]; 
  hBarChartSCLabels:any=[];
  chartLabel:string="Attendance RBT %";
  searchText:string="";
  disableEnd: boolean = true;
  activeIdString:any="1";  
  exportData:boolean=true;
  isList:boolean=true;
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  resourceWiseList=[];
  barChartResourceData:any=[];
  barChartResourceLabels: any = [];
  sortText:string;
  descriptionList=[];
  selectedRemark:string="";
  selectedDescription:string; 
  closeResult = '';
  isSelectSR:boolean=false;
  
  loggedInUserName:string="";
  isSave: boolean = false;
  isSpecialChar:boolean=true;
  
  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
     private customHttpService: CustomhttpService,private commonMethods:CommonMethods,
     private modalService: NgbModal,private datePipe:DatePipe) {
    this.category = this.activatedRoute.snapshot.params.category;
    this.type = this.activatedRoute.snapshot.params.type;

    if (this.type == "RBT") {
      this.chartLabel="RBT Attendance %";
    }
    else{
      this.chartLabel="OnSite Attendance %";
    }

  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit() {

    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.loggedInUserName = localStorage.getItem("omloggedinusername");

    console.warn("description", this.commonMethods.attendanceDescriptionList);

    this.descriptionList =this.commonMethods.attendanceDescriptionList;
    this.getAttendanceList();

  }
  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length>0 || this.isList)&& this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }
  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
  }

  search(value: string, searchBy: string): void {
    this.isList=false;

    if (searchBy == '') {
    if (value.length >= 3) {
      console.log("test" + this.allPageOfItems[0].length);
      this.pageOfItems = this.allPageOfItems.filter((val) => val.requestDtDisplayValue.toLowerCase().includes(value.toLowerCase()) ||
        (val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
        (val.serviceCenter !=null &&  val.serviceCenter.toLowerCase().includes(value.toLowerCase())) ||
        (val.resourceName !=null && val.resourceName.toLowerCase().includes(value.toLowerCase())) ||
        (val.description !=null && val.description.toLowerCase().includes(value.toLowerCase())) ||
        (val.remark !=null && val.remark.toLowerCase().includes(value.toLowerCase())) ||
        (val.totalJobCount !=null && val.totalJobCount.toString().includes(value)) ||
        (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
        (val.rom != null && val.rom.toLowerCase().includes(value.toLowerCase())));
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
  }
  else {
    this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase()))); 
    
  }

    this.items = this.pageOfItems;
    this.isList=true;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);
   
    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if(item.classList!=undefined){
      item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse= this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event, column,isASc,dataTyp);
    this.sortText=sortResponse.sortText; 
    this.reverse=sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  changeCheckTxt(event:any,item){
    debugger;
    var chkRemark=  this.commonMethods.commonValidateString(event); 
    item.isSpecialChar= !chkRemark;
  }

  saveRemark(item){  
   
    if(item.description!=null){
      var chkRemark= this.commonMethods.commonValidateString(item.remark); 
      item.isSpecialChar= !chkRemark;

      if(chkRemark){
      this.customHttpService.postData("/he/UpdateAttendanceRemark" ,
      {
        "ResourceName":item.resourceName,
        "ResourceId":item.resourceId,
        "ReqDate":item.requestDate,
        "Remark":item.remark,
        "Description":item.description,
        "UserName":this.loggedInUserName,
        "UserId":this.loggedinUserId
      }).subscribe(data => {
        if (data.isSuccess) {
         this.isSave = true;

         var updatedDate =new Date();
         let latest_date =this.datePipe.transform(updatedDate, 'dd MMM yyyy');
         item.remarkLastUpdatedOnDisplayDate=latest_date.toString();
         item.remarkLastUpdatedBy=this.loggedInUserName;
      setTimeout(() => { this.isSave = false; }, 5000);
        }
      });
    }
  }
     
    }

  getAttendanceList() {
    this.isSelectSR=false;
    var callMethod: string = "";
    var summaryCallMethod:string="";
    if (this.type == "RBT") {
      callMethod = "GetOMHEAttendenceList";
      summaryCallMethod ="GetOMHEAttendenceSummary";
    }
    else {
      callMethod = "GetOMHEOnsiteAttendenceList";
      summaryCallMethod ="GetOMHEOnsiteAttendenceSummary";
      
    }
    this.isList=false;
    this.customHttpService.getDataWithAuthentication("/HE/" + callMethod + "?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList=true;
        //new changes
        // this.groupByRegion(data.responseData);
        // this.groupByROM(data.responseData);
        // this.groupByOM(data.responseData);
        // this.groupBySC(data.responseData);

        this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'regionName',true); 
        this.sortText="Region (Ascending)"; 
      });

      this.customHttpService.getDataWithAuthentication("/HE/"+summaryCallMethod+"?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category)
      .subscribe(data => {
       
        this.groupByRegion(data.responseData.regionList);
        this.groupByROM(data.responseData.romList);
        this.groupByOM(data.responseData.omList);
        this.groupBySC(data.responseData.scList);
        this.groupByResource(data.responseData.technicianList);

      });      
  }

  groupByResource(array){
    console.log("by technician");
    let resourceWiseChartData:ChartModel; 
    resourceWiseChartData = this.commonMethods.groupByResourcePercent(array,this.chartLabel);
    console.log(resourceWiseChartData);
    this.barChartResourceData = resourceWiseChartData.chartData;
    this.barChartResourceLabels = resourceWiseChartData.chartlabels;
    this.resourceWiseList = resourceWiseChartData.list;
  }
  
  groupByRegion(array) {
    let regionWiseChartData:ChartModel; 
    regionWiseChartData = this.commonMethods.groupByRegionPercent(array,this.chartLabel);
    this.barChartData = regionWiseChartData.chartData;
    this.mbarChartLabels = regionWiseChartData.chartlabels;
    this.regionWiseList = regionWiseChartData.list;
  }

  groupByROM(array) {
    let romWiseChartData:ChartModel; 
    romWiseChartData = this.commonMethods.groupByROMPercent(array,this.chartLabel);
    this.romBarChartData = romWiseChartData.chartData;
    this.romMbarChartLabels = romWiseChartData.chartlabels;
    this.romWiseList = romWiseChartData.list;
  }

  groupByOM(array) {

    let omWiseChartData:ChartModel; 
    omWiseChartData = this.commonMethods.groupByOMPercent(array,this.chartLabel);
    this.hbarChartData = omWiseChartData.chartData;
    this.hbarChartLabels = omWiseChartData.chartlabels;
    this.omWiseList = omWiseChartData.list;
  }

  groupBySC(array) {
    let scWiseChartData:ChartModel; 
    scWiseChartData = this.commonMethods.groupBySCPercent(array,this.chartLabel);
    this.hBarChartSCData = scWiseChartData.chartData;
    this.hBarChartSCLabels = scWiseChartData.chartlabels;
    this.scWiseList = scWiseChartData.list;
  }

  exportToExcel() {
    var callMethod: string = "";
    this.exportData=false;
    if (this.type == "RBT") {
      callMethod = "ExportAttendanceRBTList";
    }
    else {
      callMethod = "ExportAttendanceOnSiteList";
    }

    this.customHttpService.exportDataWithAuthentication("/HE/" + callMethod + "?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category)
      .subscribe(data => {
        this.exportData=true;
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);
        var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = "Attendance_" + this.type+"_"+this.category+"_"+ nowDate+".xls";
        anchor.href = url;
        anchor.click();

      });
  }

  onKeyPressCheckSpecialCharacter(event: any){   
    return this.commonMethods.commonCheckSpecialCharacter(event);
           
  }

  onPaste(event:any) {
    return this.commonMethods.commonRestrictPaste(event);
   
  }

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales:{yAxes: [{ ticks: {
      beginAtZero: true
  }
 
}]
} ,
series:{stacking: 'normal'},
onHover:this.graphHover,
onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems,"regionName")
  };

  public romBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
  };
  onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex=activePoints[0]._datasetIndex;      
      console.log( "active"+this.activeIdString+" "+activePoints[0]._model.label);
      
      this.activeIdString="3";     
      this.search(activePoints[0]._model.label,searchBy);
      this.searchText=activePoints[0]._model.label;       
    }
  }
  setActiveIndex(evt: any) {
    console.log("set index");
    this.activeIdString = evt.nextId;
  }
  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  public regionBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105,159,177,0.2)',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];

  public romBarChartColors : Array<any> = [    
    {
      backgroundColor: 'rgba(77,20,96,0.3)',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];

  

  public resourceBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "resourceName")
  };

  public hbarChartOptions: ChartOptions = {
    responsive: true,
    scales:{xAxes: [{ ticks: {
      beginAtZero: true
  }
}]},
onHover:this.graphHover,
onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems,"om")
    
  };
  public scHBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "serviceCenter")
  };

  graphHover(e, array) {
    if(array.length > 0) {
        e.target.style.cursor = 'pointer';
    }else {
        e.target.style.cursor = '';
    }
}


  public hbarChartType: ChartType = 'horizontalBar';
  public hbarChartLegend = true;

  public hbarChartData: ChartDataSets[] =[];
  public hBarChartSCData: ChartDataSets[] =[];

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}

