import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { inject } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class CustomhttpService {
  constructor(private httpClient: HttpClient) { }
 
  
  private domainUrl:string="https://dashboardapi.hicare.in/omdashboard/api/"
  //private domainUrl:string="https://connect.hicare.in/omdashboard/api/"
  //private domainUrl:string="http://15.207.50.230/omdashboard/api/"
  //private domainUrl: string = "https://localhost:5001/api"
  getData(relativeUrl: string) {
    var apiUrl: string = this.domainUrl + relativeUrl;
    return this.httpClient.get<any>(apiUrl);
  }

  getDataForAbsoluteUrl(absoluteUrl: string) {
    return this.httpClient.get<any>(absoluteUrl);
  }

  getDataWithAuthentication(relativeUrl: string) {
    var apiUrl: string = this.domainUrl + relativeUrl;
    var authenticationToken = localStorage.getItem("omgcbstoretoken");
    var options: HttpOption = new HttpOption();
    var headers = { 'Authorization': 'bearer ' + authenticationToken, "RequestedMonth": localStorage.getItem("SelectedMonthOM"), "LoggedInEmail": localStorage.getItem("omloggedinemail") }
    options.headers = new HttpHeaders(headers);
    return this.httpClient.get<any>(apiUrl, options);
  }

  exportDataWithAuthentication(relativeUrl: string) {
    var apiUrl: string = this.domainUrl + relativeUrl;
    var authenticationToken = localStorage.getItem("omgcbstoretoken");
    var options: HttpExportOption = new HttpExportOption();
    var headers = { 'Authorization': 'bearer ' + authenticationToken, "RequestedMonth": localStorage.getItem("SelectedMonthOM"), "LoggedInEmail":localStorage.getItem("omloggedinemail") }
    options.headers = new HttpHeaders(headers);
    var responseType = 'blob' as 'json';
    return this.httpClient.get<any>(apiUrl, { headers, responseType: 'blob' as 'json' });
  }

  postData(relativeUrl: string, body: any) {
    var apiUrl: string = this.domainUrl + relativeUrl;
    var authenticationToken = localStorage.getItem("omgcbstoretoken");
    var options: HttpOption = new HttpOption();
    var headers = { 'Authorization': 'bearer ' + authenticationToken, "RequestedMonth": localStorage.getItem("SelectedMonthOM"), "LoggedInEmail":localStorage.getItem("omloggedinemail") }
    options.headers = new HttpHeaders(headers);
    return this.httpClient.post<any>(apiUrl, body, options);
  }

  postDataWithoutAuthentication(relativeUrl: string, body: any) {
    var apiUrl: string = this.domainUrl + relativeUrl;
    var authenticationToken = localStorage.getItem("omgcbstoretoken");
    var headers = { 'Access-Control-Allow-Origin': '*' };
    var options: HttpOption = new HttpOption();
    options.headers = new HttpHeaders(headers);
    return this.httpClient.post<any>(apiUrl, body, options);
  }

  postDataWithoutAuthenticationForAbsoluteURL(absoluteUrl: string, body: any) {
    var apiUrl: string = absoluteUrl;
    var headers = { 'Access-Control-Allow-Origin': '*' };
    var options: HttpOption = new HttpOption();
    options.headers = new HttpHeaders(headers);
    return this.httpClient.post<any>(apiUrl, body, options);
  }
  getDomainUrl(){
    return this.domainUrl;
  }
}
export class HttpExportOption {
  headers: HttpHeaders;
  responseType: string;
}
export class HttpOption {
  headers: HttpHeaders;
}
