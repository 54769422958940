import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomhttpService } from '../services/customhttp.service';
import { S3loginComponent } from '../s3login/s3login.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isMobileNumberValidated:boolean=false;
  enteredMobileNumber:string="";
  enteredOTP:string="";
  accessToken:string="";
  isError:boolean=false;
  isLoading:boolean=false;
  constructor(private customHttpService:CustomhttpService, private router:Router) { 
  this.accessToken=localStorage.getItem("omgcbstoretoken");
    if(this.accessToken!=undefined && this.accessToken!=null &&  this.accessToken!="")
    {
      this.router.navigate(["dashboard"]);
    }

  }

  ngOnInit() {
    var mobileNo = localStorage.getItem("omloggedInMobileNumber");
    if(mobileNo!=undefined && mobileNo!=null && mobileNo!='')
    this.enteredMobileNumber = mobileNo;
  }

  validateMobileNo(){
    debugger
    this.isLoading=true;
    this.customHttpService.postDataWithoutAuthentication("/user/ValidateMobile",{"MobileNumber":this.enteredMobileNumber}).subscribe(data=>{
     if(data.isSuccess)
     {
      localStorage.setItem("omloggedInMobileNumber",this.enteredMobileNumber);
      console.log(data);
      this.isMobileNumberValidated = true;
      this.isError=false;
     }
     else
     {
       this.isError=true;
       this.isMobileNumberValidated = false;
     }
     this.isLoading=false;
    })


  }

  cancelLogin()
  {
    this.isMobileNumberValidated = false;
  }

  resetMobileNo(){
    this.enteredMobileNumber="";
  }

  validateOTP(){
    this.isLoading=true;
    this.isError=false;
    this.customHttpService.postDataWithoutAuthentication("/user/ValidateUser",
    {"MobileNumber":this.enteredMobileNumber,"OTP":this.enteredOTP}).subscribe(data=>{
      if(data.isSuccess)
      {
        localStorage.setItem("omloggedInMobileNumber",this.enteredMobileNumber);
        localStorage.setItem("omgcbstoretoken",data.responseData.accessToken);
        localStorage.setItem("omloggedinemail",data.responseData.email);
        localStorage.setItem("omloggedinusername",data.responseData.name);
        localStorage.setItem("omloggedinuserid",data.responseData.id);
        localStorage.setItem("omloggedinusertype",data.responseData.typeName);
       console.log(data);
       this.isLoading=false;
       debugger;
       this.router.navigate(["dashboard"]);
       //this.router.navigate(["s3login/"+data.responseData.id]);
      }
      else
      {
        //alert(data.uiMessage);
        this.isError=true;
        //this.isMobileNumberValidated = false;
        this.isLoading=false;
      }
      
  });
}
}
