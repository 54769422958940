import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { SortService } from './sortservice';

@Injectable()
export class CommonMethods {

  chartData: any = [];
  chartlabels: any = [];
  categoryWiseList: any = [];
  attendanceDescriptionList=['Buffer',
  'Holiday',
  'New Hire Induction Training',
  'Onsite Reliever',
  'Present, Bird HE, not delivering through Gantt',
  'Present, No jobs Scheduled',
  'Semi Onsite HE, not delivering through Gantt',
  'Sr HE, No Jobs Scheduled',
  'Training',
  'Unplanned leave',
  'Weekoff'
  ];

   DocumentAuditList=['Technical','RM'];

  nonOtifRemarkList= [
    'Cancellation / Shortclosure',
    'Client postponed the services against planned date / Set appointment',
    'Client Stopped Services temporarily',
    'Declared Holiday',
    'Delayed Start of Order, nondeliverable SR',
    'Extra SR  Non deliverable',
    'Free SR',
    'HiCare Missed',
    'Onsite Account',
    'Payment Pending',
    'Service delivered outside Gantt, Closure Pending',
    'SR Not Found','Wrong Schedule - Set appointment Correction Required'];

    monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


ticketDomain="https://connect.hicare.in/Freshdesk/api";

updateTicket = "https://connect.hicare.in/iotwebapi/api";

  constructor(private sortService: SortService) { }

  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
    //return Math.round( parseFloat(no));
  }

  groupByCommon(array, criteria: string) {

    var distinctRegionList = array.map(x => x[criteria]).filter((value, index, self) => self.indexOf(value) == index);
    let result = [];

    let countArry = [];

    distinctRegionList.forEach(function (a) {
      var individualRegionList = [];
      individualRegionList = array.filter(x => x[criteria] == a);

      if (individualRegionList[0].svValue == undefined) {
        result.push({
          Name: individualRegionList[0][criteria],
          value: individualRegionList.length
        })
      }
      else {
        result.push({
          Name: individualRegionList[0][criteria],
          //totalPresent: individualRegionList.length
          value: individualRegionList.reduce(function (prev, cur) {
            return (prev + cur.svValue);
          }, 0),
        });
      }
    }, Object.create(null));


    console.log(result);

    return result;
  }

  groupByRegion(array, chartLabel): ChartModel {

    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var groupResult = this.groupByCommon(array, "regionName");

    var result = this.sortService.sortListByConfiguration(groupResult, "region");
    console.warn("grpRegion", result);

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": chartLabel.toLowerCase().indexOf("count") > -1 ? result[i].value : this.roundNumber(result[i].value), "LabelName": "Total" });

    }

    this.chartData = [
      { data: countArry, label: chartLabel }
    ];

    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }


  groupByROM(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var groupResult = this.groupByCommon(array, "rom");
    var result = this.sortService.sortListByConfiguration(groupResult, "rom");

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": chartLabel.toLowerCase().indexOf("count") > -1 ? result[i].value : this.roundNumber(result[i].value), "LabelName": "Total" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  groupByOM(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];

    var groupResult = this.groupByCommon(array, "om");
    var result = this.sortService.sortListByConfiguration(groupResult, "om");

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": chartLabel.toLowerCase().indexOf("count") > -1 ? result[i].value : this.roundNumber(result[i].value), "LabelName": "Total" });

    }

    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  groupBySC(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var groupResult = this.groupByCommon(array, "scName");
    var result = this.sortService.sortListByConfiguration(groupResult, "serviceCenter");

    for (let i = result.length - 1; i >= 0; i--) {
      //console.log(result[i].sc);
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": chartLabel.toLowerCase().indexOf("count") > -1 ? result[i].value : this.roundNumber(result[i].value), "LabelName": "Total" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  groupByPercent(array) {

    let result = [];
    let countArry = [];

    array.forEach(function (a) {
      result.push({
        Name: a.xAxis,
        value: a.yAxis
      });
    }, Object.create(null));

    console.log(result);

    return result;

  }

  groupByRegionPercent(array, chartLabel): ChartModel {

    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    let result = [];
    var groupResult = this.groupByPercent(array);

    //Sort changes
    result = this.sortService.sortListByConfiguration(groupResult, "region");

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value), "LabelName": "Total Percentage" });

    }
    console.warn("regionpercent", countArry);

    this.chartData = [
      { data: countArry, label: chartLabel }
    ];

    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;


    return responseModel;
  }


  groupByROMPercent(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var groupResult = this.groupByPercent(array);

    var result = this.sortService.sortListByConfiguration(groupResult, "rom");

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value), "LabelName": "Total Percentage" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  groupByOMPercent(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];

    var groupResult = this.groupByPercent(array);
    var result = this.sortService.sortListByConfiguration(groupResult, "om");

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value), "LabelName": "Total Percentage" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  groupBySCPercent(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var groupResult = this.groupByPercent(array);
    var result = this.sortService.sortListByConfiguration(groupResult, "serviceCenter");

    for (let i = result.length - 1; i >= 0; i--) {
      //console.log(result[i].sc);
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value), "LabelName": "Total Percentage" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  plotDualBarChart(array1, array2, chartLabel1, chartLabel2): ChartModel {

    let countArry = [];
    let countArry2 = [];
    this.chartData = [];
    this.chartlabels = [];
    // var result = this.groupByPercent(array1);

    // for (let i = result.length - 1; i >= 0; i--) {
    //   this.chartlabels.push(result[i].Name);
    //   countArry.push(this.roundNumber(result[i].value));
    //   this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value), "LabelName": "Total Percentage" });
    // }

    // var result1 = this.groupByPercent(array2);

    // for (let i = result1.length - 1; i >= 0; i--) {
    //   countArry2.push(this.roundNumber(result1[i].value));

    // }

    //New change
    let value;
    var result = this.groupByPercent(array1);
    var result1 = this.groupByPercent(array2);

    result = result.sort(function (a, b) { return sort(a.Name, b.Name, true) });

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));

      var filterValue = result1.filter(x => x.Name == result[i].Name);
      if (filterValue.length > 0)
        value = filterValue[0].value;
      else
        value = 0;
      countArry2.push(this.roundNumber(value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value),"LabelName": "Total Percentage" });

    }
    //END
    this.chartData = [
      { data: countArry, label: chartLabel1 },
      { data: countArry2, label: chartLabel2 }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }
  plotDualCountBarChart(array1, array2, chartLabel1, chartLabel2, criteria): ChartModel {

    var arrayList: any = [];
    debugger;

    let countArry = [];
    let countArry2 = [];
    this.chartData = [];
    this.chartlabels = [];
    let value;
    var result = this.groupByCommon(array1, criteria);
    var result1 = this.groupByCommon(array2, criteria);

    result = result.sort(function (a, b) { return sort(a.Name, b.Name, true) });

    for (let i = result.length - 1; i >= 0; i--) {
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));

      var filterValue = result1.filter(x => x.Name == result[i].Name);
      if (filterValue.length > 0)
        value = filterValue[0].value;
      else
        value = 0;
      countArry2.push(this.roundNumber(value));
      arrayList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(value), "LabelName": "Total Count" });

    }



    // for (let i = result1.length - 1; i >= 0; i--) {
    //   var filterValue = result.filter(x => x.Name == result1[i].Name);

    //   countArry2.push(this.roundNumber(result1[i].value));
    //   arrayList.push({"DisplayName":result1[i].Name,"DisplayValue":this.roundNumber(result1[i].value),"LabelName":"Total Percentage"});       

    // }      
    this.chartData = [
      { data: countArry, label: chartLabel1 },
      { data: countArry2, label: chartLabel2 }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = arrayList;
    return responseModel;
  }

  groupByResource(array, chartLabel): ChartModel {

    console.warn("resource", array);
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var result = this.groupByCommon(array, "resourceName");

    console.warn("resource1", result);
    for (let i = result.length - 1; i >= 0; i--) {
      //console.log(result[i].sc);
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": chartLabel.toLowerCase().indexOf("count") > -1 ? result[i].value : this.roundNumber(result[i].value), "LabelName": "Total" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }

  groupByResourcePercent(array, chartLabel): ChartModel {
    this.chartData = [];
    this.chartlabels = [];
    this.categoryWiseList = [];
    let countArry = [];
    var result = this.groupByPercent(array);

    for (let i = result.length - 1; i >= 0; i--) {
      //console.log(result[i].sc);
      this.chartlabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value));
      this.categoryWiseList.push({ "DisplayName": result[i].Name, "DisplayValue": this.roundNumber(result[i].value), "LabelName": "Total Percentage" });

    }
    this.chartData = [
      { data: countArry, label: chartLabel }
    ];
    var responseModel = {} as ChartModel;
    responseModel.chartData = this.chartData;
    responseModel.chartlabels = this.chartlabels;
    responseModel.list = this.categoryWiseList;
    return responseModel;
  }  
  commonSort(listItems :any[],pageOfItem:Array<any>,isReverse:boolean,event, column: string, isASc: boolean, dataTyp?: any):SortModel{

    var sortText;
    var colName;
    if(event!=null){
    if (event.srcElement.nodeName == 'SPAN') {   
      var index=event.srcElement.innerHTML.indexOf( "<i" );
      colName=event.srcElement.innerHTML.substr(0,index);
      console.log(colName);
     }
     else{
      //colName= target.parentElement;
    if(event.target.parentElement.nodeName == 'SPAN') {
      var index=event.target.parentElement.innerHTML.indexOf( "<i" );
      colName=event.target.parentElement.innerHTML.substr(0,index);
      console.log(colName);
    }
  }
     var sortedBy;
     if(isASc){
       sortedBy="Ascending";
     }
     else{
        sortedBy="Descending";
     }
    sortText= colName+" ("+sortedBy+")"; 
    }

    if (dataTyp != "date") {
if(dataTyp=="number")
{ listItems.sort(function (a, b) { return isASc? (parseInt(a[column])-parseInt(b[column])):(parseInt(b[column])-parseInt(a[column]))  });
isReverse = !isReverse;}
else
{
      listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) });
      isReverse = !isReverse;
}
    }
    else {
      if (isASc == false) {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)
  
      }
      else {
        pageOfItem = listItems.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)
  
      }
      isReverse = !isReverse;
      listItems = pageOfItem;
    }
var response={} as SortModel;
response.listItem=listItems;
response.isReverse=isReverse;
response.sortText=sortText;
return response;
  }  

  commonSortBy2Column(listItems :any[], column: string,isASc :boolean, thenByColumn: string,thenByIsASc :boolean){
    if(column =='RemarkDateTimeString' || column =='ticketCreatedOn')
    {
      // listItems.sort(function (a, b) { return ( (b[column]==null ||b[column]=='')?-1:(a[column]==null ||a[column]=='')?1:((new Date(a[column]) > new Date(b[column]) ? 1 : (new Date(a[column]) < new Date(b[column]) ? -1 : 0))))
      //    || sort(a[thenByColumn], b[thenByColumn], thenByIsASc) });

         listItems.sort(function (a, b) { return ((new Date(a[column]) > new Date(b[column]) ? 1 : (new Date(a[column]) < new Date(b[column]) ? -1 : 0)))
         });

    }
    else
    {  
    listItems.sort(function (a, b) { return sort(a[column], b[column], isASc) || sort(a[thenByColumn], b[thenByColumn], thenByIsASc) });
    }
  }

 
  commonValidateString(str:string){

    if(str=="")return true;
    var regex = new RegExp("^[a-zA-Z0-9 \n]+$");
    
    if (regex.test(str)) {
        return true;
    }
    else 
    return false;
  }
  commonValidateMobileString(str:string){

    if(str=="")return true;
    var regex = new RegExp("^[0-9]+$");
    
    if (regex.test(str)) {
        return true;
    }
    else 
    return false;
  }

  commonCheckSpecialCharacter(event: any){   
    // var k = event.keyCode;    
    //    if((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 ||k==46 || (k >= 48 && k <= 57)){
       
    //     return true;
    //    }
    //    event.preventDefault();
    //    return false;

    var regex = new RegExp("^[a-zA-Z0-9\r\n ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }
  commonCheckForMobileNo(event: any){   
   
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }
  commonCheckForEmail(event: any){   
   
    var regex = new RegExp("^[a-zA-Z0-9\r@. ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
        return true;
    }

    event.preventDefault();
    return false;
  }
  commonRestrictPaste(event:any){
    //event.preventDefault();
    //return false;
    return true;
  }

  commonSearch(tableColumn:any,itemList:any[],value:string){

    var tableColumnObj = tableColumn;

    var newResultSet = itemList.filter(function (item) {
      //console.warn("item", item);

      for (var key in tableColumnObj) {
        if (item[key] != null && item[key] != undefined &&
          item[key].toString().toLowerCase().includes(value.toLowerCase()))
          return true;
      }
      return false;
    });

    return newResultSet;
  }
  
  calculateDayDiff(dateSent:any){
    let currentDate = new Date();
    dateSent = new Date(dateSent);    

    var diffMs = (currentDate.valueOf() - dateSent.valueOf()); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    return diffDays;
  }

  calculateDateDiff(dateSent:any){
    //debugger;
    let currentDate = new Date();
    dateSent = new Date(dateSent);    

    var diffMs = (currentDate.valueOf() - dateSent.valueOf()); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    var diffMonth=this.monthDiff(dateSent,currentDate);

   

    console.log(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes ");

    if(diffHrs<24 && diffDays==0){
      return  diffHrs+" hours ago";
    }
    else if(diffDays<=30 ){//&& diffMonth==0){
      if(diffDays==1){
        return "1 day ago";
      }
      return diffDays+" days ago";
    }
    else if(diffMonth>=1 && diffMonth<=4){
      if(diffMonth==1){
        return "1 month ago";
      }
    return diffMonth+" month ago";
    }
    else{
      return  dateSent.getDate()+" "+this.monthNames[dateSent.getMonth()]+" "+dateSent.getFullYear();
    }
  }

  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}
}


function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}

export interface ChartModel {
  chartData: [];
  chartlabels: [];
  list: [];
}

export interface SortModel{
  listItem:any[];
  isReverse:boolean;
  sortText:string;
}
