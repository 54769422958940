import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import {SummaryCardComponent} from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{DatePipe }from '@angular/common';

@Component({
  selector: 'app-b2b-otif',
  templateUrl: './b2b-otif.component.html',
  styleUrls: ['./b2b-otif.component.scss'],
  providers: [DatePipe]
})
export class B2bOtifComponent implements OnInit {
  category: string = "";
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  period: string = "";
  sortText:string="";

  items = [];
  pageOfItems: Array<any>;

  regionwiseNumber: any = [];
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  label: string = "Percentage";
  searchText:string="";
breadCrumbCategory:string="";
activeIdString: any = "1";
isList:boolean=true;
  totCount:number;
  exportData:boolean=true;
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  barChartResourceData:any=[];
  barChartResourceLabels: any = [];
  resourceWiseList = [];
  remarkList = [];
  isSave: boolean = false;
  loggedInUserName:string="";
  selectedRemark:string;
  selectedDescription:string="";
  closeResult = '';
  isSelectSR:boolean=false;

  tableColumn={nonOtifRemark:"Last Update / Description",nonOtifDescription:"Description", accountName:"Account",
  regionName:"Region",srName:"SR Name",ordernNo:"Order No",
  planDateDisplayValue:"Plan Date",completionDatetimeDisplayValue:"Completion Date",status:"Status",
  servicePlan:"Service Plan",rom:"ROM",om:"OM",scName:"Service Center"};

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, 
    private customHttpService: CustomhttpService,private commonMethods:CommonMethods,
    private modalService: NgbModal,private datePipe:DatePipe) {
    this.category = this.activatedRoute.snapshot.params.category;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;
  
    if(this.category=="B2BOTIFPer"){
      this.breadCrumbCategory="B2B Otif Percentage"
    }
    if(this.category=="B2BNonOTIFPer"){
      this.breadCrumbCategory="B2B Non Otif Percentage"
    }
  
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {
    console.log("sadsf");
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedInUserName = localStorage.getItem("omloggedinusername");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.remarkList =this.commonMethods.nonOtifRemarkList;   

    this.getB2bOtifList();
   
  }

  getNonOtifRemark(otifRemark){
    if(otifRemark==null){
      return "SR Blocked";
    }
    else return otifRemark;
  }
  getB2bOtifList(){
    this.isSelectSR=false;
    this.isList=false;
    this.customHttpService.getDataWithAuthentication("/Delivery/GetB2bOtifList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
     "&category="+this.category+ "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList=true;    
        this.totCount=this.items.length;

        // this.groupByRegion(data.responseData);
        // this.groupByROM(data.responseData);
        // this.groupByOM(data.responseData);
        // this.groupBySC(data.responseData);
        // this.groupByResource(data.responseData);
      
      this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'accountName',true); 
      this.sortText="Account Name (Ascending)";  

      this.customHttpService.getDataWithAuthentication("/Delivery/GetB2BOtifPercentage?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
     "&category="+this.category+ "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => {

        //new changes
        console.log(data.responseData.regionList);
        this.groupByRegion(data.responseData.regionList);
        this.groupByROM(data.responseData.romList);
        this.groupByOM(data.responseData.omList);
        this.groupBySC(data.responseData.scList);
        //this.groupByResource(data.responseData.technicianList);

      });
    });

  }

  checkIsNonOtif(){
    if(this.category=="B2BNonOTIFPer"){
      return true;
    }
    else
      return false;
  }
  changeCheckTxt(event:any,item){
    debugger;
    var chkRemark=  this.commonMethods.commonValidateString(event); 
    item.isSpecialChar= !chkRemark;
  }

  saveRemark(item){  
    debugger;
    if(item.nonOtifRemark!=null){
      var chkDescription= this.commonMethods.commonValidateString(item.nonOtifDescription); 
      item.isSpecialChar= !chkDescription;

      if(chkDescription){
      this.customHttpService.postData("/delivery/UpdateB2BOtifRemark" ,
      {
        "AccountNo":item.accountId,
        "NonOtifRemark":item.nonOtifRemark,
        "NonOtifDescription":item.nonOtifDescription,
        "UserName":this.loggedInUserName,
        "UserId":this.loggedinUserId,
        "SrName":item.srName
      }).subscribe(data => {
        if (data.isSuccess) {
  this.isSave = true;
  var updatedDate =new Date();
  let latest_date =this.datePipe.transform(updatedDate, 'dd MMM yyyy');
  item.remarkLastUpdatedOnDisplayDate=latest_date.toString();
  item.lastUpdatedBy=this.loggedInUserName;

      setTimeout(() => { this.isSave = false; }, 5000);
        }
      });
    }
  }
}
   
    roundNumber(no: any) {
      return parseFloat(no).toFixed(2);
    }
    groupByRegion(array) {

      let regionWiseChartData:ChartModel; 
     
     // regionWiseChartData = this.commonMethods.groupByRegion(array,"Count");
     regionWiseChartData = this.commonMethods.groupByRegionPercent(array,"Percentage");
       
      this.barChartData = regionWiseChartData.chartData;
      this.mbarChartLabels = regionWiseChartData.chartlabels;
      this.regionWiseList = regionWiseChartData.list;  
    }
  
    groupByROM(array) {
      let romWiseChartData:ChartModel;      
      //romWiseChartData = this.commonMethods.groupByROM(array,"Count");
      romWiseChartData = this.commonMethods.groupByROMPercent(array,"Percentage");
       
      this.romBarChartData = romWiseChartData.chartData;
      this.romMbarChartLabels = romWiseChartData.chartlabels;
      this.romWiseList = romWiseChartData.list;
    }
  
    groupByOM(array) {
  
      let omWiseChartData:ChartModel; 
     
      //omWiseChartData = this.commonMethods.groupByOM(array,"Count");
      omWiseChartData = this.commonMethods.groupByOMPercent(array,"Percentage");
       
      this.hbarChartData = omWiseChartData.chartData;
      this.hbarChartLabels = omWiseChartData.chartlabels;
      this.omWiseList = omWiseChartData.list;
    }
  
    groupBySC(array) {
      let scWiseChartData:ChartModel; 
     
      //scWiseChartData = this.commonMethods.groupBySC(array,"Count");
      scWiseChartData = this.commonMethods.groupBySCPercent(array,"Percentage");
     
      this.hBarChartSCData = scWiseChartData.chartData;
      this.hBarChartSCLabels = scWiseChartData.chartlabels;
      this.scWiseList = scWiseChartData.list;
    }
    groupByResource(array){
      let resourceWiseChartData:ChartModel; 
    
      resourceWiseChartData = this.commonMethods.groupByResource(array,"Count");
      
      this.barChartResourceData = resourceWiseChartData.chartData;
      this.barChartResourceLabels = resourceWiseChartData.chartlabels;
      this.resourceWiseList = resourceWiseChartData.list;
    }
   
    checkLoading() {
      if (this.items != undefined && this.items != null&& (this.items.length>0 || this.isList)&& this.exportData) {
        return true;
      }
      else {
        return false;
      }
    }

    search(value: string, searchBy: string): void {
      this.isList=false;
      if (searchBy == '') {
      if (value.length >= 3) {
        this.pageOfItems = this.allPageOfItems.filter((val) =>
        (val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
        (val.rom!=null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
          (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
        (val.accountName != null && val.accountName.toLowerCase().includes(value.toLowerCase())) ||
        (val.scName!=null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
        // (val.resourceName!=null && val.resourceName.toLowerCase().includes(value.toLowerCase())) ||       
        (val.planDateDisplayValue!=null && val.planDateDisplayValue.toLowerCase().includes(value.toLowerCase()))||
        (val.completionDatetimeDisplayValue!=null && val.completionDatetimeDisplayValue.toLowerCase().includes(value.toLowerCase()))||
        (val.ordernNo != null && val.ordernNo.toLowerCase().includes(value.toLowerCase())) ||
        (val.srName != null && val.srName.toLowerCase().includes(value.toLowerCase())) ||
        (val.status != null && val.status.toLowerCase().includes(value.toLowerCase())) ||      
        (val.nonOtifRemark != null && val.nonOtifRemark.toLowerCase().includes(value.toLowerCase())) ||      
        // (val.nonOtifRemarkBy != null && val.nonOtifRemarkBy.toLowerCase().includes(value.toLowerCase())) ||      
        (val.nonOtifDescription!=null&& val.nonOtifDescription.toLowerCase().includes(value.toLowerCase()))||
        (val.servicePlan !=null && val.servicePlan.toLowerCase().includes(value.toLowerCase())));
      }
      else {
        this.pageOfItems = this.allPageOfItems;
      }
    }
    else{
      this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
    }
      this.items = this.pageOfItems;
      this.isList=true;
    }
  
    sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
      //sortCol(column: string): void {
        debugger;
      console.log("sort" + isASc);
  
      var target = event.target;
      var parent = target.parentElement;
      var colName="";
  
      if (event.srcElement.nodeName == 'SPAN') {   
        var index=event.srcElement.innerHTML.indexOf( "<i" );
        colName=event.srcElement.innerHTML.substr(0,index);
        console.log(colName);
       }
       var sortedBy;
       if(isASc){
         sortedBy="Ascending";
       }
       else{
          sortedBy="Descending";
       }
      parent.parentElement.classList.remove("selectBackColor");
      parent.parentElement.childNodes.forEach(function (item) {
        if(item.classList!=undefined){
        item.classList.remove("selectBackColor");
        }
      });
  
      parent.classList.add("selectBackColor");
  
      var sortResponse=  this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event,column,isASc,dataTyp);
      this.sortText=sortResponse.sortText; 
      this.reverse=sortResponse.isReverse;
      this.onChangePage(sortResponse.listItem.slice(0, 20));
    }
    exportToExcel(){
      var zrmId = localStorage.getItem("SelectedZRMId");
      var rmId = localStorage.getItem("SelectedRMId");
      var region = localStorage.getItem("SelectedRegion");    
    
        this.exportData=false;
      this.customHttpService.exportDataWithAuthentication("/Delivery/ExportB2bOtifList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category="+this.category+ "&type=" + this.type + "&period=" + this.period)
      .subscribe(data=>{
        this.exportData=true;
        let b: any = new Blob([data], {type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);
        
        var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = this.breadCrumbCategory +"_"+this.type+"("+ this.period+")_"+nowDate+".xls";
        anchor.href = url;
        anchor.click();
    
      });
    
    }
    onKeyPressCheckSpecialCharacter(event: any){
      return this.commonMethods.commonCheckSpecialCharacter(event);
             
    }
    onPaste(event:any) {
      return this.commonMethods.commonRestrictPaste(event);
     
    }
      public barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName") 
      };
    
      graphHover(e, array) {
        if(array.length > 0) {
            e.target.style.cursor = 'pointer';
        }else {
            e.target.style.cursor = '';
        }
    }
    public romBarChartOptions: any = {
      scaleShowVerticalLines: false,
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
    };
    
    onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
      const chart = activeElems[0]._chart;
      const activePoints = chart.getElementAtEvent(clickEvt);
      console.log(activePoints);
    
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const clickedPeridIndex = activePoints[0]._datasetIndex;
        console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);
    
        this.activeIdString = "3";
        this.search(activePoints[0]._model.label, searchBy);
        this.searchText = activePoints[0]._model.label;
       
    
      }
    }
    public resourceBarChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "resourceName")
    };
    
    setActiveIndex(evt: any) {
      console.log("set index");
      this.activeIdString = evt.nextId;
    }
    
      public barChartType: string = 'bar';
      public barChartLegend: boolean = false;
    
      public regionBarChartColors: Array<any> = [
        {
          backgroundColor: 'rgba(105,159,177,0.2)',
          borderColor: 'rgba(105,159,177,1)',
          pointBackgroundColor: 'rgba(105,159,177,1)',
          pointBorderColor: '#fafafa',
          pointHoverBackgroundColor: '#fafafa',
          pointHoverBorderColor: 'rgba(105,159,177)'
        }
      ];
    
      public romBarChartColors: Array<any> = [
        {
          backgroundColor: 'rgba(77,20,96,0.3)',
          borderColor: 'rgba(77,20,96,1)',
          pointBackgroundColor: 'rgba(77,20,96,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(77,20,96,1)'
        }
      ];
    
      // events
      public chartClicked(e: any): void {
        //console.log(e);
      }
    
      public chartHovered(e: any): void {
        //console.log(e);
      }
    
      public hbarChartOptions: ChartOptions = {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "om")
      };
    
      public scHBarChartOptions: ChartOptions = {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "scName")
      };
    
    
      public hbarChartType: ChartType = 'horizontalBar';
      public hbarChartLegend = true;
    
      public hbarChartData: ChartDataSets[] = [];
      public hBarChartSCData: ChartDataSets[] = [];
    
    }
    
    function sort(a, b, asc) {
      var result;
    
      /* Default ascending order */
      if (typeof asc == "undefined") asc = true;
    
      //console.log("sort " + a + " " + b);
      if (asc) {
        if (a == null) return -1;
        if (b == null) return 1;
      }
      else {
        if (b == null) return -1;
        if (a == null) return 1;
      }
      if (a === null && b === null) return 0;
    
      result = a - b;
    
      if (isNaN(result)) {
        return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
      }
      else {
        return (asc) ? result : -result;
      }
    }
    
    
    