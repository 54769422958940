import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import {SummaryCardComponent} from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';

@Component({
  selector: 'app-he-productivity',
  templateUrl: './he-productivity.component.html',
  styleUrls: ['./he-productivity.component.scss']
})
export class HeProductivityComponent implements OnInit {

  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  category: string = "";
  period: string = "";
  exportData:boolean=true;
  isList:boolean=true;
  totCount:number=0;
  totalLabel:string;

  items = [];
  pageOfItems: Array<any>;

  amtIn: number = 100000;
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  searchText: string = "";
  chartLabel: string = "Total SV Value";
  activeIdString: any = "1";
  bar1Label="Actual Hour";
  bar2Label="Planned Hour";
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  barChartResourceData:any=[];
  barChartResourceLabels: any = [];
  resourceWiseList=[];
  sortText:string;
  
  // tableColumn={regionName:"Region",taskAssigntStartDisplayDate:"Task Assign Start", taskAssigntEndDisplayDate:"Task Assign End",
  // totalActualWork:"Actual Duration(Min)",originalAssStartdatetimeDisplayValue:"Original Assignment Start",originalAssEnddatetimeDisplayValue:"Original Assignment End",
  // totalPlanWork:"Planned Duration(Min)",completionDatetimeDisplayValue:"Completion Date",order_Number:"Order No",svValue:"SV Value",scheduling_Status:"Scheduling Status",service_Plan:"Service Plan",
  // rom:"ROM",om:"OM",scName:"Service Center",resourceName:"Resource Name"};


  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private commonMethods:CommonMethods) {
  
    this.category = this.activatedRoute.snapshot.params.category;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;

    this.totalLabel="Total SV"
    this.chartLabel="Avg SV Value";
    if(this.type=="AvgHour"){
      this.chartLabel="Avg Man Hour";
      this.totalLabel="Total Duration"
    }
    if(this.type=="AvgTask"){
      this.totalLabel="Total Task"
    this.chartLabel="Avg Task";
    }

   }

   onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit() {

    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");    

    this.getHEProductivityList();
  }

  getHEProductivityList(){
    this.isList=false;

    this.customHttpService.getDataWithAuthentication("/Task/GetTaskDeliveryList?userId=" + this.loggedinUserId +
    "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
    "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
    .subscribe(data => {   
      this.items = data.responseData;
      this.allPageOfItems = data.responseData;
     
      this.totCount=  this.items.reduce(function(prev,cur){
        return prev+cur.svValue;
      },0);
      if(this.type=="AvgHour"){
      this.totCount=(this.items.reduce(function(prev,cur){
        return prev+cur.totalActualWork;
       },0))/60;
      }
      this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'regionName',true); 
      this.sortText="Region (Ascending)"; 
      console.log(this.totCount);
    });

      this.customHttpService.getDataWithAuthentication("/Task/GetHEProductivitySummary?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => { 
       
         //new changes
         this.groupByRegion(data.responseData);
         this.groupByROM(data.responseData);
         this.groupByOM(data.responseData);
         this.groupBySC(data.responseData);
         this.groupByResource(data.responseData);

         this.isList=true;       
      });       
    }   

    groupByRegion(array) {
      
      let regionWiseChartData:ChartModel; 
      
      regionWiseChartData = this.commonMethods.groupByRegionPercent(array[0].regionList,this.chartLabel);     
          
      this.regionWiseList = regionWiseChartData.list;

      if(this.type=="AvgHour"){
        this.plotRegionGraph(array);
      }
      else
      {
      this.barChartData = regionWiseChartData.chartData;
      this.mbarChartLabels = regionWiseChartData.chartlabels;
      }
  
    }

    groupByROM(array) {
      let romWiseChartData:ChartModel;      
        romWiseChartData = this.commonMethods.groupByROMPercent(array[0].romList,this.chartLabel);  
     
      this.romWiseList = romWiseChartData.list;

      if(this.type=="AvgHour"){
      this.plotROMGraph(array);
      }
      else{
       this.romBarChartData = romWiseChartData.chartData;
       this.romMbarChartLabels = romWiseChartData.chartlabels;
      }
    }
  
    groupByOM(array) {

      let omWiseChartData:ChartModel; 
     
        omWiseChartData = this.commonMethods.groupByOMPercent(array[0].omList,this.chartLabel);     
      
    
      this.omWiseList = omWiseChartData.list;

      if(this.type=="AvgHour"){
        this.plotOMGraph(array);
        }
     else{
       this.hbarChartData = omWiseChartData.chartData;
      this.hbarChartLabels = omWiseChartData.chartlabels;
     }     
    }
  
    groupBySC(array) {
      let scWiseChartData:ChartModel; 
     
      scWiseChartData = this.commonMethods.groupBySCPercent(array[0].scList,this.chartLabel);   
      
      this.scWiseList = scWiseChartData.list;

      if(this.type=="AvgHour"){
      this.plotSCGraph(array);
      }
      else{
       this.hBarChartSCData = scWiseChartData.chartData;
      this.hBarChartSCLabels = scWiseChartData.chartlabels;
      }
    }  
  
    groupByResource(array){
      
      console.log("by technician");
      let resourceWiseChartData:ChartModel; 
      resourceWiseChartData = this.commonMethods.groupByResourcePercent(array[0].technicianList,this.chartLabel);
      console.log(resourceWiseChartData);
      // this.barChartResourceData = resourceWiseChartData.chartData;
      // this.barChartResourceLabels = resourceWiseChartData.chartlabels;
      // this.resourceWiseList = resourceWiseChartData.list;

      this.resourceWiseList = resourceWiseChartData.list;

      if(this.type=="AvgHour"){
      this.plotResourceGraph(array);
      }
      else{
       this.barChartResourceData = resourceWiseChartData.chartData;
      this.barChartResourceLabels = resourceWiseChartData.chartlabels;
      }
    }

    roundNumber(no: any) {
      return parseFloat(no).toFixed(2);
    }
    midpointRound(no:any){
      return parseFloat(no).toFixed();
    }

    groupByPercent(array) {

      let result = [];
      let countArry = [];
  
      array.forEach(function (a) {
        result.push({
          Name: a.xAxis,
          value: a.yAxis
        });
      }, Object.create(null));
  
      console.log(result);
  
      return result;    
  
    }
    
    generateBarChartData(array){
      let countArry = [];
      var result = this.groupByPercent(array);
  
      for (let i = result.length - 1; i >= 0; i--) {
        this.mbarChartLabels.push(result[i].Name);
        countArry.push(this.roundNumber(result[i].value)); 
      } 

      return countArry;
    }

    plotRegionGraph(array) {    
      let regionWiseChartData:ChartModel; 
      regionWiseChartData=  this.commonMethods.plotDualBarChart(array[0].regionList,array[1].regionList,this.bar1Label,this.bar2Label);
     
      this.barChartData=regionWiseChartData.chartData;
      this.mbarChartLabels=regionWiseChartData.chartlabels;
      
    }
    plotROMGraph(array) {    
      
      let romWiseChartData:ChartModel; 
      romWiseChartData=  this.commonMethods.plotDualBarChart(array[0].romList,array[1].romList,this.bar1Label,this.bar2Label);
     
      this.romBarChartData=romWiseChartData.chartData;
      this.romMbarChartLabels=romWiseChartData.chartlabels;

    }
    plotOMGraph(array) {    
      
      let omWiseChartData:ChartModel; 
      omWiseChartData=  this.commonMethods.plotDualBarChart(array[0].omList,array[1].omList,this.bar1Label,this.bar2Label);
     
      this.hbarChartData=omWiseChartData.chartData;
      this.hbarChartLabels=omWiseChartData.chartlabels;      
    }
  
    plotSCGraph(array) {    
      
      let scWiseChartData:ChartModel; 
      scWiseChartData=  this.commonMethods.plotDualBarChart(array[0].scList,array[1].scList,this.bar1Label,this.bar2Label);
     
      this.hBarChartSCData=scWiseChartData.chartData;
      this.hBarChartSCLabels=scWiseChartData.chartlabels;      
    }
    plotResourceGraph(array) {    
      
      let resourceWiseChartData:ChartModel; 
      resourceWiseChartData=  this.commonMethods.plotDualBarChart(array[0].technicianList,array[1].technicianList,this.bar1Label,this.bar2Label);
     
      this.barChartResourceData=resourceWiseChartData.chartData;
      this.barChartResourceLabels=resourceWiseChartData.chartlabels;      
    }

    checkLoading() {
      if (this.items != undefined && this.items != null && (this.items.length>0 || this.isList)&& this.exportData) {
        return true;
      }
      else {
        return false;
      }
    }
    search(value: string, searchBy: string): void {
      this.isList=false;
      if (searchBy == '') {
      if (value.length >= 3) {
        this.pageOfItems = this.allPageOfItems.filter((val) =>
        (val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
        (val.rom!=null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
        (val.om!=null && val.om.toLowerCase().includes(value.toLowerCase())) ||
        (val.scName!=null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
        (val.resourceName!=null && val.resourceName.toLowerCase().includes(value.toLowerCase())) ||
        (val.svValue!=null && val.svValue.toString().includes(value)) ||
  
          (val.originalAssStartdatetimeDisplayValue != null && val.originalAssStartdatetimeDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
          (val.originalAssEnddatetimeDisplayValue!=null && val.originalAssEnddatetimeDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
  
          ( val.taskAssigntStartDisplayDate!=null && val.taskAssigntStartDisplayDate.toLowerCase().includes(value.toLowerCase())) ||
          ( val.taskAssigntEndDisplayDate!=null && val.taskAssigntEndDisplayDate.toLowerCase().includes(value.toLowerCase())) ||
          ( val.totalActualWork!=null && val.totalActualWork.toString().includes(value)) ||
          (val.totalPlanWork!=null && val.totalPlanWork.toString().includes(value)) ||
       
        // (val.onSiteDatetime!=null && val.onSiteDatetime.toLowerCase().includes(value.toLowerCase())) ||
        (val.completionDatetimeDisplayValue!=null && val.completionDatetimeDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
        (val.order_Number != null && val.order_Number.toLowerCase().includes(value.toLowerCase())) ||
        (val.scheduling_Status != null && val.scheduling_Status.toLowerCase().includes(value.toLowerCase())) ||      
        (val.service_Plan !=null && val.service_Plan.toLowerCase().includes(value.toLowerCase())));
      }
      else {
        this.pageOfItems = this.allPageOfItems;
      }
    }
    else{
      this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
    }
      this.items = this.pageOfItems;
      this.isList=true;
    }
  
  
    sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
      //sortCol(column: string): void {
      console.log("sort" + isASc);
  
      var target = event.target;
      var parent = target.parentElement;
      console.log(target);
      console.log(parent.parentElement.classList);
      parent.parentElement.classList.remove("selectBackColor");
      parent.parentElement.childNodes.forEach(function (item) {
        item.classList.remove("selectBackColor");
      });
  
      parent.classList.add("selectBackColor");
  
      var sortResponse= this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event, column,isASc,dataTyp);
      this.sortText=sortResponse.sortText; 
      this.reverse=sortResponse.isReverse
      this.onChangePage(sortResponse.listItem.slice(0, 20));
      }
      exportToExcel() {
        this.exportData=false;
        this.customHttpService.exportDataWithAuthentication("/Task/ExportTaskDeliveryData?userId=" + this.loggedinUserId +
          "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
          "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
          .subscribe(data => {
            this.exportData=true;
            let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
            var url = window.URL.createObjectURL(b);
            //window.open(url);
    
            var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
            var anchor = document.createElement("a");
            anchor.download = "HEProductivity_" +this.category +"_"+this.type+"("+ this.period+")_"+nowDate+".xls";
            anchor.href = url;
            anchor.click();
    
          });    
      }
      public barChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName")
      };
    
      graphHover(e, array) {
        if(array.length > 0) {
            e.target.style.cursor = 'pointer';
        }else {
            e.target.style.cursor = '';
        }
    }
      public romBarChartOptions: any = {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
      };
    
      onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
        const chart = activeElems[0]._chart;
        const activePoints = chart.getElementAtEvent(clickEvt);
        console.log(activePoints);
    
        if (activePoints.length > 0) {
          const clickedElementIndex = activePoints[0]._index;
          const clickedPeridIndex = activePoints[0]._datasetIndex;
          console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);
    
          this.search(activePoints[0]._model.label, searchBy);
          this.searchText = activePoints[0]._model.label;
          this.activeIdString = "3";
    
        }
      }
    
      public resourceBarChartOptions: ChartOptions = {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "resourceName")
      };

      setActiveIndex(evt: any) {
        console.log("set index");
        this.activeIdString = evt.nextId;
      }
    
      public barChartType: string = 'bar';
      public barChartLegend: boolean = true;
    
      public regionBarChartColors: Array<any> = [
        {
          backgroundColor: 'rgba(105,159,177,0.2)',
          borderColor: 'rgba(105,159,177,1)',
          pointBackgroundColor: 'rgba(105,159,177,1)',
          pointBorderColor: '#fafafa',
          pointHoverBackgroundColor: '#fafafa',
          pointHoverBorderColor: 'rgba(105,159,177)'
        }
      ];
    
      public romBarChartColors: Array<any> = [
        {
          backgroundColor: 'rgba(77,20,96,0.3)',
          borderColor: 'rgba(77,20,96,1)',
          pointBackgroundColor: 'rgba(77,20,96,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(77,20,96,1)'
        }
      ];
    
      // events
      public chartClicked(e: any): void {
        console.log(e);
      }
    
      public chartHovered(e: any): void {
        console.log(e);
      }  
    
      public hbarChartOptions: ChartOptions = {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "om")
      };
      public scHBarChartOptions: ChartOptions = {
        responsive: true,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onHover:this.graphHover,
        onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "scName")
      };
    
      onHBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
        const chart = activeElems[0]._chart;
        const activePoints = chart.getElementAtEvent(clickEvt);
        console.log(activePoints);
    
        if (activePoints.length > 0) {
          const clickedElementIndex = activePoints[0]._index;
          const clickedPeridIndex = activePoints[0]._datasetIndex;
          console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);
    
          this.activeIdString = "3";
          this.search(activePoints[0]._model.label, searchBy);
          this.searchText = activePoints[0]._model.label;
        }
      }
      public hbarChartType: ChartType = 'horizontalBar';
      public hbarChartLegend = true;
    
      public hbarChartData: ChartDataSets[] = [];
      public hBarChartSCData: ChartDataSets[] = [];
    
    }
    
    function sort(a, b, asc) {
      
      var result;
    
      /* Default ascending order */
      if (typeof asc == "undefined") asc = true;
    
      //console.log("sort " + a + " " + b);
      if (asc) {
        if (a == null) return -1;
        if (b == null) return 1;
      }
      else {
        if (b == null) return -1;
        if (a == null) return 1;
      }
      if (a === null && b === null) return 0;
    
      result = a - b;
  
      console.log( result);
    
      if (isNaN(result)) {
        return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
      }
      else {
        return (asc) ? result : -result;
      }
    }   
  

