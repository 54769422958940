import { HttpClient } from '@angular/common/http';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { CommonProperties } from './commonproperties';

@Injectable({
  providedIn: 'root'
})

export class SortService {

 sortList: any[];

 constructor(private httpClient: HttpClient, private customHttpService: CustomhttpService) { }


  getSortConfiguration() {
    this.customHttpService.getDataWithAuthentication("/User/GetOMSortConfiguration").subscribe(data => {
      this.sortList = data.responseData;

      console.log(this.sortList);
      return this.sortList;
    });

  }

  sortListByConfiguration(array,criteria) {
    //New change for sorting

    var newResult = [];
    var filterRecord=[];    
      
      //debugger;
      //alert(CommonProperties.property);
      this.sortList  =  JSON.parse(localStorage.getItem("SortOrderList"));      
      //console.warn("sort" + CommonProperties.getSortList());

      if(this.sortList.length==0||this.sortList==undefined)return array;

      var distinctList = this.sortList.map(x => x[criteria]).filter((value, index, self) => self.indexOf(value) == index);

      distinctList.forEach(function (a) {
        if(a!=null){
        filterRecord = array.filter((val) => val.Name == a);
        if(filterRecord!=undefined && filterRecord.length>0){
        newResult.push({
          Name: filterRecord[0].Name,
          value: filterRecord[0].value
        });
      }
    }
      }, Object.create(null));

      filterRecord=[];
      var chkCount=0;
      array.forEach(function(a) {
        filterRecord= newResult.filter(val=>val.Name==a.Name);
        //if(chkCount>0)newResult= newResult.reverse();

        if(filterRecord.length==0){
          chkCount++;
          newResult.push({
            Name: a.Name,
            value: a.value
          });
        }
        
      });     
      newResult= newResult.reverse();
      
      console.warn("sort list", newResult);
      return newResult;   
    return newResult;
    //end
  }
}
