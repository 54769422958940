import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { LoginComponent } from './login/login.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { CustomhttpService } from './services/customhttp.service';
import { ComplaintComponent } from './data-tables/complaint/complaint.component';
import { SrbaseComponent } from './data-tables/srbase/srbase.component';
import { JwPaginationModule } from 'jw-angular-pagination';
import { HttpErrorInterceptor } from './services/http-interceptor';
import { RedaccountComponent } from './data-tables/redaccount/redaccount.component';
import { InvoicesComponent } from './data-tables/invoices/invoices.component';
import { AttendanceComponent } from './data-tables/attendance/attendance.component';
import { KarmaComponent } from './data-tables/karma/karma.component';
import { NpsComponent } from './data-tables/nps/nps.component';
import { DeliveryComponent } from './data-tables/delivery/delivery.component';
import { ServiceDeliveryComponent } from './data-tables/service-delivery/service-delivery.component';
import { ChemicalComsumptionComponent } from './data-tables/chemical-comsumption/chemical-comsumption.component';
import { DeliveryPercentComponent } from './data-tables/delivery-percent/delivery-percent.component';
import { MissDeliveryComponent } from './data-tables/miss-delivery/miss-delivery.component';
import { JeopardyComponent } from './data-tables/jeopardy/jeopardy.component';
import { ShortClosureComponent } from './data-tables/short-closure/short-closure.component';
import { TaskDeliveryComponent } from './data-tables/task-delivery/task-delivery.component';
import { SummaryCardComponent } from './data-tables/summary-card/summary-card.component';
import { ObdNpsComponent } from './data-tables/obd-nps/obd-nps.component';
import {CommonMethods} from './services/commonmethods';
import { HeProductivityComponent } from './data-tables/he-productivity/he-productivity.component';
import { OrderComponent } from './data-tables/order/order.component';
import { LeaderBoardComponent } from './leader-board/leader-board.component';
import { CommonProperties } from './services/commonproperties';
import { B2bOtifComponent } from './data-tables/b2b-otif/b2b-otif.component';
import { OmromMappingComponent } from './data-tables/omrom-mapping/omrom-mapping.component';
import { TicketsComponent } from './data-tables/tickets/tickets.component';
import { TicketsDetailComponent } from './data-tables/tickets-detail/tickets-detail.component';
import { TicketsInteractionDetailComponent } from './data-tables/tickets-interaction-detail/tickets-interaction-detail.component';
import { FileUploadModule } from 'ng2-file-upload';
import { ControlroomAdherenceComponent } from './data-tables/controlroom-adherence/controlroom-adherence.component';
import {GeoTaggerComponent} from './data-tables/geotagger/geotagger.component';
import {GeoTaggerDetailComponent} from './data-tables/geotagger-detail/geotagger-detail.component';
import { UploadFileComponent } from './data-tables/upload-file/upload-file.component';
import { DocumentMappingComponent } from './data-tables/document-mapping/document-mapping.component';

//import { EnlargeimageComponent } from './enlargeimage/enlargeimage.component';
import { DocumentUploaderComponent } from './data-tables/document-uploader/document-uploader.component';
import { S3loginComponent } from './s3login/s3login.component';
import { GoogleAuthComponent } from './googleAuthLogin/google-auth/google-auth.component';
//import { PulseReportComponent } from './pulse-report/pulse-report.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    SpinnerComponent,
    ContentAnimateDirective,
    LoginComponent,
    ComplaintComponent,
    SrbaseComponent,
    RedaccountComponent,
    InvoicesComponent,
    AttendanceComponent,
    KarmaComponent,
    NpsComponent,
    DeliveryComponent,
    ServiceDeliveryComponent,
    ChemicalComsumptionComponent,
    DeliveryPercentComponent,
    MissDeliveryComponent,
    JeopardyComponent,
    ShortClosureComponent,
    TaskDeliveryComponent,
    SummaryCardComponent,
    ObdNpsComponent,
    HeProductivityComponent,
    OrderComponent,
    LeaderBoardComponent,
    B2bOtifComponent,
    OmromMappingComponent,
    TicketsComponent,
    TicketsDetailComponent,
    TicketsInteractionDetailComponent,
    TicketsInteractionDetailComponent,
    ControlroomAdherenceComponent,
    GeoTaggerComponent,
    GeoTaggerDetailComponent,
    UploadFileComponent,
    DocumentMappingComponent,
    //EnlargeimageComponent,
    DocumentUploaderComponent,
    S3loginComponent,
    GoogleAuthComponent
    
    //PulseReportComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    JwPaginationModule,
    FileUploadModule
  ],
  providers: [ThemeService,CustomhttpService,CommonProperties,{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },CommonMethods],
  bootstrap: [AppComponent]
})
export class AppModule { }
