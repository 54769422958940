import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import {SummaryCardComponent} from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss']
})
export class ComplaintComponent implements OnInit {

  category: string = "";
  period: string = "";
  type: string = "";
  complaintList: any = [];
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  reverse: boolean = false;

  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  deliveryList: any = [];
  complaintPerList: any = [];
  deliveryPerList: any = [];
  searchText:string="";
  chartLabel:string="";
  activeIdString: any = "1";
  exportData:boolean=true;
  isList:boolean=true;
  count:number;
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  barChartResourceData:any=[];
  barChartResourceLabels: any = [];
  resourceWiseList=[];
sortText:string;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService,private commonMethods : CommonMethods) {
    this.category = this.activatedRoute.snapshot.params.category;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;

    if(this.category == "CompPer"||this.category=="OpenCompPer"){
      this.chartLabel="Complaint %";      
    }
    else{
      this.chartLabel="Complaint Count";
    }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit(): void {
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");

    this.getComplaintList();
  }

  getComplaintList() {

    this.isList=false;
    this.customHttpService.getDataWithAuthentication("/Complaint/GetOMComplaintList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category + "&type=" + this.type + "&period=" + this.period).subscribe(data => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          this.complaintList = data.responseData;
          this.items = data.responseData;
          this.allPageOfItems = data.responseData;
          this.isList=true;
          this.count=this.items.length;

          this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'accountName',true); 
          this.sortText="Account Name (Ascending)"; 
          
          if(this.category=="CompNo" ||this.category=="OpenCompNo"){
            this.groupByRegion(this.complaintList);
            this.groupByROM(this.complaintList);
            this.groupByOM(this.complaintList);
            this.groupBySC(this.complaintList);
            this.groupByResource(this.complaintList) 
          }
        }
      });

          if (this.category == "CompPer" ||this.category=="OpenCompPer") {
            this.customHttpService.getDataWithAuthentication("/Complaint/GetOMComplaintPercentList?userId=" + this.loggedinUserId +
              "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
              "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
              .subscribe(data => {
                //this.complaintPerList = data.responseData.complaintModel;
                //this.deliveryPerList = data.responseData.deliveryModel;
                this.groupByRegion(data.responseData.regionList);
                this.groupByROM(data.responseData.romList);
                this.groupByOM(data.responseData.omList);
                this.groupBySC(data.responseData.scList);
                this.groupByResource( data.responseData.technicianList);

                console.log("delivery" + this.deliveryList.length);
              });
          }

  }
 
  groupByRegion(array) {

    let regionWiseChartData:ChartModel; 
    if(this.category == "CompPer" ||this.category=="OpenCompPer"){
      regionWiseChartData = this.commonMethods.groupByRegionPercent(array,this.chartLabel);
    }
    else
    {
    regionWiseChartData = this.commonMethods.groupByRegion(array,this.chartLabel);
    }
    this.barChartData = regionWiseChartData.chartData;
    this.mbarChartLabels = regionWiseChartData.chartlabels;
    this.regionWiseList = regionWiseChartData.list;

  }

  groupByROM(array) {
    let romWiseChartData:ChartModel; 
    if(this.category == "CompPer" ||this.category=="OpenCompPer"){
      romWiseChartData = this.commonMethods.groupByROMPercent(array,this.chartLabel);
    }else
    {
    romWiseChartData = this.commonMethods.groupByROM(array,this.chartLabel);
    }
    this.romBarChartData = romWiseChartData.chartData;
    this.romMbarChartLabels = romWiseChartData.chartlabels;
    this.romWiseList = romWiseChartData.list;
  }

  groupByOM(array) {

    let omWiseChartData:ChartModel; 
    if(this.category == "CompPer" ||this.category=="OpenCompPer"){
      omWiseChartData = this.commonMethods.groupByOMPercent(array,this.chartLabel);
    }
    else
    {
    omWiseChartData = this.commonMethods.groupByOM(array,this.chartLabel);
    }
    this.hbarChartData = omWiseChartData.chartData;
    this.hbarChartLabels = omWiseChartData.chartlabels;
    this.omWiseList = omWiseChartData.list;
  }

  groupBySC(array) {
    let scWiseChartData:ChartModel; 
    if(this.category == "CompPer" ||this.category=="OpenCompPer"){
      scWiseChartData = this.commonMethods.groupBySCPercent(array,this.chartLabel);
    }
    else
    {
    scWiseChartData = this.commonMethods.groupBySC(array,this.chartLabel);
    }
    this.hBarChartSCData = scWiseChartData.chartData;
    this.hBarChartSCLabels = scWiseChartData.chartlabels;
    this.scWiseList = scWiseChartData.list;
  }

  groupByResource(array) {
    let resourceWiseChartData:ChartModel; 
    if(this.category == "CompPer" ||this.category=="OpenCompPer"){
      resourceWiseChartData = this.commonMethods.groupByResourcePercent(array,this.chartLabel);
    }
    else
    {
      resourceWiseChartData = this.commonMethods.groupByResource(array,this.chartLabel);
    }
    this.barChartResourceData = resourceWiseChartData.chartData;
    this.barChartResourceLabels = resourceWiseChartData.chartlabels;
    this.resourceWiseList = resourceWiseChartData.list;
  }
  
  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
  }
  listRoundNumber(no: any) {
    return Math.round(no);
  }

  exportToExcel() {
    this.exportData=false;
    this.customHttpService.exportDataWithAuthentication("/Complaint/ExportOMComplaintsList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => {
        this.exportData=true;

        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);
        var complaintTyp="";
        if(this.category.includes("Open")){
          complaintTyp="Open";
        }
     
        var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");        
        anchor.download = complaintTyp+"Complaint_" +this.type+"_"+ this.period+"_"+ nowDate+".xls";
        anchor.href = url;
        anchor.click();
      });

  }
  checkLoading() {
    if (this.items != undefined && this.items != null&& (this.items.length>0 || this.isList)&& this.exportData)  {
      return true;
    }
    else {
      return false;
    }
  }

  setActiveIndex(evt: any) {
    console.log("set index");
    this.activeIdString = evt.nextId;
  }
  search(value: string, searchBy: string): void {
    this.isList=false;
    if (searchBy == '') {
    if (value.length >= 3) {

      this.pageOfItems = this.allPageOfItems.filter((val) => val.createdDateDisplayValue.toLowerCase().includes(value.toLowerCase()) ||
        (val.regionName != null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
        (val.rom != null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
        (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
        (val.caseNumber != null && val.caseNumber.toLowerCase().includes(value.toLowerCase())) ||
        (val.servicePlan != null && val.servicePlan.toLowerCase().includes(value.toLowerCase())) ||
        (val.accountName != null && val.accountName.toLowerCase().includes(value.toLowerCase())) ||
        (val.status != null && val.status.toLowerCase().includes(value.toLowerCase())) ||
        (val.orderValue != null && val.orderValue.toString().includes(value)) ||
        (val.scName!=null &&val.scName.toLowerCase().includes(value.toLowerCase()))||
        (val.resourceName!=null &&val.resourceName.toLowerCase().includes(value.toLowerCase()))||
        (val.complaintRemarks!=null &&val.complaintRemarks.toLowerCase().includes(value.toLowerCase()))||
        (val.complaintDescription != null && val.complaintDescription.toLowerCase().includes(value.toLowerCase())));        
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
  }
  else{
    
    this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
    
  }
    this.items = this.pageOfItems;
    this.isList=true;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    var sortResponse= this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event, column,isASc,dataTyp);
    this.sortText=sortResponse.sortText; 
    this.reverse=sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName")
  };

  public romBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
  };

  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  public regionBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105,159,177,0.2)',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];

  public romBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(77,20,96,0.3)',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }


  public hbarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "om")
  };

  public scHBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "scName")
  };
  public resourceBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes:[{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "resourceName")
  };

  graphHover(e, array) {
    if(array.length > 0) {
        e.target.style.cursor = 'pointer';
    }else {
        e.target.style.cursor = '';
    }
}
  
  onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

      this.activeIdString = "3";
      this.search(activePoints[0]._model.label, searchBy);
      this.searchText = activePoints[0]._model.label;
    }
  }
  public hbarChartType: ChartType = 'horizontalBar';
  public hbarChartLegend = true;

  public hbarChartData: ChartDataSets[] = [];
  public hBarChartSCData: ChartDataSets[] = [];


}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}