import { ParseError } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';

@Component({
  selector: 'app-document-mapping',
  templateUrl: './document-mapping.component.html',
  styleUrls: ['./document-mapping.component.scss']
})
export class DocumentMappingComponent implements OnInit {
  items = [];
  isList: boolean = true;
  categoryList = [];
  folderList = [];
  categoryId: number ;
  folderId: number;
  selectedFolder: string;
  documentsMappingList = [];
  isSelected: boolean;
  loggedinUserId: string;
  checkedCheckboxCount: any;
  totalCheckBox: any;
  allFolderList =[];

  constructor(private customHttpService: CustomhttpService) { }

  ngOnInit() {
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.getCategoryList();
    this.getFolderList();
    this.countCheckedCheckbox();
  }

  getCategoryList() {
    this.isList = false;
    this.customHttpService
      .getDataWithAuthentication("/Account/GetCategoryList").subscribe((data) => {
        if (data.isSuccess) {
          this.categoryList = data.responseData;
          this.categoryList.sort((a, b) => a.categoryName > b.categoryName ? 1 : -1);
          console.log(this.categoryList);
          this.isList = true;
          this.getFolderList();
        }

      });
  }

  getFolderList() {
    debugger;
    this.isList = false;
    this.customHttpService
      .getDataWithAuthentication("/Account/GetFolderList").subscribe((data) => {
        if (data.isSuccess) {
          debugger;
          this.allFolderList = data.responseData;
          this.allFolderList.sort((a, b) => a.folderName > b.folderName ? 1 : -1);
          console.log(this.allFolderList);
          this.isList = true;
        }

      });
  }

  getFolderByCategory() {
    debugger;
    console.log("On category change: ", this.categoryId)

      if(this.categoryId != 4){
        this.folderId = undefined;
      }
      else{
        this.folderId = 0;
      }
    
    this.folderList = this.allFolderList.filter(
      (f) => f.categoryId == this.categoryId
    );
    }

  getDocumentsList(categoryId, folderId) {
    debugger;
    console.log(categoryId,folderId);
    if (categoryId != undefined && folderId != undefined) {
      this.customHttpService
        .getDataWithAuthentication("/Account/GetDocumentsForMapping?categoryId=" + categoryId + "&folderId=" + folderId).subscribe((data) => {
          if (data.isSuccess) {
            debugger;
            this.documentsMappingList = data.responseData;
            this.documentsMappingList.sort((a, b) => a.documentType > b.documentType ? 1 : -1);
            console.log(this.documentsMappingList);
          }
          for (let item of this.documentsMappingList) {
            if (item.isActive == true) {
              item.isSelected = item.isActive;
            }
            else {
              item.isSelected = false;
            }
          }
          this.countCheckedCheckbox();
        });
    } else {
      alert("Please select Category and Folder");
    }

  }

  checkCheckbox(event) {
    console.log(event.target.checked, event.target.value);
    var checkedCheckbox = this.documentsMappingList.filter(x => x.id == event.target.value);
    for (let i of checkedCheckbox) {
      i.isSelected = event.target.checked;
      i.createdBy = this.loggedinUserId;
      i.categoryId = this.categoryId;
      i.folderId = this.folderId;
    }
    this.countCheckedCheckbox();
    console.log(this.documentsMappingList);
  }

  SaveMapping(categoryId, folderId) {
    console.log(this.documentsMappingList);
    if (categoryId != undefined && folderId != undefined) {
      this.customHttpService.postData('/Account/SaveMappedDocuments',
        this.documentsMappingList).subscribe(data => {

          if (data.isSuccess) {
            var response = data.responseData;
            alert("Documents mapped successfully!");
            this.resetMapping(categoryId, folderId);
          }
        });
    }
    else {
      alert("Please select Category and Folder");
    }
  }

  resetMapping(categoryId, folderId) {
    this.getDocumentsList(categoryId, folderId);
  }

  countCheckedCheckbox() {
    if (this.documentsMappingList.length > 0) {
      this.totalCheckBox = this.documentsMappingList.length;
      this.checkedCheckboxCount = this.documentsMappingList.filter(x => x.isSelected == true).length;
    } else {
      this.totalCheckBox = 0;
      this.checkedCheckboxCount = 0;
    }
  }

  checkLoading() {
    if ((this.categoryList != undefined && this.categoryList != null && (this.categoryList.length > 0 || this.isList))
      &&
      (this.folderList != undefined && this.folderList != null && (this.folderList.length > 0 || this.isList))) {
      return true;
    }
    else {
      return false;
    }
  }
}
