import { Route } from "@angular/compiler/src/core";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as Chart from "chart.js";
import { CustomhttpService } from "../services/customhttp.service";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { times } from "chartist";
import * as moment from "moment";
import { SortService } from "../services/sortservice";
import { CommonProperties } from "../services/commonproperties";
import { CommonMethods } from "src/app/services/commonmethods";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  isTDMTD: boolean = true;
  isTDYDA: boolean = false;
  isCEXMTD: boolean = true;
  isCEXYDA: boolean = false;
  isNPSMTD: boolean = true;
  isNPSYDA: boolean = false;
  isSRDMTD: boolean = true;
  isSRDYDA: boolean = false;
  loggedInUserName: string = "";
  loggedinUserEmail: string = "";
  loggedinUserMobile: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  selectedRMId: any = {};
  selectedZRMId: any = {};
  selectedRMItem: any = {};
  selectedZRMItem: any = {};
  rrmListFromServer: any = [];
  regionListFromServer: any = [];
  regionList: any = [];
  romList: any = [];
  omList: any = [];
  scList: any = [];
  rrmList: any = [];
  zrmList: any = [];
  selectedRegion: string = "";
  selectedROM: string = "";
  selectedOM: string = "";
  selectedSC: string = "";
  selectedRegionDisplay: string = "";
  selectedROMDisplay: string = "";
  selectedOMDisplay: string = "";
  selectedSCDisplay: string = "";
  isFirstTime = true;
  isADHRYDA = false;
  isADHRMTD = true;
  complaintNumbers: any = [];
  npsNumbers: any = {};
  attendenceNumbers: any = {};
  onsiteAttendenceNumbers: any = {};
  karmaNumbers: any = [];
  redAccountNumbers: any = [];
  invoiceNumbers: any = {};
  srNumbers: any = {};
  selectedOMItem: any = {};
  selectedROMItem: any = {};
  selectedSCItem: any = {};
  deliveryPlanNumbers: any = [];
  firstDeliveryNumbers: any = [];
  otifNumbers: any = [];
  consumptionNumber: any = [];
  mbarChartLabels: any = [];
  barChartData: any = [];
  missNumber: any = [];
  chartLabel: string = "Percentage";
  todayDt: string = "";
  ydt: string;
  mdtStartDt: string;
  mdtEndDt: string;
  deliveryPeriod: string = "YDA";
  deliveryPeriodIndex: number = 0;
  jeopardyNumber: any = [];
  shortCloseNumber: any = [];
  firstTimeLoad: boolean = false;
  npsGroupwiseNumber: any = [];
  npsObdNumber: any = [];
  taskDelivery: any = [];
  heProductivityNumber: any = [];
  heBarChartData: any = [];
  heBarChartLabels: any = [];
  monthList = [];
  orderNumber: any = [];
  selectedMonth = "";
  currentMonth = "";
  b2bOtifNumbers: any = [];
  adherence: any = [];
  uploadedFile: any;
  closeResult = "";
  isTicketUpdated: boolean = false;
  message: string = "";
  isNPSNumbers: boolean = true;
  isNPSDisabled: boolean = false;
  isNpsApiHit: boolean = false;
  isAttendanceRBT: boolean = true;
  isAttendanceRBTDisabled: boolean = false;
  isAttendanceRBTApiHit: boolean = false;
  isHeProductivity: boolean = true;
  isHeProductivityDisabled: boolean = false;
  isHeProductivityApiHit: boolean = false;
  // doughnutPieChartData = [
  //   {
  //     data: [100],
  //     "hoverBackgroundColor": [
  //       "rgb(255, 69, 96)",
  //       "rgb(206, 148, 73)",
  //       "rgb(153, 223, 89)",
  //       "rgba(0, 0, 0, 0.6)",
  //       "rgb(153, 223, 89)"
  //   ],
  //   }
  // ];

  karmaData = [
    {
      label: "# of technicians",
      data: [10, 19, 3],
      borderWidth: 1,
      fill: false,
    },
  ];

  karmaLabels = ["Life 1", "Life 2", "Life 3"];

  karmaOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    onClick: (clickEvt, activeElems) =>
      this.onChartClick(clickEvt, activeElems),
  };

  karmaColors = [
    {
      backgroundColor: [
        "rgba(0, 128, 0, 0.5)",
        "rgba(255, 165, 0, 0.5)",
        "rgba(255, 68, 51, 0.5)",
      ],
      borderColor: [
        "rgba(0, 128, 0, 0.5)",
        "rgba(255, 165, 0, 0.5)",
        "rgba(255, 68, 51, 0.5)",
      ],
    },
  ];

  doughnutPieChartData = [
    {
      data: [208, 1, 200],
      backgroundColor: [
        "rgb(255, 69, 96)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(255, 69, 96)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [208, 1, 200],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  doughnutPieChartOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "52%",
      position: "bottom",
    },
  };

  rbtAttTodayData = [
    {
      data: [250, 1, 150],
      backgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [250, 1, 150],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  rbtAttTodayOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "55%",
      position: "bottom",
    },
  };

  rbtAttYestData = [
    {
      data: [0, 1, 0],
      backgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [0, 1, 0],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  rbtAttYestOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "",
      position: "bottom",
    },
  };

  rbtAttMTDData = [
    {
      data: [350, 1, 60],
      backgroundColor: [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [350, 1, 60],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  rbtAttMTDOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "85%",
      position: "bottom",
    },
  };

  onsiteAttTodayData = [
    {
      data: [250, 1, 150],
      backgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [250, 1, 150],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  onsiteAttTodayOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "55%",
      position: "bottom",
    },
  };

  onsiteAttYestData = [
    {
      data: [0, 1, 0],
      backgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [0, 1, 0],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  onsiteAttYestOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "",
      position: "bottom",
    },
  };

  onsiteAttMTDData = [
    {
      data: [350, 1, 60],
      backgroundColor: [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [350, 1, 60],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  onsiteAttMTDOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "85%",
      position: "bottom",
    },
  };

  npsYestData = [
    {
      data: [0, 1, 0],
      backgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [0, 1, 0],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  npsYestOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "",
      position: "bottom",
    },
  };

  npsMTDData = [
    {
      data: [350, 1, 60],
      backgroundColor: [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ],
      hoverBorderWidth: 0,
    },
    {
      data: [350, 1, 60],
      backgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      borderWidth: 0,
      hoverBackgroundColor: [
        "rgba(0, 0, 0, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgba(0, 0, 0, 0)",
      ],
      hoverBorderWidth: 0,
    },
  ];

  npsMTDOptions = {
    responsive: true,
    rotation: -1.0 * Math.PI, // start angle in radians
    circumference: Math.PI, // sweep angle in radians

    animation: {
      animateScale: true,
      animateRotate: true,
    },
    cutoutPercentage: 0,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    title: {
      display: true,
      text: "85%",
      position: "bottom",
    },
  };

  constructor(
    private customhttpService: CustomhttpService,
    private router: Router,
    private commonMethods: CommonMethods,
    private modalService: NgbModal
  ) {
    this.firstTimeLoad = true;
    this.monthList = [
      this.monthNames[moment().add(-2, "M").month()] +
        " " +
        moment().add(-2, "M").year(),
      this.monthNames[moment().add(-1, "M").month()] +
        " " +
        moment().add(-1, "M").year(),
      this.monthNames[moment().month()] + " " + moment().year(),
    ];
    this.selectedMonth =
      this.monthNames[moment().month()] + " " + moment().year();
    this.currentMonth =
      this.monthNames[moment().month()] + " " + moment().year();
    localStorage.setItem("SelectedMonthOM", this.selectedMonth);
  }

  roundNumber(no: any) {
    if (no != undefined && no != null && no != "") {
      return parseFloat(no).toFixed(2);
    }
    if (no == 0) {
      return no;
    }
  }

  roundNumberLac(no: any) {
    if (no != undefined && no != null && no != "" && no != 0) {
      return (parseFloat(no) / 100000).toFixed(2);
    } else return 0;
  }
  calculatePercentage(no: any, totalNo: any) {
    console.log("percentage" + no + " " + totalNo);
    if (
      no != undefined &&
      no != null &&
      no != "" &&
      totalNo != undefined &&
      totalNo != null &&
      totalNo != ""
    ) {
      return this.roundNumber((no / totalNo) * 100);
    } else if (totalNo == 0) {
      return 0;
    } else return 0;
  }
  // changePeriod(e){
  //   console.log(e.target.value);
  //   this.deliveryPeriod=e.target.value;

  //   this.deliveryPeriodIndex=(this.deliveryPeriod=="YDA")?0:1;
  // }

  navigateToSVBase(period: string, srStatus: string) {
    var url = "SVBase/" + period + "/" + srStatus;
    window.open(url, "_blank");
    //this.router.navigate(['SVBase',period,srStatus]);
  }

  navigateToComplaint(segment: string, period: string, srStatus: string) {
    var url = "complaint/" + segment + "/" + period + "/" + srStatus;
    window.open(url, "_blank");
    //this.router.navigate(['complaint',segment,period,srStatus]);
  }

  navigateToRedAccount(period: string, category: string) {
    var url = "RedAccount/" + period + "/" + category;
    window.open(url, "_blank");
    //this.router.navigate(['complaint',segment,period,srStatus]);
  }

  navigateToInvoices(period: string, category: string) {
    var url = "Invoices/" + period + "/" + category;
    window.open(url, "_blank");
    //this.router.navigate(['complaint',segment,period,srStatus]);
  }

  getRegionList() {
    debugger;
    this.customhttpService
      .getDataWithAuthentication(
        "/User/GetRegionList?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType
      )
      .subscribe((data) => {
        console.log(data);
        debugger;
        if (data.isSuccess) {
          this.regionListFromServer = data.responseData;
          this.regionList = data.responseData;
          this.selectedRegion = data.responseData[0].name;
          this.onRegionChange();
          if (
            this.loggedinUserType == "SOM" ||
            this.loggedinUserType == "SROM" ||
            this.loggedinUserType == "TECHS" ||
            this.loggedinUserType == "TECHADMIN"
          ) {
            this.onOMChange();
          }
        }
      });
  }

  fetchList() {
    console.log("fetch" + this.selectedOM);
    this.selectedRegionDisplay = this.selectedRegion;
    this.selectedSCDisplay = this.selectedSC;

    if (this.firstTimeLoad == true) {
      if (
        this.loggedinUserType == "ADMIN" ||
        this.loggedinUserType == "SOM" ||
        this.loggedinUserType == "SROM" ||
        this.loggedinUserType == "TECHS" ||
        this.loggedinUserType == "TECHADMIN"
      ) {
        this.selectedOM = "0";
        this.selectedROM = "0";
        this.selectedRegion = "All Regions";
        this.selectedRegionDisplay = "All Regions";
        this.selectedSC = "All SC";
        this.selectedSCDisplay = "All SC";
      }
    }

    if (this.selectedOM == "0") {
      localStorage.setItem("SelectedOMName", "All OM");
      this.selectedOMDisplay = "All OM";
    } else {
      this.selectedOMItem = this.omList.filter((o) => o.id == this.selectedOM);
      localStorage.setItem("SelectedOMName", this.selectedOMItem[0].name);
      this.selectedOMDisplay = this.selectedOMItem[0].name;
    }
    if (this.selectedROM == "0") {
      localStorage.setItem("SelectedROMName", "All ROM");
      this.selectedROMDisplay = "All ROM";
    } else {
      this.selectedROMItem = this.romList.filter(
        (r) => r.id == this.selectedROM
      );
      localStorage.setItem("SelectedROMName", this.selectedROMItem[0].name);
      this.selectedROMDisplay = this.selectedROMItem[0].name;
    }

    localStorage.setItem("SelectedOM", this.selectedOM);
    localStorage.setItem("SelectedROM", this.selectedROM);
    localStorage.setItem("SelectedRegion", this.selectedRegion);
    localStorage.setItem("SelectedSC", this.selectedSC);
    localStorage.setItem("SelectedMonthOM", this.selectedMonth);

    // this.attendenceNumbers=undefined;
    // this.onsiteAttendenceNumbers=undefined;
    // this.npsNumbers=undefined;
    // this.karmaNumbers=undefined;
    this.deliveryPlanNumbers = [];
    this.getDashbordPeriod();
    //this.getAttendenceNumbers();
    this.getOnsiteAttendenceNumbers();
    this.getHeKarmaNumbers();
    this.getComplaintNumbers();
    //this.getNPSNumbers();
    this.getNPSGroupwiseNumber();
    this.getNPSOBDNumbers();
    this.getDeliveryPlanNumbers();
    this.getDeliveryMissNumbers();
    this.getFirstDeliveryNumbers();
    this.getOTIFNumbers();
    this.getB2BOtifNumbers();
    this.getConsumptionNumber();
    this.getJeopardyNumber();
    this.getShortClosureNumber();
    this.getTaskDeliveryNumber();
    //this.getHeProductivityNumber();
    this.getOrderNumber();
    this.getControlRoomAdherence();

    this.getSortConfiguration();
    this.npsNumbers = undefined;
    this.isNPSNumbers = true;
    this.isNPSDisabled = false;
    this. isNpsApiHit = false;
    this.attendenceNumbers = undefined;
    this.isAttendanceRBT = true;
    this.isAttendanceRBTDisabled = false;
    this.isAttendanceRBTApiHit = false;
    this.heProductivityNumber= [];
    this.isHeProductivity = true;
    this.isHeProductivityDisabled = false;
    this.isHeProductivityApiHit = false;

  }

  ngOnInit() {
    debugger;
    this.loggedInUserName = localStorage.getItem("omloggedinusername");
    this.loggedinUserEmail = localStorage.getItem("omloggedinemail");
    this.loggedinUserMobile = localStorage.getItem("omloggedInMobileNumber");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    console.log("loggedInEmail:", this.loggedinUserEmail);
    this.getDashbordPeriod();
    this.getRegionList();
    if (
      this.loggedinUserType == "SOM" ||
      this.loggedinUserType == "SROM" ||
      this.loggedinUserType == "TECHS" ||
      this.loggedinUserType == "TECHADMIN"
    ) {
      this.selectedOM = "0";
      this.selectedROM = "0";
      this.selectedRegion = "All Regions";
      this.selectedRegionDisplay = "All Regions";
      this.selectedSC = "All SC";
      this.selectedSCDisplay = "All SC";
    }
  }

  getDashbordPeriod() {
    this.customhttpService
      .getDataWithAuthentication("/Task/GetDashboardPeriod")
      .subscribe((data) => {
        console.log("Period: " + data);
        this.todayDt = data.responseData.todayDt; //localStorage.getItem("omTodayDt");
        this.ydt = data.responseData.ydt; //localStorage.getItem("omYDT");
        this.mdtStartDt = data.responseData.mtdStartDt; //localStorage.getItem("omMTDStartDt");
        this.mdtEndDt = data.responseData.mtdEndDt; // localStorage.getItem("omMTDEndDt");
      });
  }
  onRegionChange() {
    console.log(this.selectedRegion);
    this.customhttpService
      .getDataWithAuthentication(
        "/User/GetROMList?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          this.selectedRegion
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          debugger;
          this.romList = data.responseData;
          this.selectedROM = data.responseData[0].id;
          this.onROMChange();
        }
      });
  }

  onROMChange() {
    console.log(this.selectedROM);
    this.customhttpService
      .getDataWithAuthentication(
        "/User/GetOMList?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          this.selectedRegion +
          "&romId=" +
          this.selectedROM
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          debugger;
          this.omList = data.responseData;
          this.selectedOM = data.responseData[0].id;
          this.onOMChange();
        }
      });
  }

  onOMChange() {
    console.log(this.selectedROM);
    this.customhttpService
      .getDataWithAuthentication(
        "/User/GetSCList?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          this.selectedRegion +
          "&romId=" +
          this.selectedROM +
          "&omId=" +
          this.selectedOM
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          this.scList = data.responseData;
          this.selectedSC = data.responseData[0].name;
          if (this.isFirstTime) {
            this.isFirstTime = false;
            this.fetchList();
          }
        }
      });
  }
  checkLoading() {
    if (
      //this.attendenceNumbers!=undefined &&
      //this.onsiteAttendenceNumbers!=undefined&&
      //this.karmaNumbers!=undefined && this.karmaNumbers.length>0 &&
      //this.npsNumbers!=undefined && this.npsNumbers.length>0 &&
      this.deliveryPlanNumbers != undefined &&
      this.deliveryPlanNumbers.length > 0
      // &&
      //this.firstDeliveryNumbers!=undefined && this.firstDeliveryNumbers.length>0
    ) {
      //this.isAllLoad=true;
      return true;
    } else return false;
  }

  checkNPSLoading() {
    if (
      //this.attendenceNumbers!=undefined &&
      //this.onsiteAttendenceNumbers!=undefined&&
      //this.karmaNumbers!=undefined && this.karmaNumbers.length>0 &&
      this.npsNumbers != undefined &&
      this.npsNumbers.mtd != undefined
      // &&
      //this.deliveryPlanNumbers!=undefined && this.deliveryPlanNumbers.length>0
      // &&
      //this.firstDeliveryNumbers!=undefined && this.firstDeliveryNumbers.length>0
    ) {
      //this.isAllLoad=true;
      return true;
    } else return false;
  }

  checkAttOnsiteLoading() {
    if (
      //this.attendenceNumbers!=undefined &&
      this.onsiteAttendenceNumbers != undefined &&
      this.onsiteAttendenceNumbers.mtd != undefined
      //&&
      //this.karmaNumbers!=undefined && this.karmaNumbers.length>0 &&
      //this.npsNumbers!=undefined && this.npsNumbers.length>0 &&
      //this.deliveryPlanNumbers!=undefined && this.deliveryPlanNumbers.length>0
      // &&
      //this.firstDeliveryNumbers!=undefined && this.firstDeliveryNumbers.length>0
    ) {
      //this.isAllLoad=true;
      return true;
    } else return false;
  }

  checkAttRBTLoading() {
    if (
      this.attendenceNumbers != undefined &&
      this.attendenceNumbers.mtd != undefined //&&
      //this.onsiteAttendenceNumbers!=undefined&&
      //this.karmaNumbers!=undefined && this.karmaNumbers.length>0 &&
      //this.npsNumbers!=undefined && this.npsNumbers.length>0 &&
      //this.deliveryPlanNumbers!=undefined && this.deliveryPlanNumbers.length>0
      // &&
      //this.firstDeliveryNumbers!=undefined && this.firstDeliveryNumbers.length>0
    ) {
      //this.isAllLoad=true;
      return true;
    } else return false;
  }

  getAttendenceNumbers() {
debugger;
    if(this.isAttendanceRBTApiHit == false){

    this.isAttendanceRBTDisabled = true;
    this.isAttendanceRBT = false;   
    this.attendenceNumbers = undefined;
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/HE/GetOMHEAttendenceDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        //console.log(data);
        if (data.isSuccess) {
          debugger;
          //console.log(data);
          //debugger;
          this.isAttendanceRBTDisabled = false;
          this.isAttendanceRBT = true;
          this.isAttendanceRBTApiHit = true;
          this.attendenceNumbers = data.responseData;
          console.log(
            "attendance" +
              this.attendenceNumbers.yesterday.availableHE +
              "  " +
              this.attendenceNumbers.yesterday.totalHE
          );
          this.rbtAttYestData[0].data = [
            this.attendenceNumbers.yesterday.percentageAvailability,
            1,
            99 - this.attendenceNumbers.yesterday.percentageAvailability,
          ];
          this.rbtAttYestData[1].data = [
            this.attendenceNumbers.yesterday.percentageAvailability,
            1,
            99 - this.attendenceNumbers.yesterday.percentageAvailability,
          ];
          this.rbtAttYestOptions.title.text =
            this.attendenceNumbers.yesterday.percentageAvailability.toString() +
            "%";
          this.setColor(
            this.rbtAttYestData,
            this.attendenceNumbers.yesterday.percentageAvailability
          );

          this.rbtAttTodayData[0].data = [
            this.attendenceNumbers.today.percentageAvailability,
            1,
            99 - this.attendenceNumbers.today.percentageAvailability,
          ];
          this.rbtAttTodayData[1].data = [
            this.attendenceNumbers.today.percentageAvailability,
            1,
            99 - this.attendenceNumbers.today.percentageAvailability,
          ];
          this.rbtAttTodayOptions.title.text =
            this.attendenceNumbers.today.percentageAvailability.toString() +
            "%";
          this.setColor(
            this.rbtAttTodayData,
            this.attendenceNumbers.today.percentageAvailability
          );

          this.rbtAttMTDData[0].data = [
            this.attendenceNumbers.mtd.averageAvailability,
            1,
            99 - this.attendenceNumbers.mtd.averageAvailability,
          ];
          this.rbtAttMTDData[1].data = [
            this.attendenceNumbers.mtd.averageAvailability,
            1,
            99 - this.attendenceNumbers.mtd.averageAvailability,
          ];
          this.rbtAttMTDOptions.title.text =
            this.attendenceNumbers.mtd.averageAvailability.toString() + "%";
          this.setColor(
            this.rbtAttMTDData,
            this.attendenceNumbers.mtd.averageAvailability
          );
        }
        else{
          this.isAttendanceRBTDisabled = false;
          this.isAttendanceRBT = true;
        }
      });
    }
  }

  getTaskDeliveryNumber() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetTaskDeliveryDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          //console.log(data);
          //debugger;
          console.log("TAsl Deliver");
          console.log(data.responseData);
          this.taskDelivery = data.responseData;
        }
      });
  }
  getHeProductivityNumber() {
    if(this.isHeProductivityApiHit == false){
      this.isHeProductivityDisabled = true;
      this.isHeProductivity = false;
  
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetHEProductivityDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          console.log(data.responseData);
          this.heProductivityNumber = data.responseData;
          this.isHeProductivityDisabled = false;
          this.isHeProductivityApiHit = true;
          this.isHeProductivity = true;
        }
        else{
          this.isHeProductivityDisabled = false;
          this.isHeProductivity = true;
        }
      });

    }
  }
  getOrderNumber() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Order/GetRenewalRefferalDashboardData?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          console.warn("Order", data.responseData);
          this.orderNumber = data.responseData;
        }
      });
  }

  getNPSGroupwiseNumber() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetOMNPSGroupwiseNumberForDashboard?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          //console.log(data);
          //debugger;
          console.log("Taskdelivery");
          this.npsGroupwiseNumber = data.responseData;
        }
      });
  }
  getNPSOBDNumbers() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetOMNPSOBDNumberForDashboard?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          //console.log(data);
          //debugger;

          this.npsObdNumber = data.responseData;
        }
      });
  }
  getShortClosureNumber() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Delivery/GetShortClosureDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          //console.log(data);
          //debugger;
          console.log("shortCl");
          this.shortCloseNumber = data.responseData;
        }
      });
  }
  getJeopardyNumber() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetJeopardyDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          //console.log(data);
          //debugger;
          console.log("jeopordy");
          this.jeopardyNumber = data.responseData;

          console.log(this.jeopardyNumber);
        }
      });
  }
  getOnsiteAttendenceNumbers() {
    this.onsiteAttendenceNumbers = undefined;
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/HE/GetOMHEOnsiteAttendenceDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          //debugger;
          this.onsiteAttendenceNumbers = data.responseData;
          this.onsiteAttYestData[0].data = [
            this.onsiteAttendenceNumbers.yesterday.percentageAvailability,
            1,
            99 - this.onsiteAttendenceNumbers.yesterday.percentageAvailability,
          ];
          this.onsiteAttYestData[1].data = [
            this.onsiteAttendenceNumbers.yesterday.percentageAvailability,
            1,
            99 - this.onsiteAttendenceNumbers.yesterday.percentageAvailability,
          ];
          this.onsiteAttYestOptions.title.text =
            this.onsiteAttendenceNumbers.yesterday.percentageAvailability.toString() +
            "%";
          this.setColor(
            this.onsiteAttYestData,
            this.onsiteAttendenceNumbers.yesterday.percentageAvailability
          );

          this.onsiteAttTodayData[0].data = [
            this.onsiteAttendenceNumbers.today.percentageAvailability,
            1,
            99 - this.onsiteAttendenceNumbers.today.percentageAvailability,
          ];
          this.onsiteAttTodayData[1].data = [
            this.onsiteAttendenceNumbers.today.percentageAvailability,
            1,
            99 - this.onsiteAttendenceNumbers.today.percentageAvailability,
          ];
          this.onsiteAttTodayOptions.title.text =
            this.onsiteAttendenceNumbers.today.percentageAvailability.toString() +
            "%";
          this.setColor(
            this.onsiteAttTodayData,
            this.onsiteAttendenceNumbers.today.percentageAvailability
          );

          this.onsiteAttMTDData[0].data = [
            this.onsiteAttendenceNumbers.mtd.averageAvailability,
            1,
            99 - this.onsiteAttendenceNumbers.mtd.averageAvailability,
          ];
          this.onsiteAttMTDData[1].data = [
            this.onsiteAttendenceNumbers.mtd.averageAvailability,
            1,
            99 - this.onsiteAttendenceNumbers.mtd.averageAvailability,
          ];
          this.onsiteAttMTDOptions.title.text =
            this.onsiteAttendenceNumbers.mtd.averageAvailability.toString() +
            "%";
          this.setColor(
            this.onsiteAttMTDData,
            this.onsiteAttendenceNumbers.mtd.averageAvailability
          );
        }
      });
  }

  getHeKarmaNumbers() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/HE/GetOMHEKarmaDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          this.karmaNumbers = data.responseData;
          this.karmaData[0].data = [
            this.karmaNumbers.karmaForLifeline1.karmaHE,
            this.karmaNumbers.karmaForLifeline2.karmaHE,
            this.karmaNumbers.karmaForLifeline3.karmaHE,
          ];
        }
      });
  }

  getComplaintNumbers() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Complaint/GetOMComplaintDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("complaints");
        console.log(data.responseData);
        if (data.isSuccess) {
          this.complaintNumbers = data.responseData;
          //debugger;
          console.log("copy" + this.complaintNumbers[0].total);
        }
      });
  }

  getDeliveryPlanNumbers() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Delivery/GetOMDeliveryPlanDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("Delivery");
        console.log(data);
        if (data.isSuccess) {
          console.log(data.responseData);
          this.deliveryPlanNumbers = data.responseData;
          this.firstTimeLoad = false;
          //debugger;
        }
      });
  }
  getFirstDeliveryNumbers() {
    debugger;
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetFirstServiceDeliveryDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("First Delivery");
        console.log(data);
        if (data.isSuccess) {
          console.log(data.responseData);
          this.firstDeliveryNumbers = data.responseData;
          //debugger;
        }
      });
  }

  getOTIFNumbers() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetOTIFDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("Otif");
        console.log(data);
        if (data.isSuccess) {
          console.log(data.responseData);
          this.otifNumbers = data.responseData;
          //debugger;
        }
      });
  }

  getDeliveryMissNumbers() {
    console.log("miss");
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetOMDeliveryMissDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("Delivery Miss");
        //console.log(data);
        if (data.isSuccess) {
          console.log(data.responseData);
          this.missNumber = data.responseData;
          console.log(this.missNumber[0].totalSC.custMiss.missPer);
          //debugger;
        }
      });
  }
  getConsumptionNumber() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Delivery/GetOMChemicalConsumptionDashboardNumber?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("First Delivery");
        console.log(data);
        if (data.isSuccess) {
          console.log(data.responseData);
          this.consumptionNumber = data.responseData;

          let countArry = [];

          this.barChartData = [
            {
              data: [
                this.consumptionNumber[0].total,
                this.consumptionNumber[0].gpms,
                this.consumptionNumber[0].termite,
                this.consumptionNumber[0].stericare,
                this.consumptionNumber[0].rodent,
              ],
              label: "Yesterday",
            },
            {
              data: [
                this.consumptionNumber[1].total,
                this.consumptionNumber[1].gpms,
                this.consumptionNumber[1].termite,
                this.consumptionNumber[1].stericare,
                this.consumptionNumber[1].rodent,
              ],
              label: "MTD",
            },
          ];

          this.mbarChartLabels = [
            "Total",
            "GPMS",
            "Termite",
            "Stericare",
            "Rodent",
          ];
        }
      });
  }

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    series: { stacking: "normal" },
    onClick: (clickEvt, activeElems) =>
      this.onConsumptionChartClick(clickEvt, activeElems),
  };

  public barChartType: string = "bar";
  public barChartLegend: boolean = true;

  public barChartColors: Array<any> = [
    {
      backgroundColor: "rgba(105,159,177,0.2)",
      borderColor: "rgba(105,159,177,1)",
      pointBackgroundColor: "rgba(105,159,177,1)",
      pointBorderColor: "#fafafa",
      pointHoverBackgroundColor: "#fafafa",
      pointHoverBorderColor: "rgba(105,159,177)",
    },
  ];

  public romBarChartColors: Array<any> = [
    {
      backgroundColor: "rgba(77,20,96,0.3)",
      borderColor: "rgba(77,20,96,1)",
      pointBackgroundColor: "rgba(77,20,96,1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(77,20,96,1)",
    },
  ];

  generatePercentCommon(array) {
    let result = [];
    let countArry = [];

    array.forEach(function (a) {
      result.push({
        Name: a.xAxis,
        totalPresent: a.yAxis,
      });
    }, Object.create(null));

    console.log(result);

    return result;
  }

  getNPSNumbers() {
    if(this.isNpsApiHit == false){
    this.npsNumbers = undefined;
    this.isNPSNumbers = false;
    this.isNPSDisabled = true;
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    
      this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetOMNPSDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          this.isNPSNumbers = true;
          this.isNPSDisabled = false;
          this.isNpsApiHit = true;
          this.npsNumbers = data.responseData;
          this.npsYestData[0].data = [
            this.npsNumbers.yda.npsPercent,
            1,
            99 - this.npsNumbers.yda.npsPercent,
          ];
          this.npsYestData[1].data = [
            this.npsNumbers.yda.npsPercent,
            1,
            99 - this.npsNumbers.yda.npsPercent,
          ];
          this.npsYestOptions.title.text =
            this.npsNumbers.yda.npsPercent.toString() + "%";
          this.setColor(this.npsYestData, this.npsNumbers.yda.npsPercent);

          this.npsMTDData[0].data = [
            this.npsNumbers.mtd.npsPercent,
            1,
            99 - this.npsNumbers.mtd.npsPercent,
          ];
          this.npsMTDData[1].data = [
            this.npsNumbers.mtd.npsPercent,
            1,
            99 - this.npsNumbers.mtd.npsPercent,
          ];
          this.npsMTDOptions.title.text =
            this.npsNumbers.mtd.npsPercent.toString() + "%";
          this.setColor(this.npsMTDData, this.npsNumbers.mtd.npsPercent);
        }
        else{
          this.isNPSDisabled = false;
          this.isNPSNumbers = true;
        }
      });
    }

    
  }

  setColor(data, percentage) {
    if (percentage <= 50) {
      data[0].backgroundColor = [
        "rgb(255, 68, 51)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ];
      data[0].hoverBackgroundColor = [
        "rgb(255, 68, 51)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ];
    } else if (percentage > 50 && percentage <= 80) {
      data[0].backgroundColor = [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ];
      data[0].hoverBackgroundColor = [
        "rgb(255, 165, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ];
    } else {
      data[0].backgroundColor = [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ];
      data[0].hoverBackgroundColor = [
        "rgb(0, 128, 0)",
        "rgba(0, 0, 0, 0.6)",
        "rgb(192, 192, 192)",
      ];
    }
  }

  getInvoiceNumbers() {
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Invoice/GetInvoicesForDashboard?zrmId=" +
          zrmId +
          "&rmId=" +
          rmId +
          "&region=" +
          region
      )
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          console.log(data);
          this.invoiceNumbers = data.responseData;
        }
      });
  }

  calculateMissPercentage(criteria: string, prd: string) {
    //this.missNumber[0]?.totalSC.hiCareMiss.count,this.deliveryPlanNumbers[0]?.totalSC?.srPlanned.svValue
    //return this.roundNumber( missCount*100/plannedCount);

    var index;
    //prd=this.deliveryPeriod; //new change

    if (prd == "YDA") index = 0;
    else index = 1;

    if (
      this.missNumber[index][criteria].hiCareMiss.count != 0 &&
      this.deliveryPlanNumbers[index][criteria].srPlanned.svValue != 0
    ) {
      return this.roundNumber(
        (this.missNumber[index][criteria].hiCareMiss.count * 100) /
          this.deliveryPlanNumbers[index][criteria].srSchedule.svValue
      );
    } else return 0;
  }

  getSRDeliveredPercentage(criteria: string, prd: string) {
    var index;
    //prd=this.deliveryPeriod; //new change

    if (prd == "YDA") index = 0;
    else index = 1;

    if (
      this.deliveryPlanNumbers[index][criteria].srDelivered.svValue != 0 &&
      this.deliveryPlanNumbers[index][criteria].srSchedule.svValue != 0
    ) {
      return this.roundNumber(
        (this.deliveryPlanNumbers[index][criteria].srDelivered.svValue * 100) /
          this.deliveryPlanNumbers[index][criteria].srSchedule.svValue
      );
    } else return 0;
  }
  getSortConfiguration() {
    this.customhttpService
      .getDataWithAuthentication("/User/GetOMSortConfiguration")
      .subscribe((data) => {
        localStorage.setItem(
          "SortOrderList",
          JSON.stringify(data.responseData)
        );
        // CommonProperties.setSortList( data.responseData);
        // CommonProperties.property="Hello1";
        // console.warn("sortDash",CommonProperties.getSortList());
      });
  }
  getB2BOtifNumbers() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Delivery/GetB2BOTIFDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("Otif");
        console.log(data);
        if (data.isSuccess) {
          console.log(data.responseData);
          this.b2bOtifNumbers = data.responseData;
          //debugger;
        }
      });
  }

  getControlRoomAdherence() {
    var sc = localStorage.getItem("SelectedSC");
    var rom = localStorage.getItem("SelectedROM");
    var om = localStorage.getItem("SelectedOM");
    var region = localStorage.getItem("SelectedRegion");

    this.customhttpService
      .getDataWithAuthentication(
        "/Task/GetAdherenceDashboardNumbers?userId=" +
          this.loggedinUserId +
          "&userTypeName=" +
          this.loggedinUserType +
          "&region=" +
          region +
          "&rom=" +
          rom +
          "&om=" +
          om +
          "&servicecenter=" +
          sc
      )
      .subscribe((data) => {
        console.log("Otif");
        console.log(data);
        if (data.isSuccess) {
          console.warn("controlroom", data.responseData);
          this.adherence = data.responseData;
        }
      });
  }

  public chartClicked(type: string, category: string): void {
    // if (e.active.length > 0) {
    // const chart = e.active[0]._chart;
    // const activePoints = chart.getElementAtEvent(e.event);
    //   if ( activePoints.length > 0) {
    //     // get the internal index of slice in pie chart
    //     const clickedElementIndex = activePoints[0]._index;
    //     const label = chart.data.labels[clickedElementIndex];
    //     // get value by index
    //     const value = chart.data.datasets[0].data[clickedElementIndex];
    //     console.log( value);
    //   }
    //  }

    console.log(type + " " + category);
    var url = "Attendance/" + type + "/" + category;
    window.open(url, "_blank");
  }

  npsChartClicked(category: string): void {
    var url = "Nps/" + category + "/" + "Total";
    window.open(url, "_blank");
  }
  onConsumptionChartClick(clickEvt: MouseEvent, activeElems: Array<any>) {
    console.log("chemicalchartClick");

    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log(clickedElementIndex);
      var lifeIndex = clickedElementIndex;
      //To get Value
      // const label = chart.data.labels[clickedElementIndex];
      // const value = chart.data.datasets[0].data[clickedElementIndex];
      // console.log( value);
      var period, category;
      if (clickedPeridIndex == 0) period = "YDA";
      else period = "MTD";

      if (clickedElementIndex == 0) category = "Total";
      else if (clickedElementIndex == 1) category = "GPMS";
      else if (clickedElementIndex == 2) category = "Termite";
      else if (clickedElementIndex == 3) category = "Stericare";
      else category = "Rodent";

      var url = "chemical_comsumption/" + category + "/" + period;
      window.open(url, "_blank");
    }
  }

  onChartClick(clickEvt: MouseEvent, activeElems: Array<any>) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);
    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      console.log(clickedElementIndex);
      var lifeIndex = clickedElementIndex;
      //To get Value
      // const label = chart.data.labels[clickedElementIndex];
      // const value = chart.data.datasets[0].data[clickedElementIndex];
      // console.log( value);

      var url = "Karma/" + (Number(lifeIndex) + 1);
      window.open(url, "_blank");
    }
  }

  open(content) {
    this.message = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  uploadTicketsExcelFile(event) {
    debugger;
    this.uploadedFile = event.target.files[0];
    document.getElementById("fileName").innerHTML = this.uploadedFile.name;
  }

  saveExcelFile() {
    debugger;
    const formData = new FormData();
    formData.append("file", this.uploadedFile, this.uploadedFile.name);
    this.customhttpService
      .postDataWithoutAuthenticationForAbsoluteURL(
        this.commonMethods.updateTicket +
          "/Ticket/UpdateBulkTicketReportingManager",
        formData
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          this.isTicketUpdated = true;
          this.message = "Tickets Updated Successfully!";
        } else {
          this.isTicketUpdated = false;
          this.message = "Something went wrong!";
        }
      });
  }

  navigateToProductivity(category: string) {
    var url = "he_productivity/" + category;
    window.open(url, "_blank");
  }

  navigateToTaskDelivery(category: string, type: string, period: string) {
    var url = "task_delivery/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }

  navigateToOrder(category: string, type: string) {
    var url = "order/" + category + "/" + type;
    window.open(url, "_blank");
  }

  navigateToHEProductivity(category: string, type: string, period: string) {
    var url = "he_produtivity/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }
  navigateToJeopardy(category: string, type: string, period: string) {
    var url = "jeopardy/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }
  navigateToShortClosure(category: string, type: string, period: string) {
    var url = "short_closure/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }
  navigateToNPS(category: string, prd: string) {
    var url = "Nps/" + prd + "/" + category;
    window.open(url, "_blank");
  }
  navigateToOBDNPS(category: string, prd: string) {
    var url = "obd_Nps/" + prd + "/" + category;
    window.open(url, "_blank");
  }

  navigateToDelivery(srStatus: string, type: string, period: string) {
    //period=this.deliveryPeriod;
    console.log(period);
    if (srStatus == "DeliveryPercentage") {
      var url = "Delivery_percent/" + srStatus + "/" + type + "/" + period;
      window.open(url, "_blank");
    } else {
      var url = "Delivery/" + srStatus + "/" + type + "/" + period;
      window.open(url, "_blank");
    }
  }

  navigateToCompaints(category: string, type: string, period: string) {
    var url = "complaint/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }

  navigateConsumption(category: string, period: string) {
    var url = "chemical_comsumption/" + category + "/" + period;
    window.open(url, "_blank");
  }

  navigateFirstDelivery(category: string, type: string, period: string) {
    //period=this.deliveryPeriod; //new changes
    debugger;
    var url = "service_delivery/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }
  navigateMissDelivery(category: string, type: string, period: string) {
    var url = "miss_delivery/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }
  navigateB2BOtif(category: string, type: string, period: string) {
    var url = "b2b_otif/" + category + "/" + type + "/" + period;
    window.open(url, "_blank");
  }
  complaintPercentage(count: number, deliverCount: number) {
    console.log(count + " Delivery:" + deliverCount);
    if (deliverCount != 0 && deliverCount != undefined) {
      return this.roundNumber((count / deliverCount) * 100);
    } else return 0;
  }
  roundAmt(no: any) {
    return (parseFloat(no) / 100000).toFixed(2);
  }
  redirectToLeaderBoard() {
    var url = "leaderboard";
    window.open(url, "_blank");
  }

  redirectToPulseReport() {
    var url = "pulseReport";
    window.open(url, "_blank");
  }

  redirectToOMROMMapping() {
    debugger;
    var url = "omrom_mapping";
    window.open(url, "_blank");
  }
  redirectToTickets() {
    var url = "tickets";
    window.open(url, "_blank");
  }
  redirectToMyTickets() {
    var url = "tickets_detail/0";
    window.open(url, "_blank");
  }
  redirectToGeoTagger() {
    debugger;
    var url = "geotagger";
    window.open(url, "_blank");
  }
  navigateToControlRoomAdherence(
    category: string,
    period: string,
    status: string
  ) {
    var url = "controlroom_adherence/" + category + "/" + period + "/" + status;
    window.open(url, "_blank");
  }
  redirectToUploadFile() {
    var url = "uploadfile";
    window.open(url, "_blank");
  }

  redirectToDocumentUploader() {
    var url = "documentuploader";
    window.open(url, "_blank");
  }

  isClose: boolean;
  closeAlert() {
    this.isClose = true;
  }
}
