import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent implements OnInit {

  @Input('regionWiseList') regionWiseList = [];
  @Input('scWiseList') scWiseList = [];
  @Input('omWiseList') omWiseList = [];
  @Input('romWiseList') romWiseList = [];
  @Input('resourceWiseList') technicianWiseList = [];

  reverse: boolean = true;
  
  constructor() { }

  ngOnInit() {
  }

  sortCol(column: string, isASc: boolean,sortBy,  event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(parent.parentElement.classList);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

var sortArry=[];

if(sortBy=="Region")
{
sortArry=this.regionWiseList;
}
if(sortBy=="ROM")
{
  sortArry=this.romWiseList;
}
if(sortBy=="OM")
{
  sortArry=this.omWiseList;  
}
if(sortBy=="SCName")
{
  sortArry=this.scWiseList;
}
if(sortBy=="TechnicianName")
{
  sortArry=this.technicianWiseList;
}     
    sortArry.sort(function (a, b) { return sort(a[column], b[column], isASc) });
     this.reverse = !this.reverse;
}

}
function sort(a, b, asc) {
    
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  console.log( result);

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}