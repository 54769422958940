import { Component, OnInit } from "@angular/core";
import { CustomhttpService } from "src/app/services/customhttp.service";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
import { CommonMethods } from "src/app/services/commonmethods";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import {
  NgbAccordionConfig,
  NgbDate,
  NgbDateParserFormatter,
  NgbTimepickerModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "src/app/services/dateformat";

@Component({
  selector: "app-document-uploader",
  templateUrl: "./document-uploader.component.html",
  styleUrls: ["./document-uploader.component.scss"],
})
export class DocumentUploaderComponent implements OnInit {
  customerFileList = [];
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  userType: string = "";
  sortText: string;
  reverse: boolean = false;
  searchText = "";
  customerFile: any;
  fileName: string = "";
  isSave: boolean = false;
  loggedinUserId: string;
  loggedInUserName: string;
  isList: boolean = true;
  isDelete = false;
  fileArry = [];
  closeResult = "";
  errorMsg: string;
  successMsg: string;
  isError: boolean = true;
  fileType: string = "Audit";
  remark: string = "";
  auditCategory: string = "";
  
  fileTitle: string = "";
  isSuccess: boolean = false;
  toBeDeleteId: number = 0;
  tobeDeleteCustomer: string = "";
  tobeDeleteTitle: string = "";
  tobeDeleteFile: string = "";
  tobeDeleteFileUrl: string = "";
  tobeDeleteType: string = "";
  isCallSave: boolean = false;
  isCustomerValid = false;
  categoryList: any;
  folderList: any;
  allFolderList: any;

  //  auditList: [{label:'Technical', value:'1'},
  // {label: 'RM', value: '2'}];

  auditList: string[] =
  ['Technical','RM'];
  auditListOption: string;

  documentList: any;
  documentMappingList: any;
  customerId: any;
  customerName: any;
  isCustomerButtonDisabled: boolean;
  customerResponseModel: any;
  folderId: number = 0;
  categoryId: number = 1;

  
  uploadedFiles = [];
  documentIdList = [];
  fileAndIdList = [];
  isCustomerIdDisabled: boolean; //no need can delete
  fileId: any;
  isFolderListDisabled: boolean;
  lastVisitNgbDate: NgbDate;
  nextVisitNgbDate: NgbDate;
  nextVisitDate: NgbDate;
  pickerDate: NgbDate;
  auditNgbDate: NgbDate;
  auditDate: any;
  isValidCustomer: boolean = false;
  toBeDeleteDocument: documentModel;
  largeFileList = [];
  largeFileIdList = [];
  largeFileId: any;
  bgColor: any;
  newFolderName: string = "";
  uploadedDocumentList = [];
  isReadyForDocumentUpload: boolean = true;
  isChooseFileDisabled: boolean = true;
  replaceFile: boolean;
  isFileReplace: boolean;
  //isDatePickerHidden: boolean;

 

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService,
    private commonMethods: CommonMethods,
    private modalService: NgbModal
  ) {
    var date = new Date();
    this.pickerDate = new NgbDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    );
  }

  ngOnInit() {
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedInUserName = localStorage.getItem("omloggedinusername");

    this.getDocumentCategory();
    this.getCustomerFilesList();

  //    console.warn("audit", this.commonMethods.DocumentAuditList);
  //  this.auditList = this.commonMethods.DocumentAuditList;
 
  }

  getDocumentCategory() {
    debugger
    this.customHttpService
      .getDataWithAuthentication("/Account/GetCategoryList")
      .subscribe((data) => {
        if (data.isSuccess) {
          this.categoryList = data.responseData;
          console.log("CategoryList",data);

          this.getDocumentFolder();
        }
      });
  }

  getDocumentFolder() {
    this.customHttpService
      .getDataWithAuthentication("/Account/GetFolderList")
      .subscribe((data) => {
        if (data.isSuccess) {
          this.allFolderList = data.responseData;
          this.getFolderByCategory();

          //this.folderList = data.responseData;
        }
      });
  }

  getCustomerName() {
    debugger;
    this.isValidCustomer = false;
    this.isChooseFileDisabled = true;

    this.customerName = "";
    this.customHttpService
      .getDataWithAuthentication(
        "/GeoTagger/GetCustomerDetailsById?customerId=" + this.customerId
      )
      .subscribe((data) => {
        debugger;
        console.log(data.responseData);
        if (data.isSuccess == true) {
          this.customerResponseModel = data.responseData;
          if (this.customerResponseModel.length > 0 && this.categoryId == 2) {
            this.customerResponseModel = this.customerResponseModel[0];
            var parentId = this.customerResponseModel.parent_Customer_ID__c;
            if (parentId == "" || parentId == null || parentId == undefined) {
              this.customerId = this.customerResponseModel.customer_id__c;
              this.customerName = this.customerResponseModel.name;
              this.isValidCustomer = true;
              this.isChooseFileDisabled = false;
            } else {
              this.customerName = "Please enter Valid Customer Id (Parent)";
              this.isValidCustomer = false;
              this.isChooseFileDisabled = true;
            }
          }
          //else if (
          //  this.customerResponseModel.length > 0 &&
          //  this.categoryId == 3
          //) {
          //  this.customerResponseModel = this.customerResponseModel[0];
          //  var parentId = this.customerResponseModel.parent_Customer_ID__c;
          //  if (parentId != "" && parentId != null && parentId != undefined) {
          //    this.customerId = this.customerResponseModel.customer_id__c;
          //    this.customerName = this.customerResponseModel.name;
          //    this.isValidCustomer = true;
          //    this.isChooseFileDisabled = false;
          //  } else {
          //    this.customerName = "Please enter Valid Customer Id (Site)";
          //    this.isValidCustomer = false;
          //    this.isChooseFileDisabled = true;
          //  }
          //}
          else if (
            this.customerResponseModel.length > 0 &&
            (this.categoryId == 4 || this.categoryId == 3)
          ) {
            this.customerResponseModel = this.customerResponseModel[0];
            this.customerId = this.customerResponseModel.customer_id__c;
            this.customerName = this.customerResponseModel.name;
            this.isValidCustomer = true;
            this.isChooseFileDisabled = false;
          } else {
            this.customerName = "Please enter Valid Customer Id";
            this.isValidCustomer = false;
            this.isChooseFileDisabled = true;
          }
        }
      });
  }

  getDocumentByCategoryFolder() {
    debugger;
    this.documentMappingList = [];
    this.uploadedFiles = [];
    this.customerId = "";
    this.customerName = "";
    this.errorMsg = "";
    this.isError = false;
    this.auditNgbDate = null;

    if (this.categoryId == 4) {
      this.folderId = 0;
      this.isChooseFileDisabled = false;
    }

    if (this.categoryId == 1) {
      this.isChooseFileDisabled = false;
      this.auditCategory = undefined;
    }

    if (this.categoryId == 2 || this.categoryId == 3) {
      this.isChooseFileDisabled = true;
      this.auditCategory = undefined;
    }
    //if(this.categoryId==4 ||(this.categoryId!=4 && this.folderId!=0)){
    this.customHttpService
      .getDataWithAuthentication(
        "/Account/GetDocumentsByCategory?categoryId=" +
          this.categoryId +
          "&folderId=" +
          this.folderId
      )
      .subscribe((data) => {
        debugger;
        console.log(data);
        if (data.isSuccess) {
          this.documentMappingList = data.responseData;
        }
      });
    //}
  }


  
  
  getFolderByCategory() {
    debugger;
    this.folderList = this.allFolderList.filter(
      (f) => f.categoryId == this.categoryId
    );
    this.getDocumentByCategoryFolder();
  }
  getDocumentByCategoryIdNew() {
    this.getDocumentByCategoryFolder();
  }
  getDocumentByCategoryId() {
    debugger;
    if (
      this.categoryId != null &&
      this.categoryId != undefined &&
      this.folderId != null &&
      this.folderId != undefined
    ) {
      this.documentMappingList = [];
      this.customHttpService
        .getDataWithAuthentication(
          "/Account/GetDocumentsByCategory?categoryId=" +
            this.categoryId +
            "&folderId=" +
            this.folderId
        )
        .subscribe((data) => {
          debugger;
          console.log(data);
          if (data.isSuccess) {
            this.documentMappingList = data.responseData;
          }
        });
    } else if (this.categoryId != null && this.categoryId != undefined) {
      this.documentMappingList = [];
      this.customHttpService
        .getDataWithAuthentication(
          "/Account/GetDocumentsByCategory?categoryId=" +
            this.categoryId +
            "&folderId=" +
            0
        )
        .subscribe((data) => {
          debugger;
          console.log(data);
          if (data.isSuccess) {
            this.documentMappingList = data.responseData;
          }
        });
    } else if (this.categoryId == undefined || this.folderId == undefined) {
      this.documentMappingList = [];
    }
  }

  getCustomerFilesList() {
    this.isList = false;
    this.customHttpService
      .getDataWithAuthentication("/account/GetAllCustomerFiles")
      .subscribe((data) => {
        console.log(data.responseData);
        this.customerFileList = data.responseData;
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList = true;
        this.sortText = "Date (Descending)";
      });
  }

  openFileUpload(content, item: any) {
    debugger
    this.uploadedFiles = [];
    this.documentMappingList = [];
    this.fileAndIdList = [];
    this.categoryId = 1;
    this.folderId = 0;
    this.auditDate = "";
    this.customerId = "";
    this.customerName = "";
    this.auditNgbDate = null;
    this.auditCategory = undefined;
    this.remark = "";
    this.isCustomerValid = false;
    this.isError = false;
    this.errorMsg = "";
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "custom-class",
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    this.fileAndIdList = [];
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  onDateSelect(event) {
    let year = event.year;
    let month = event.month <= 9 ? "0" + event.month : event.month;
    let day = event.day <= 9 ? "0" + event.day : event.day;
    let finalDate = year + "-" + month + "-" + day;
    console.log(finalDate);
    this.auditDate = finalDate;
    return finalDate;
  }

  isSaveDisable() {
    debugger
    //console.log("audit"+ this.auditNgbDate);
    if (
      this.categoryId != undefined &&
      this.categoryId != null &&
      this.uploadedFiles.length > 0 &&
      this.isError != true
    ) {
      if (this.categoryId != 1) {
        if (this.isValidCustomer) 
        {
          if (
            this.customerId == undefined ||
            this.customerId == null ||
            this.customerId == "")
             {
            return true;
           } 
        
          
          else{
            if (this.categoryId == 4) {
              if (this.auditNgbDate == undefined || this.auditNgbDate == null || this.auditCategory == undefined || this.auditCategory== null) {
                return true;
              }
            }
            return false;
          }
        } else return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  
  isSaveEnable() {
    debugger;
    console.log(this.uploadedFiles);
    if (
      this.folderId != undefined &&
      this.folderId != null &&
      this.categoryId != 4
    ) {
      if (
        this.categoryId > 1 &&
        this.customerName != "Please enter Valid Customer Id"
      ) {
        // this.isDatePickerHidden = true;
        this.isCustomerIdDisabled = false;
        return false;
      }
    } else if (this.categoryId == 1) {
      this.isCustomerIdDisabled = true;
      this.customerId = "";
      this.customerName = "";
      this.folderId = undefined;
      //this.isDatePickerHidden = true;
      return false;
    } else if (
      this.categoryId == 4 &&
      this.customerId != null &&
      this.customerId != undefined &&
      this.customerId != undefined
    ) {
      this.folderId = undefined;
      this.isCustomerIdDisabled = false;
      //this.isDatePickerHidden = false;
      return false;
    }
    return true;
  }

  saveAllDetails() {
    debugger;
    const formData = new FormData();
    formData.append("categoryId", this.categoryId.toString());
    if (this.categoryId == 4) {
      formData.append("folderId", "0");
    } else {
      formData.append("folderId", this.folderId.toString());
    }
    formData.append("customerId", this.customerId);
    formData.append("fileType", this.fileType);
    formData.append("fileTitle", this.fileTitle);
    formData.append("remark", this.remark);
    formData.append("userId", this.loggedinUserId);
    formData.append("userName", this.loggedInUserName);
    formData.append("fileAndIdList", JSON.stringify(this.fileAndIdList));
    formData.append("auditCategory", this.auditCategory);
    
    if (this.categoryId == 4) {
      formData.append("auditDate", this.auditDate.toString());
    }
    for (let item of this.uploadedFiles) {
      formData.append("file[]", item.file);
    }

    this.customHttpService
      .postData("/account/SaveCustomerFile", formData)
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          debugger
          this.modalService.dismissAll();
          this.getCustomerFilesList();
        }
      });
  }

  navigateFile(fileUrl: string) {
    window.open(fileUrl, "_blank");
  }

  readURLOld(event): void {
    debugger;
    const singleFile = event.target.files[0];
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files && event.target.files[i]) {
        if (event.target.files[i].size > 5000 * 1024) {
          event.currentTarget.remove();
          this.errorMsg = "File size exceeds the maximum limit 5MB";
          this.isError = true;
          //document.getElementById("fileName").innerHTML="Choose file";
          this.fileName = "";
          //event.target.files[0].remove();
          //event.target.files[0].name="";
        } else {
          this.errorMsg = "";
          this.isError = false;
          this.fileArry = event.target.files;
          //console.log("size"+event.target.files[0].size);

          this.customerFile = event.target.files[i];
          this.fileName = event.target.files[i].name;
          //document.getElementById("fileName").innerHTML=event.target.files[0].name;

          this.uploadedFiles.push(event.target.files[i]);
          this.fileAndIdList.push({
            id: event.target.id,
            documentName: event.target.files[i].name,
          });

          console.log(this.fileAndIdList);
        }
      }
    }

    this.uploadedFiles.forEach((element) => {
      if (element.size > 5000 * 1024) {
        this.errorMsg = "File size exceeds the maximum limit 5MB";
        this.isError = true;
      }
    });
  }

  getAllUploadedDocumentByCriteria(
    categoryId: number,
    folderId: number,
    customerId: string,
    documentId: number,
    fileName: string
  ) {
    debugger;
    if (customerId == null) {
      customerId = "";
    }
    this.customHttpService
      .getDataWithAuthentication(
        "/account/GetAllUploadedDocumentByCriteria?categoryId=" +
          categoryId +
          "&folderId=" +
          folderId +
          "&customerId=" +
          customerId +
          "&documentId=" +
          documentId +
          "&fileName=" +
          fileName
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          debugger;
          if (data.responseData.length > 0) {
            console.log("Existing Files: ", data.responseData);
            this.replaceFile = confirm(
              "File already exists. Do you want to replace existing file?"
            );
            if (this.replaceFile == true) {
              this.isFileReplace = true;
              this.fileAndIdList.forEach((x) => {
                if (x.id == documentId) {
                  x.isFileReplace = this.isFileReplace;
                }
              });
              console.log("Replace : ", this.fileAndIdList);
            }
          } else {
            this.isFileReplace = false;
            this.fileAndIdList.forEach((x) => {
              if (x.id == documentId) {
                x.isFileReplace = this.isFileReplace;
              }
            });
            console.log("No Replace : ", this.fileAndIdList);
          }
          this.uploadedDocumentList = data.responseData;
        }
      });
  }

  readURL(event): void {
    debugger;
    if (event.target.files.length == 0 ) {
      this.uploadedFiles.forEach((item, index) => {
        if (item.id == event.target.id) {
          this.uploadedFiles.splice(index, 1);
        }
      });
      console.log("Remove File Array: ", this.uploadedFiles);
      this.fileAndIdList.forEach((item, index) => {
        if (item.id == event.target.id) {
          this.fileAndIdList.splice(index, 1);
        }
      });
      console.log("After Remove File: ", this.fileAndIdList);
      if(this.uploadedFiles.length == 0){
        this.isError = true;
      }
      
    } else {
      for (let i = 0; i < event.target.files.length; i++) {
        var uploadedFile = event.target.files[i];
        var smallFileId = event.target.id;
        var fullFileName = event.target.files[i].name;
        //var fileName = fullFileName.split(".")[0]; // to split extension of file
        var fileName = fullFileName;
        var documentId = event.target.id;
        this.getAllUploadedDocumentByCriteria(
          this.categoryId,
          this.folderId,
          this.customerId,
          documentId,
          fileName
        );

        //below part is for multiple files on choose file
        this.largeFileIdList.forEach((element) => {
          if (element == smallFileId) {
            this.largeFileList.forEach((item, index) => {
              if (item.fileId == smallFileId)
                this.largeFileList.splice(index, 1);
            });

            if (this.largeFileList.length == 0) {
              this.errorMsg = "";
              this.isError = false;
            }
          }
        });

        if (uploadedFile.size <= 5000000) {
          debugger;
          //remove & replace file *** this splice method must be kept before pushing into array
          this.uploadedFiles.forEach((item, index) => {
            if (item.id == event.target.id) {
              this.uploadedFiles.splice(index, 1);
            }
          });
          this.uploadedFiles.push({ id: event.target.id, file: uploadedFile });
          console.log("File Array: ", this.uploadedFiles);

          // remove & replace file with same if *** this splice method must be kept before pushing into array
          this.fileAndIdList.forEach((item, index) => {
            if (item.id == event.target.id) {
              this.fileAndIdList.splice(index, 1);
            }
          });

          this.fileAndIdList.push({
            id: event.target.id,
            documentName: uploadedFile.name,
            isFileReplace: false,
          });

          console.log(this.fileAndIdList);
          //this.errorMsg = "";
          this.isError = false;
        } else {
          this.errorMsg = "File size exceeds the maximum limit 5MB";
          this.isError = true;
          this.largeFileId = event.target.id;
          this.largeFileIdList.push(this.largeFileId);
          this.largeFileList.push({
            fileId: this.largeFileId,
            uploadedFile: uploadedFile,
          });
        }
      }
    }
  }

  search(value: string, searchBy: string): void {
    debugger;
    this.isList = false;
    if (searchBy == "") {
      if (value.length >= 3) {
        this.pageOfItems = this.allPageOfItems.filter(
          (val) =>
            val.customerId.toLowerCase().includes(value.toLowerCase()) ||
            (val.parentId != null &&
              val.parentId.toLowerCase().includes(value.toLowerCase())) ||
            (val.customerName != null &&
              val.customerName.toLowerCase().includes(value.toLowerCase())) ||
            (val.fileName != null &&
              val.fileName.toLowerCase().includes(value.toLowerCase())) ||
            (val.createdOnDisplayDate != null &&
              val.createdOnDisplayDate
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (val.categoryName != null &&
              val.categoryName.toLowerCase().includes(value.toLowerCase())) ||
            (val.folderName != null &&
              val.folderName.toLowerCase().includes(value.toLowerCase())) ||
            (val.documentType != null &&
              val.documentType.toLowerCase().includes(value.toLowerCase())) ||
            (val.remark != null &&
              val.remark.toLowerCase().includes(value.toLowerCase())) ||
            (val.fileSource != null &&
              val.fileSource.toLowerCase().includes(value.toLowerCase()))
        );
      } else {
        this.pageOfItems = this.allPageOfItems;
      }
    } else {
      this.pageOfItems = this.allPageOfItems.filter(
        (val) =>
          val[searchBy] != null &&
          val[searchBy].toLowerCase().includes(value.toLowerCase())
      );
    }
    this.items = this.pageOfItems;
    this.isList = true;
  }

  deleteFile() {
    this.deleteCustomerFile(this.fileId);
  }

  deleteCustomerFile(id) {
    debugger;
    this.customHttpService
      .postData("/account/DeleteCustomerFile", id)
      .subscribe((data) => {
        console.log(data);
        if (data.isSuccess) {
          this.isDelete = true;
          this.getCustomerFilesList();
          this.modalService.dismissAll();
          setTimeout(() => {
            this.isDelete = false;
          }, 5000);
        }
      });
  }

  openConfirmation(content, item) {
    debugger;
    console.log(item);
    this.toBeDeleteDocument = {} as documentModel;
    this.toBeDeleteDocument.categoryName = item.categoryName;
    this.fileId = item.id;

    this.toBeDeleteDocument.id = item.id;
    this.toBeDeleteDocument.customerId = item.customerId;
    this.toBeDeleteDocument.documentType = item.documentType;
    this.toBeDeleteDocument.folderName = item.folderName;

    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if (item.classList != undefined) {
        item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(
      this.items,
      this.pageOfItems,
      this.reverse,
      event,
      column,
      isASc,
      dataTyp
    );
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse;

    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  checkLoading() {
    if (
      this.items != undefined &&
      this.items != null &&
      (this.items.length > 0 || this.isList)
    ) {
      return true;
    } else {
      return false;
    }
  }

  saveFolder() {
    var filterFolder = this.folderList.filter(
      (f) => f.folderName == this.newFolderName
    );
    if (filterFolder.length == 0) {
      this.customHttpService
        .postData("/account/SaveDocumentFolder", {
          FolderName: this.newFolderName,
          CategoryId: this.categoryId,
          CreatedBy: this.loggedinUserId,
        })
        .subscribe((data) => {
          console.log(data);
          if (data.isSuccess) {
            this.getDocumentFolder();
          }
        });
    } else {
    }
  }

  validateDocument() {
    for (let i = 0; i < this.fileAndIdList.length; i++) {
      var filteredList = this.uploadedDocumentList.filter(
        (f) =>
          f.categoryId == this.categoryId &&
          f.folderId == this.folderId &&
          f.customerId == this.customerId &&
          f.fileName == this.fileAndIdList[i].documentName
      );

      if (filteredList.length > 0) {
        //fff
      }
    }
  }

  isDatePickerHidden() {
    if (this.categoryId != 4) {
      return true;
    } else {
      return false;
    }
  }

  redirectToDocumentMapping() {
    var url = "document_mapping";
    window.open(url, "_blank");
  }
}

export interface documentModel {
  id: number;
  customerId: string;
  folderName: string;
  categoryName: string;
  documentType: string;
}
