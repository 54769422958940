import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonMethods } from 'src/app/services/commonmethods';
import { formatDate, JsonPipe } from '@angular/common';
import { NgbAccordionConfig, NgbDate, NgbDateParserFormatter,NgbTimepickerModule  } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/services/dateformat';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
const URL = 'https://dashboardapi.hicare.in/omdashboard/api/Complaint/PostFile';
//const URL = 'https://connect.hicare.in/omdashboard/api/Complaint/PostFile';
//const URL = 'https://localhost:5001/api/Complaint/PostFile';


@Component({
  selector: 'app-tickets-interaction-detail',
  templateUrl: './tickets-interaction-detail.component.html',
  styleUrls: ['./tickets-interaction-detail.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class TicketsInteractionDetailComponent implements OnInit {

  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserId: string = "";
  loggedInUserName: string = "";
  loggedinUserType: string = "";
  complaintAttachment: ComplaintAttachment[] = [];
  ticketId: string;
  interactionList: any;
  complaint: any;
  accountId: string;
  userResponse: string;
  loggedinUserEmailid: string;
  randomColor = ["green", "#cc065f", "#078da6", "#bcb80f", "#0f026a"];
  statusList = [];
  newStatus: string = null;
  isSave: boolean = false;
  lastVisitNgbDate: NgbDate;
  nextVisitNgbDate: NgbDate;
  nextVisitDate: NgbDate;
  pickerDate: NgbDate;
  uploader: FileUploader;
  uploadCounter: number;
  attachmentUrl1: string = "";
  attachmentUrl2: string = "";
  attachmentUrl3: string = "";
  fileName1: string = "";
  fileName2: string = "";
  fileName3: string = "";
  followupDate:NgbDate;
  followupReason:string;
  followupId:number;
  ngbTime:any;
  isFollowupSave:boolean=false;
  pestCountList=[];
  minutes: number = 5;
  seconds: number = 0;
  isTimerShow:boolean=false;
  isTimerUnable:boolean=false;
  viewInteraction: string = '';
  isViewInteraction: boolean = true;
  isInteractionFound: boolean = false;
  isInteractionLoad:boolean=true;

  constructor(private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService, private modalService: NgbModal, private commonMethods: CommonMethods) {
    this.accountId = this.activatedRoute.snapshot.params.accountId;
    this.ticketId = this.activatedRoute.snapshot.params.ticketId;
    var date = new Date();
    this.pickerDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());

  }

  ngOnInit() {

    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedInUserName = localStorage.getItem("omloggedinusername");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.loggedinUserEmailid = localStorage.getItem("omloggedinemail");

    if(this.loggedinUserEmailid==undefined || this.loggedinUserEmailid==null || this.loggedinUserEmailid=='')
    {
      window.location.href="https://onedashboard.hicare.in";
    }
    //this.statusList = this.commonMethods.ticketStatusList;
    
    this.getTicketsInteractionDetails();

    this.uploader = new FileUploader({
      url: URL,
      autoUpload: true,
    });
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);

    this.isTimerShow=false;
    this.startTimer();
  }


  startTimer() {
    setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          // Timer has ended
          this.isTimerShow=false;
        } else {
          this.minutes--;
          this.seconds = 59;
        }
      } else {
        this.seconds--;
      }
    }, 1000);

  }
  
  removeImage(index){
    switch (index) {
      case 1:
        this.attachmentUrl1 ="";
        this.fileName1 = "";
        break;
      case 2:
        this.attachmentUrl2 ="";
        this.fileName2 = "";
        break;
      case 3:
        this.attachmentUrl3 ="";
        this.fileName3 = "";
        break;
      default:
        this.attachmentUrl1 ="";
        this.fileName1 = "";
        break;
    }
  }
  setCurrentUploadIndex(count: number) {
    debugger;
    this.uploadCounter = count;
  }
  onSuccessItem(item: FileItem, response: any, status: number, headers: ParsedResponseHeaders): any {
    debugger;
    console.log(response);
    if (response != undefined && response != null && response != '') {
      var attachmentResponse = JSON.parse(response) as UploadResponse;
        console.log( "onsuccess" + this.uploadCounter);
        console.log(attachmentResponse);
        console.log(attachmentResponse.responseData);
      switch (this.uploadCounter) {
        case 1:
          this.attachmentUrl1 = attachmentResponse.responseData;
          console.log(this.attachmentUrl1);
          var path = this.attachmentUrl1.split("/");
          this.fileName1 = path[path.length - 1].split("_")[1];
          break;
        case 2:
          this.attachmentUrl2 = attachmentResponse.responseData;
          console.log(this.attachmentUrl2);
          var path = this.attachmentUrl2.split("/");
          this.fileName2 = path[path.length - 1].split("_")[1];
          break;
        case 3:
          this.attachmentUrl3 = attachmentResponse.responseData;
          console.log(this.attachmentUrl3);
          var path = this.attachmentUrl3.split("/");
          this.fileName3 = path[path.length - 1].split("_")[1];
          break;
        default:
          this.attachmentUrl1 = attachmentResponse.responseData;
          console.log(this.attachmentUrl1);
          var path = this.attachmentUrl1.split("/");
          this.fileName1 = path[path.length - 1].split("_")[1];
          break;
      }
    }
    else {
      alert("Somthing went wrong. Please try again.");
    }
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    debugger;
    let error = JSON.parse(response); //error server response
    console.log(error);
    alert("Somthing went wrong. Please try again.");
  }
  download(url) {
    window.open(url, "_blank");
  }
  getTicketsInteractionDetails() {

    this.customHttpService.getDataWithAuthentication("/Complaint/GetTicketComplaintDetailById?ticketId=" + this.ticketId)
      .subscribe(data => {
        debugger;
        console.log(data.responseData)
        //this.interactionList = data.responseData.interactionList;
        this.complaint = data.responseData;
        //this.lastVisitNgbDate= new NgbDate(new Date().getFullYear(),new Date().getMonth()+1,new Date().getDate()+1);
        // if(this.complaint.lastVisitDate!=undefined && this.complaint.lastVisitDate!=null && this.complaint.lastVisitDate!='')
        // {
        // var date = new Date(this.complaint.lastVisitDate);
        //    this.lastVisitNgbDate=new NgbDate(date.getFullYear(),date.getMonth()+1, date.getDate());
        // }
        // if(this.complaint.nextVisitDate!=undefined && this.complaint.nextVisitDate!=null && this.complaint.nextVisitDate!='')
        // {
        //    var date = new Date(this.complaint.nextVisitDate);
        //    this.nextVisitNgbDate=new NgbDate(date.getFullYear(),date.getMonth()+1, date.getDate());
        // }
        this.getTicketStatus();
        //this.getFollowupDetail();
        this.getPestDetailList();

      });


    //this.getInteractions();

    //commonService.getInteractionDetailsByTicket(this.ticketId)
    // this.interactionList = [{
    //   "body": "<div style='font - family:-apple - system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans - serif; font - size:14px'> <div>Hi Abhishek Singh,</div> <div dir='ltr'> <br>Complaint has been raised. </div> <div dir='ltr'>​<br> </div> <div dir='ltr'>@Vikas, please look into this. </div> <div dir='ltr'>​</div> <div dir='ltr'>Complaint number - 331894<br><br> </div> <div dir='ltr'><br></div> </div>",
    //   "from_email": "Hicare Enterprise Care < enterprise.care@hicare.in>", "created_at": "2022-06-29T03:34:38Z","timeDifference":"1 month ago","userFirstLetter":"H","createdDisplayDate":"31 July 2022",
    //   "attachments": [{"thumb_url":"",
    //   "attachment_url":"https://hicarehelp.attachments1.freshdesk.com/data/helpdesk/attachments/production/2043429957221/original/638150000991144861_HE%20training%20certificate.pdf?Expires=1679383999&Signature=HoVmaHOSy6nn65dtfJgGLpKKDVsar0TKWsy-Iammhdu3htgpzQz6wWlphrwgQ-qi1AN--UpolG8Wj4Kn7eu1DqdauDg-ZjVD51i-VzWwSVyAQa2YUXQKLmqIw0SQgdgz7II4nm~nPsVhEBR~2Y-qMPkDUPwQFtSSIemmj2CTyHGPoURjdZVTrBezqziNJq-NDmjGJYraoX4K6mT1mcSqf06QgbpnIDMAXGO6YxGhyjlbjc-htHeoLT6h4fgjasCvwESUSgTL0PGSf-Jy3vFRwMDThGv5VnCKB-OhQkVm8Dp5ecjj8bMXA1zHGTf-f0C5aE3qUPekOve786iDk4dZdQ__&Key-Pair-Id=APKAJ7JARUX3F6RQIXLA",
    //   "name": "638150000804898683_Test2.pdf"},
    //   {"thumb_url":"",
    //   "attachment_url":"https://hicarehelp.attachments1.freshdesk.com/data/helpdesk/attachments/production/2043429738867/original/638149352679054208_HiCareHC_Assam_WB221261.pdf?Expires=1679319231&Signature=nNU8JXgAI~pZPvFiSRbrGFnAkRXqUNz1kVgQ31odksJKnkGSjFgrd9-OKzLXgH~3giXXSdUskmsCc8QaX~bsZC~M2n-SjVhpu~v4UQoXibP2NiClPmJA3QDFI~VyLcmxGmcxGAJr8NU1x1ZSS7rcnRzyGa4WctthSTzYzEWMwm-T6iIluXe94iCh7b-kP50mBWinOdB1LGP4I7XPTdnio58KIiYCTbpWX90UttG~Y1ApfGFjcnadtF0JhFTfpr75D9rVStau43jRa1NlfTwIYA~Hne7BdHRQkeR7o25L6VZwqOAJUjAq4kKd8IsFmwhzHgzq8iD6R9JR3jLWa459Tw__&Key-Pair-Id=APKAJ7JARUX3F6RQIXLA<",
    //   "name": "638150000991144861_HE training certificate.pdf"}
    // ],
      

    // },
    // {
    //   "body": "Hi Team, We have done flushout tratmentment, This is preschedule service, We have found roaches in monitoring trap. No breeding point found. Regards, Vikas Singh Operation Manager 90821 49224 [New logo] HiCare Services Pvt. Ltd. 11/197, Bhirgu Path Mansarovar Jaipur 302020 www.hicare.in<http://www.hicare.in> ",
    //   "from_email": "vikas.s@hicare.in", "created_at": "2022-05-31T06:47:05Z"
    // }]



  }

  getInteractions() {
    this.isTimerShow=false;
    this.isInteractionLoad=false;
    
    this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain + "/Ticket/GetTicketConversations?ticketId=" + this.ticketId).subscribe(data => {
      debugger;
      if( data == null || data == undefined) {
        this.isInteractionFound = true;
        this.viewInteraction = "No Interactions Found!";
      }
      else if( data.length == 0){
        this.isInteractionFound = true;
        this.viewInteraction = "No Interactions Found!";
      }
      this.isInteractionLoad=true;
      if (data != undefined && data != null && data.length > 0) {
        this.isViewInteraction = false;
        this.interactionList = data;
        for (let i = 0; i < this.interactionList.length; i++) {
          this.interactionList[i].timeDifference = this.commonMethods.calculateDateDiff(this.interactionList[i].created_at);
          this.interactionList[i].userFirstLetter = this.interactionList[i].from_email.substr(0, 1);
          console.log(this.interactionList[i].timeDifferenc);
          this.interactionList[i].userIconColor = this.randomColor[Math.floor(Math.random() * 5)];

          var dateSent = new Date(this.interactionList[i].created_at);
          this.interactionList[i].createdDisplayDate = dateSent.getDate() + " " + this.commonMethods.monthNames[dateSent.getMonth()] + " " + dateSent.getFullYear();

          if(this.interactionList[i].attachments!=undefined && this.interactionList[i].attachments!=null && this.interactionList[i].attachments.length>0){
            this.isTimerShow=true;
            this.isTimerUnable=true;
          }

        }
      }

    });

  }

  submitResponse() {
    debugger;
    console.log(this.userResponse);
    var monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    if (this.newStatus != undefined && this.newStatus != null && this.newStatus != '') {
      var autoPrependText = "";
      if (this.userResponse != undefined && this.userResponse != null && this.userResponse != "") {
        autoPrependText = "<hr/>";
      }
      autoPrependText = autoPrependText + "\nStatus changed to: " + this.newStatus;
      var lvDate = this.lastVisitNgbDate != null ? (this.lastVisitNgbDate.day.toString() + "-" + monthNames[this.lastVisitNgbDate.month-1].toString() + "-" + (this.lastVisitNgbDate.year).toString()) : null;
      var nvDate = this.nextVisitNgbDate != null ? (this.nextVisitNgbDate.day.toString() + "-" + monthNames[this.nextVisitNgbDate.month-1].toString() + "-" + (this.nextVisitNgbDate.year).toString()) : null;
      if (lvDate != undefined && lvDate != null && lvDate != "") {
        autoPrependText = autoPrependText + ", Last Visit Date: " + (lvDate==null || lvDate=="null"?"":lvDate);
      }
      if (lvDate != undefined && lvDate != null && lvDate != "") {
        autoPrependText = autoPrependText + ", Next Visit Date: " + (nvDate==null || nvDate=="null"?"":nvDate);
      }
      if (this.userResponse != undefined && this.userResponse != null && this.userResponse != "") {
        this.userResponse = this.userResponse + autoPrependText;
      }
      else {
        this.userResponse = autoPrependText;
      }
    }
    if (this.userResponse != undefined && this.userResponse != null && this.userResponse != "") {
      debugger;
      this.userResponse = this.userResponse + ", updated by: "+this.loggedInUserName + " | " + this.loggedinUserEmailid; 
      var dataToPost = this.userResponse.split("\n").join("<br/>");
      var attachments = [];
      console.log("inside int call")
      console.log(this.attachmentUrl1);
      console.log(this.attachmentUrl2);
      console.log(this.attachmentUrl3);
      if (this.attachmentUrl1 != undefined && this.attachmentUrl1 != null && this.attachmentUrl1 != '') {
        var path = this.attachmentUrl1.split("/");
        attachments.push({ FileName: path[path.length - 1], FilePath: this.attachmentUrl1 });
      }
      if (this.attachmentUrl2 != undefined && this.attachmentUrl2 != null && this.attachmentUrl2 != '') {
        var path = this.attachmentUrl2.split("/");
        attachments.push({ FileName: path[path.length - 1], FilePath: this.attachmentUrl2 });

      }
      if (this.attachmentUrl3 != undefined && this.attachmentUrl3 != null && this.attachmentUrl3 != '') {
        var path = this.attachmentUrl3.split("/");
        attachments.push({ FileName: path[path.length - 1], FilePath: this.attachmentUrl1 });

      }

      console.log(dataToPost);

      this.customHttpService.postDataWithoutAuthenticationForAbsoluteURL(this.commonMethods.ticketDomain + "/Ticket/AddTicketComment",
        { "TicketId": this.ticketId, "Message": dataToPost, "CustomerEmail": this.loggedinUserEmailid, "Attachments": attachments })
        .subscribe(data => {
          this.isSave = true;
          setTimeout(() => { this.isSave = false; }, 5000);
          this.userResponse="";
          this.attachmentUrl1="";
          this.attachmentUrl2="";
          this.attachmentUrl3="";
          this.fileName1="";
          this.fileName2="";
          this.fileName3="";
          this.getInteractions();
        });
    }

    //submit status
    if (this.newStatus != undefined && this.newStatus != null && this.newStatus != '') {
      this.changeStatus();
    }
  }

  changeStatus() {
    var statusToBeSentToFD = this.newStatus.replace("-", "");
    statusToBeSentToFD = statusToBeSentToFD.replace("/","");
    statusToBeSentToFD = statusToBeSentToFD.replace("-","");
    statusToBeSentToFD = statusToBeSentToFD.replace(/ /g, '');
    console.log(this.newStatus);
    console.log(statusToBeSentToFD);
    console.log(this.lastVisitNgbDate);
    console.log(this.nextVisitNgbDate);
    this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain + "/Ticket/ChangeStatus?ticketId=" + this.complaint.fdTicketId + "&status=" + statusToBeSentToFD)
      .subscribe(data => {
        var postData = {};
        var lvDate = this.lastVisitNgbDate != null ? (this.lastVisitNgbDate.year.toString() + "-" + (this.lastVisitNgbDate.month).toString() + "-" + (this.lastVisitNgbDate.day).toString()) : null;
        var nvDate = this.nextVisitNgbDate != null ? (this.nextVisitNgbDate.year.toString() + "-" + (this.nextVisitNgbDate.month).toString() + "-" + (this.nextVisitNgbDate.day).toString()) : null;
        postData = { "OldStatus": this.complaint.status, "Status": this.newStatus, "TicketId": this.complaint.fdTicketId, "LastVisitDate": lvDate, "NextVisitDate": nvDate };

        var date = new Date();
        var fcDate = (this.complaint.status == 'Open' && (this.newStatus == "Inspection Scheduled" || this.newStatus == "Treatment Scheduled" || this.newStatus == "Treated - Re-inspection Needed" || this.newStatus == "Treated - Site under Observation")) ? (date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString()) : null;
        var resolutionDate = ((this.newStatus == "Resolved - Client Confirmation Pending")) ? (date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString() + "-" + date.getDate().toString()) : null;


        if (fcDate != null || resolutionDate != null || lvDate != null || nvDate != null) {
          if (lvDate == null && this.complaint.lastVisitDate != undefined && this.complaint.lastVisitDate != null && this.complaint.lastVisitDate != '') {
            var date = new Date(this.complaint.lastVisitDate);
            this.lastVisitNgbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            lvDate = this.lastVisitNgbDate != null ? (this.lastVisitNgbDate.year.toString() + "-" + (this.lastVisitNgbDate.month).toString() + "-" + (this.lastVisitNgbDate.day).toString()) : null;

          }
          if (nvDate == null && this.complaint.nextVisitDate != undefined && this.complaint.nextVisitDate != null && this.complaint.nextVisitDate != '') {
            var date = new Date(this.complaint.nextVisitDate);
            this.nextVisitNgbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            nvDate = this.nextVisitNgbDate != null ? (this.nextVisitNgbDate.year.toString() + "-" + (this.nextVisitNgbDate.month).toString() + "-" + (this.nextVisitNgbDate.day).toString()) : null;
          }
          this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain + "/Ticket/SetVisitingDates?ticketId=" + this.complaint.fdTicketId + "&lastVisitDate=" + lvDate + "&nextVisitDate=" + nvDate + "&firstConnectDate=" + fcDate + "&resolutionDate=" + resolutionDate).subscribe(dateresponse => {
            this.customHttpService.postData("/Complaint/ChangeComplaintStatus", postData
            ).subscribe(data => {
              this.isSave = true;
              this.getTicketsInteractionDetails();
              setTimeout(() => { this.isSave = false; }, 5000);
            });
          });
        } else {
          if (lvDate == null && this.complaint.lastVisitDate != undefined && this.complaint.lastVisitDate != null && this.complaint.lastVisitDate != '') {
            var date = new Date(this.complaint.lastVisitDate);
            this.lastVisitNgbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            lvDate = this.lastVisitNgbDate != null ? (this.lastVisitNgbDate.year.toString() + "-" + (this.lastVisitNgbDate.month).toString() + "-" + (this.lastVisitNgbDate.day).toString()) : null;

          }
          if (nvDate == null && this.complaint.nextVisitDate != undefined && this.complaint.nextVisitDate != null && this.complaint.nextVisitDate != '') {
            var date = new Date(this.complaint.nextVisitDate);
            this.nextVisitNgbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            nvDate = this.nextVisitNgbDate != null ? (this.nextVisitNgbDate.year.toString() + "-" + (this.nextVisitNgbDate.month).toString() + "-" + (this.nextVisitNgbDate.day).toString()) : null;
          }
          postData = { "OldStatus": this.complaint.status, "Status": this.newStatus, "TicketId": this.complaint.fdTicketId, "LastVisitDate": lvDate, "NextVisitDate": nvDate };

          this.customHttpService.postData("/Complaint/ChangeComplaintStatus", postData
          ).subscribe(data => {
            this.isSave = true;
            this.lastVisitNgbDate = null;
            this.nextVisitNgbDate = null;
            this.newStatus = null;
            this.userResponse = "";
            this.getTicketsInteractionDetails();
            setTimeout(() => { this.isSave = false; }, 5000);
          });
        }

      });
    // this.isSave = true;
    // this.complaint.status=this.newStatus;
    // setTimeout(() => { this.isSave = false; }, 5000);
  }

  checkStatus(status) {
    //if (status == 'Closed' || status == 'Resolved')
    //  return false;
    //else
    //  return true;
    return true;
  }

  getTicketStatus(){
    debugger;
    this.customHttpService.getDataWithAuthentication("/Complaint/GetComplaintStatusMasterList?userTypeName=" + this.loggedinUserType + "&userEmail="+this.loggedinUserEmailid)
      .subscribe(data => {
        console.log(data.responseData);

        this.statusList=data.responseData;
        if(this.statusList.filter(p=>p.StatusName == "Closed").length<=0)
        {
          if(this.complaint.subject.indexOf("AutoEmailer")>-1)
          {
            this.statusList.push({statusName:"Closed",id:4,statusId:5,acessRoles:""});
          }
        }
      })
  }
  onDateSelect(event) {
    debugger;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = day + "-" + month + "-" + year;
    return finalDate;
  }
  navigateToAccountDetail(accountId:string){
    var accountNo= accountId.split('/')[0];
    var url = "tickets_detail/"+accountNo;
    window.open(url, "_blank");
  }

  saveFollowupDate(){
console.log( this.ngbTime);
var followDate=new Date(this.followupDate.year,this.followupDate.month-1,this.followupDate.day+1);
    this.customHttpService.postData("/complaint/SaveTicketComplaintFollowUp" ,
    {
      "Id":this.followupId,
      "FdTicketId":this.ticketId,
      "FollowUpDate":followDate,
      "Reason":this.followupReason,
      "UserId":this.loggedinUserId,
      "UserEmail":this.loggedinUserEmailid
    }).subscribe(data => {
      if (data.isSuccess) {
        this.isFollowupSave=true;
        this.followupReason="";
        this.followupDate=null;
        setTimeout(() => { this.isFollowupSave = false; }, 5000);
      }})
  }
  getFollowupDetail(){
    debugger;
    this.customHttpService.getDataWithAuthentication("/Complaint/GetFollowupDetailByTicket?ticketId=" + this.ticketId)
    .subscribe(data => {
      debugger;
      var followDate=new Date( data.responseData.followUpDate);
      this.followupDate=new NgbDate(followDate.getFullYear(),followDate.getMonth()+1,followDate.getDate());
      this.followupReason=data.responseData.reason;
      this.followupId=data.responseData.id;
    }
    )
  }
  getPestDetailList(){
    debugger;
    if(this.complaint.accountId!=null && this.complaint.accountId!=''){
    this.pestCountList=[];
    this.customHttpService.getDataWithAuthentication("/Complaint/GetPestDetailsByAccount?accountNo=" + this.complaint.accountId+"&lastRecordCnt=3")
    .subscribe(data => {
    
      console.log(data.responseData);
      this.pestCountList=data.responseData;
    })
    }
  }
}

export interface ComplaintAttachment {
  Id: number;
  Complaint_Id: number;
  FileName: string;
  Attachment_Url: string;
}

export interface UploadResponse
{
  isSuccess : boolean,
  responseData:string,
  errorMessage:string,
  uiMessage:String;
}
