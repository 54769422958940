import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-s3login',
  templateUrl: './s3login.component.html',
  styleUrls: ['./s3login.component.scss']
})

export class S3loginComponent implements OnInit {

  userId:string="";
  omloggedInMobileNumber:string;
  omgcbstoretoken:string;
  omloggedinemail:string;
  omloggedinusername:string;
  omloggedinuserid:string;
  omloggedinusertype:string;
  userInfo:any={};
  sfdcCode:string="";

  constructor(private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private router:Router) {
    this.userId = this.activatedRoute.snapshot.params.id;
    this.sfdcCode= this.activatedRoute.snapshot.queryParamMap.get('code');
   }

  ngOnInit() {
    if(this.sfdcCode==undefined ||this.sfdcCode==null||this.sfdcCode==""){
    this.getUserById();
    }
    else{
      this.validateSFDCUserByCode();
     }
  }

  getUserById(){
    this.customHttpService.getData("/user/GetUserById?id="+this.userId)
    .subscribe(data=>{
      if(data.isSuccess){
        localStorage.setItem("omloggedInMobileNumber",data.responseData.mobile);
        localStorage.setItem("omgcbstoretoken",data.responseData.accessToken);
        localStorage.setItem("omloggedinemail",data.responseData.email);
        localStorage.setItem("omloggedinusername",data.responseData.name);
        localStorage.setItem("omloggedinuserid",data.responseData.id);
        localStorage.setItem("omloggedinusertype",data.responseData.typeName);

        this.setUserInfo();
        this.router.navigate(["dashboard"]);
      }
     
    });
  }
  setUserInfo(){
    this.userInfo.omloggedInMobileNumber=localStorage.getItem("omloggedInMobileNumber");
    this.userInfo.omgcbstoretoken= localStorage.getItem("omgcbstoretoken");
    this.userInfo.omgcbstoretoken=localStorage.getItem("omloggedinemail");
    this.userInfo.omloggedinusername=localStorage.getItem("omloggedinusername");
    this.userInfo.omloggedinuserid=localStorage.getItem("omloggedinuserid");
    this.userInfo.omloggedinusertype= localStorage.getItem("omloggedinusertype");

    return this.userInfo;
  }

  validateSFDCUserByCode(){
    debugger;
    this.customHttpService.getData("/user/ValidateSFDCUserByCode?code="+this.sfdcCode)
    .subscribe(data=>{
      debugger;
      if(data.isSuccess){
        localStorage.setItem("omloggedInMobileNumber",data.responseData.mobile);
        localStorage.setItem("omgcbstoretoken",data.responseData.accessToken);
        localStorage.setItem("omloggedinemail",data.responseData.email);
        localStorage.setItem("omloggedinusername",data.responseData.name);
        localStorage.setItem("omloggedinuserid",data.responseData.id);
        localStorage.setItem("omloggedinusertype",data.responseData.typeName);

        this.setUserInfo();
        this.router.navigate(["dashboard"]);
      }
      else{
        alert("No Access found for OM Insights, please contact IT Support Team.");
      }
     
    });
  }
}
