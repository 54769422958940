import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';

@Component({
  selector: 'app-srbase',
  templateUrl: './srbase.component.html',
  styleUrls: ['./srbase.component.scss']
})
export class SrbaseComponent implements OnInit {

  period : string="";
  srStatus:string="";
  srList:any=[];
  selectedZRMName:string="";
  selectedRMName:string="";
  selectedRegion :string="";
  items = [];
  pageOfItems: Array<any>;
  constructor(private httpClient: HttpClient, private activatedRoute:ActivatedRoute,private customHttpService:CustomhttpService) { 
  this.period=  this.activatedRoute.snapshot.params.period;
  this.srStatus=  this.activatedRoute.snapshot.params.status;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}
  ngOnInit(): void {
    this.selectedZRMName = localStorage.getItem("SelectedZRMName");
this.selectedRMName = localStorage.getItem("SelectedRMName");
this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.getSRList();
  }

  getSRList(){
    var zrmId = localStorage.getItem("SelectedZRMId");
    var rmId = localStorage.getItem("SelectedRMId");
    var region = localStorage.getItem("SelectedRegion");

this.customHttpService.getDataWithAuthentication("/SR/GetSRList?zrmId="+
zrmId+"&rmId="+rmId+"&region="+region+"&period="+this.period+"&srStatus="+this.srStatus).subscribe(data=>{
  console.log(data);
  if(data.isSuccess)
  {
    console.log(data);
    this.srList = data.responseData;
    this.items = data.responseData;
  }
});

}


roundNumber(no:any){
  return parseFloat(no).toFixed(2);
}

exportToExcel(){
  var zrmId = localStorage.getItem("SelectedZRMId");
  var rmId = localStorage.getItem("SelectedRMId");
  var region = localStorage.getItem("SelectedRegion");
debugger;
  this.customHttpService.exportDataWithAuthentication("/SR/ExportSRList?zrmId="+
  zrmId+"&rmId="+rmId+"&region="+region+"&period="+this.period+"&srStatus="+this.srStatus)
  .subscribe(data=>{
    console.log(data);
    let b: any = new Blob([data], {type: 'application/vnd.ms-excel' });
    var url = window.URL.createObjectURL(b);
    window.open(url);
  });
//   fetch("/Complaint/ExportComplaintList?zrmId="+
//    zrmId+"&rmId="+rmId+"&region="+region+"&segmentType="+this.segmentType+"&period="+this.period+"&complaintStatus="+this.complaintStatus, {
//     method: 'GET',
//     headers: {"Authorization":"bearer " + localStorage.getItem("gcbstoretoken")}
// })
//     .then(response => response.blob())
//     .then(blob => URL.createObjectURL(blob))
//     .then(url => {
//         var link = document.createElement("a");
//         link.setAttribute("href", url);
//         link.setAttribute("download", "test.csv");
//         link.style.display = "none";
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//     });
}

}
