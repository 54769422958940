import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from '../services/customhttp.service';
import {CommonMethods } from 'src/app/services/commonmethods';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.scss']
})
export class LeaderBoardComponent implements OnInit {
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  selectedMonth: string = "";
  npsNumbers: any = [];
  otifNumbers: any = [];
  complaintNumbers: any = [];
  deliveryNumbers: any = [];
  productivityNumbers: any = [];
  activeIdString: any = "1";
  scoreCardList: any = [];
  selectedCategory: string = "OM";
  adherenceScoreList:any=[];
  adherenceNumber:any=[];

  npsData:any=[];
  otifData:any=[];
  complaintsData:any=[];
  deliveryData:any=[];
  productivityData:any=[];
  reverse: boolean = false;
  sortText:string;
 

  constructor(private customhttpService: CustomhttpService,private commonMethods : CommonMethods) {
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.selectedMonth = localStorage.getItem("SelectedMonthOM");
  }

  ngOnInit() {
    this.getNumbersForLeaderBoard();
  }

  changeCategory(category: string,event) {
    console.log("change Cate");
    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.warn("target", parent.parentElement);   
    
    console.log(document.getElementsByClassName("dv-heading"));
    var divEle= document.getElementsByClassName("dv-heading")
    for(var i=0;i<divEle.length;i++){
      var element= divEle[i];
      element.classList.remove("divBackColor");
    }    
    if (event.srcElement.nodeName == 'SPAN') {
      parent.classList.add("divBackColor");
    }
    else{
    target.classList.add("divBackColor");
    }
    this.selectedCategory = category;
    this.scoreCardList=[];
    this.deliveryNumbers=[];
    this.otifNumbers=[];
    this.productivityNumbers=[];
    this.npsNumbers=[];
    this.complaintNumbers=[];
    this.adherenceScoreList=[];

    this.setLeaderboardOnCategoryChange();    
  }

  getNumbersForLeaderBoard() {
    this.getNPSNumbersForLeaderBoard();
    this.getOTIFNumbersForLeaderBoard();
    this.getComplaintNumbersForLeaderBoard();
    this.getDeliveryNumbersForLeaderBoard();
    this.getProductivityNumbersForLeaderBoard();
    this.getControlRoomAdherenceForLeaderBoard();

     var divEle= document.getElementsByClassName("dv-heading");
    // this.commonMethods.commonSort(this.scoreCardList, this.scoreCardList,this.reverse,null,'NPS',false); 
    //  this.sortText="NPS (Descending)"; 
     divEle[0].classList.add("divBackColor");
  }

  getNPSNumbersForLeaderBoard() {
    this.customhttpService.getDataWithAuthentication("/Task/GetNPSSummaryModel?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=All Regions&rom=0&om=0&servicecenter=All SC&category=MTD" + "&serviceType=Total")
      .subscribe(data => {

        this.npsData=data.responseData;
        this.npsNumbers = this.getLeaderboardNumberByCategory(this.npsData,"NPS")
      
        console.log(this.npsNumbers);
        this.createScoreCard(this.npsNumbers, "NPS");
      });

  }

  getOTIFNumbersForLeaderBoard() {
    this.customhttpService.getDataWithAuthentication("/Task/GetOtifPercentage?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=All Regions&rom=0&om=0&servicecenter=All SC&category=OTIFPer&period=MTD" + "&type=Total")
      .subscribe(data => {

        this.otifData=data.responseData;
        //this.otifNumbers = data.responseData.omList.sort((a, b) => b.yAxis - a.yAxis);
        this.otifNumbers =  this.getLeaderboardNumberByCategory(this.otifData,"Otif");
        console.log(this.otifNumbers);
        this.createScoreCard(this.otifNumbers, "OTIF");
      });
  };

  getComplaintNumbersForLeaderBoard() {
    this.customhttpService.getDataWithAuthentication("/Complaint/GetOMComplaintPercentList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=All Regions&rom=0&om=0&servicecenter=All SC&category=CompPer&period=MTD" + "&type=Total")
      .subscribe(data => {

        this.complaintsData=data.responseData;
        //this.complaintNumbers = data.responseData.omList.sort((a, b) => a.yAxis - b.yAxis);
        this.complaintNumbers =  this.getLeaderboardNumberByCategory(this.complaintsData,"Complaints");
        console.log(this.complaintNumbers);
        this.createScoreCard(this.complaintNumbers, "Complaints");
      });
  };

  getDeliveryNumbersForLeaderBoard() {
    this.customhttpService.getDataWithAuthentication("/Task/GetTaskDeliverySummary?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=All Regions&rom=0&om=0&servicecenter=All SC&category=Percentage&period=MTD" + "&type=Total")
      .subscribe(data => {

        this.deliveryData=data.responseData;
        //this.deliveryNumbers = data.responseData.omList.sort((a, b) => b.yAxis - a.yAxis);
        this.deliveryNumbers = this.getLeaderboardNumberByCategory(this.deliveryData,"SD");
        console.log(this.deliveryNumbers);
        this.createScoreCard(this.deliveryNumbers, "SD");

      });
  };

  getProductivityNumbersForLeaderBoard() {
    this.customhttpService.getDataWithAuthentication("/Task/GetHEProductivitySummary?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=All Regions&rom=0&om=0&servicecenter=All SC&category=Delivered&period=MTD" + "&type=AvgSV")
      .subscribe(data => {

        this.productivityData=data.responseData[0];
       // this.productivityNumbers = data.responseData[0].omList.sort((a, b) => b.yAxis - a.yAxis);
        this.productivityNumbers =this.getLeaderboardNumberByCategory(this.productivityData,"Productivity");
        console.log(this.productivityNumbers);
        this.createScoreCard(this.productivityNumbers, "Productivity");

      });

  };

  getControlRoomAdherenceForLeaderBoard(){
    this.customhttpService.getDataWithAuthentication("/Task/GetControlRoomAdherenceForLeaderBoard?userId=" + this.loggedinUserId +
    "&userTypeName=" + this.loggedinUserType + "&region=All Regions&rom=0&om=0&servicecenter=All SC")
    .subscribe(data => {

      this.adherenceNumber=data.responseData;
      debugger;
    
      this.createAdherenceScoreCard( this.adherenceNumber, "B2B");
      this.createAdherenceScoreCard(this.adherenceNumber, "TMS");
      this.createAdherenceScoreCard(this.adherenceNumber, "CMS");
      this.createAdherenceScoreCard(this.adherenceNumber, "QRVerify");
      this.createAdherenceScoreCard(this.adherenceNumber, "JobCardVerify");

    });
  };

  
getLeaderboardNumberForAdherence(data:any,dataType:string){
  if (dataType == "B2B") {
     return this.getLeaderboardNumberByCategory(data.b2BJobCard,"Adherence");
    }
    if (dataType == "TMS") {
      return this.getLeaderboardNumberByCategory(data.tmsDigitization,"Adherence");
    }
    if (dataType == "CMS") {
      return this.getLeaderboardNumberByCategory(data.cmsDigitization,"Adherence");
    }
    if (dataType == "QRVerify") {
      return this.getLeaderboardNumberByCategory(data.qrVerification,"Adherence");
    }
    if (dataType == "JobCardVerify") {
      return this.getLeaderboardNumberByCategory(data.jobCardVarification,"Adherence");
    }
}


  setLeaderboardOnCategoryChange() {
    //this.sortText="";
    debugger;
    this.npsNumbers = this.getLeaderboardNumberByCategory(this.npsData,"NPS")
    this.createScoreCard(this.npsNumbers, "NPS");

    this.otifNumbers =  this.getLeaderboardNumberByCategory(this.otifData,"Otif");
    this.createScoreCard(this.otifNumbers, "OTIF");

    this.complaintNumbers =  this.getLeaderboardNumberByCategory(this.complaintsData,"Complaints");
    this.createScoreCard(this.complaintNumbers, "Complaints");

    this.deliveryNumbers = this.getLeaderboardNumberByCategory(this.deliveryData,"SD");
    this.createScoreCard(this.deliveryNumbers, "SD");

    this.productivityNumbers =this.getLeaderboardNumberByCategory(this.productivityData,"Productivity");
    this.createScoreCard(this.productivityNumbers, "Productivity");

    //new
    this.createAdherenceScoreCard( this.adherenceNumber, "B2B");
      this.createAdherenceScoreCard(this.adherenceNumber, "TMS");
      this.createAdherenceScoreCard(this.adherenceNumber, "CMS");
      this.createAdherenceScoreCard(this.adherenceNumber, "QRVerify");
      this.createAdherenceScoreCard(this.adherenceNumber, "JobCardVerify");
    
    //this.commonMethods.commonSort(this.scoreCardList, this.scoreCardList,this.reverse,null,'NPS',true); 
    //this.sortText="NPS (Ascending)"; 
  }

  getLeaderboardNumberByCategory(data:any,dataType:string){
    console.log("cateory"+this.selectedCategory);    
   
    if (this.selectedCategory == "OM") {
      if(dataType=="Complaints"){
        return data.omList.sort((a, b) => a.yAxis - b.yAxis);
      }
     return data.omList.sort((a, b) => b.yAxis - a.yAxis);
    }
    
    if (this.selectedCategory == "ROM") {
      if(dataType=="Complaints"){
        return data.romList.sort((a, b) => a.yAxis - b.yAxis);
      }
      return data.romList.sort((a, b) => b.yAxis - a.yAxis);
    }
    if (this.selectedCategory == "SC") {
      if(dataType=="Complaints"){
        return data.scList.sort((a, b) => a.yAxis - b.yAxis);
      }
      return data.scList.sort((a, b) => b.yAxis - a.yAxis);
    }
    if (this.selectedCategory == "Technician") {
      if(dataType=="Complaints"){
        return data.technicianList.sort((a, b) => a.yAxis - b.yAxis);
      }
      return (data.technicianList.sort((a, b) => b.yAxis - a.yAxis));//.slice(0, 20);
    }
    if (this.selectedCategory == "Region") {
      if(dataType=="Complaints"){
        return data.regionList.sort((a, b) => a.yAxis - b.yAxis);
      }
      return data.regionList.sort((a, b) => b.yAxis - a.yAxis);
    }
  }

  setActiveIndex(evt: any) {
    console.log("set index");
    this.activeIdString = evt.nextId;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(parent.parentElement.classList);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

     var sortResponse= this.commonMethods.commonSort(this.scoreCardList,this.scoreCardList,this.reverse,event, column,isASc,dataTyp);
     this.sortText=sortResponse.sortText; 
     this.reverse=sortResponse.isReverse
     //this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  createScoreCard(data: any, typeOfScoreCard: string) {

    for (var i = 0; i < data.length; i++) {
      if (this.scoreCardList == undefined || this.scoreCardList == null || this.scoreCardList.length == 0) {
        this.scoreCardList.push({ 'ResourceName': data[i].xAxis, 'SD': 0, 'OTIF': 0, 'Productivity': 0, 'NPS': 0, 'Complaints': 0 });
      }
      else {
        if (this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis) != null && this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis) != undefined && this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis).length > 0) { }
        else {
          this.scoreCardList.push({ 'ResourceName': data[i].xAxis, 'SD': 0, 'OTIF': 0, 'Productivity': 0, 'NPS': 0, 'Complaints': 0 });
        }

      }
      if (typeOfScoreCard == "NPS") {
        this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis)[0].NPS = data[i].yAxis;
      }
      if (typeOfScoreCard == "SD") {
        this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis)[0].SD = data[i].yAxis;
      }
      if (typeOfScoreCard == "OTIF") {
        this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis)[0].OTIF = data[i].yAxis;
      }
      if (typeOfScoreCard == "Productivity") {
        this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis)[0].Productivity = data[i].yAxis;
      }
      if (typeOfScoreCard == "Complaints") {
        this.scoreCardList.filter(item => item.ResourceName == data[i].xAxis)[0].Complaints = data[i].yAxis;
      }
    }

  }

  createAdherenceScoreCard(statisticsData:any,typeOfScoreCard:string){
   var data=this.getLeaderboardNumberForAdherence(statisticsData,typeOfScoreCard);

    for (var i = 0; i < data.length; i++) {
      if (this.adherenceScoreList == undefined || this.adherenceScoreList == null || this.adherenceScoreList.length == 0) {
        this.adherenceScoreList.push({ 'ResourceName': data[i].xAxis, 'B2B': 0, 'TMS': 0, 'CMS': 0, 'QRVerify': 0,'JobCardVerify':0});
      }
      else {
        if (this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis) != null && 
        this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis) != undefined &&
         this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis).length > 0) { }
        else {
          this.adherenceScoreList.push({ 'ResourceName': data[i].xAxis, 'B2B': 0, 'TMS': 0, 'CMS': 0, 'QRVerify': 0 ,'JobCardVerify':0});
        }

      }
      if (typeOfScoreCard == "B2B") {
        this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis)[0].B2B = data[i].yAxis;
      }
      if (typeOfScoreCard == "TMS") {
        this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis)[0].TMS = data[i].yAxis;
      }
      if (typeOfScoreCard == "CMS") {
        this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis)[0].CMS = data[i].yAxis;
      }
      if (typeOfScoreCard == "QRVerify") {
        this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis)[0].QRVerify = data[i].yAxis;
      }
      if (typeOfScoreCard == "JobCardVerify") {
        this.adherenceScoreList.filter(item => item.ResourceName == data[i].xAxis)[0].JobCardVerify = data[i].yAxis;
      }
     
    }
  }
}
