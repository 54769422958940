import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import {SummaryCardComponent} from '../summary-card/summary-card.component';


@Component({
  selector: 'app-short-closure',
  templateUrl: './short-closure.component.html',
  styleUrls: ['./short-closure.component.scss']
})
export class ShortClosureComponent implements OnInit {
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  category: string = "";
  period: string = "";
  exportData:boolean=true;
  isList:boolean=true;
  totCount:number;

  items = [];
  pageOfItems: Array<any>;

  amtIn: number = 100000;
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  searchText: string = "";
  chartLabel: string = "SV Value";
  activeIdString: any = "1";
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService,private commonMethods:CommonMethods) {
    this.category = this.activatedRoute.snapshot.params.category;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;

    console.log("short closure");
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit(){
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");    

    this.getShortClosureList();
  }  

  getShortClosureList(){

    this.isList=false;

    this.customHttpService.getDataWithAuthentication("/Delivery/GetGetShortClosureList?userId=" + this.loggedinUserId +
    "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
    "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
    .subscribe(data => {  
      console.log(data.responseData)
      this.items = data.responseData;
      this.allPageOfItems = data.responseData;
      this.isList=true;

      this.totCount= this.items.reduce(function (prev, cur) {
        return prev + cur.svValue;
      }, 0);   

      this.groupByRegion(data.responseData);
      this.groupByROM(data.responseData);
      this.groupByOM(data.responseData);
      this.groupBySC(data.responseData);

      this.isList=true;
    });

  }

  groupByRegion(array) {

    let regionWiseChartData:ChartModel; 
    regionWiseChartData = this.commonMethods.groupByRegion(array,this.chartLabel);
    this.barChartData = regionWiseChartData.chartData;
    this.mbarChartLabels = regionWiseChartData.chartlabels;
    this.regionWiseList = regionWiseChartData.list;

  }

  groupByROM(array) {
    let romWiseChartData:ChartModel; 
    romWiseChartData = this.commonMethods.groupByROM(array,this.chartLabel);
    this.romBarChartData = romWiseChartData.chartData;
    this.romMbarChartLabels = romWiseChartData.chartlabels;
    this.romWiseList = romWiseChartData.list;
  }

  groupByOM(array) {

    let omWiseChartData:ChartModel; 
    omWiseChartData = this.commonMethods.groupByOM(array,this.chartLabel);
    this.hbarChartData = omWiseChartData.chartData;
    this.hbarChartLabels = omWiseChartData.chartlabels;
    this.omWiseList = omWiseChartData.list;
  }

  groupBySC(array) {
    let scWiseChartData:ChartModel; 
    scWiseChartData = this.commonMethods.groupBySC(array,this.chartLabel);
    this.hBarChartSCData = scWiseChartData.chartData;
    this.hBarChartSCLabels = scWiseChartData.chartlabels;
    this.scWiseList = scWiseChartData.list;
  }

  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
  }

  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length>0 || this.isList)&& this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }

  search(value: string, searchBy: string): void {
    this.isList=false;
    if (searchBy == '') {
      if (value.length >= 3) {

        this.pageOfItems = this.allPageOfItems.filter((val) => val.planDateDisplayValue.toLowerCase().includes(value.toLowerCase()) ||
          (val.srName != null && val.srName.toLowerCase().includes(value.toLowerCase())) ||
          (val.orderNno != null && val.orderNno.toLowerCase().includes(value.toLowerCase())) ||
          (val.scName != null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
          (val.rom != null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
          (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
          (val.regionName != null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountNo != null && val.accountNo.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountName != null && val.accountName.toLowerCase().toString().includes(value.toLowerCase())) ||
          (val.accountType != null && val.accountType.toLowerCase().includes(value.toLowerCase())) ||
          (val.status != null && val.status.toString().toLowerCase().includes(value.toLowerCase())) ||
          (val.orderStatus != null && val.orderStatus.toString().toLowerCase().includes(value.toLowerCase())) ||
          (val.svValue != null && val.svValue.toString().includes(value)));
      }
      else {
        this.pageOfItems = this.allPageOfItems;
      }
    }
    else {

      this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
           
    }
    this.items = this.pageOfItems;
    this.isList=true;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    //New change

    if (dataTyp != "date") {
      this.items.sort(function (a, b) { return sort(a[column], b[column], isASc) });
      this.reverse = !this.reverse;
    }
    else {
      if (isASc == true) {
        this.pageOfItems = this.items.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)

      }
      else {
        this.pageOfItems = this.items.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)

      }
      this.reverse = !this.reverse
      this.items = this.pageOfItems;
    }

    //END

    this.onChangePage(this.items.slice(0, 20));
  }


    exportToExcel() {
      this.exportData=false;
      this.customHttpService.exportDataWithAuthentication("/Delivery/ExportShortClosureData?userId=" + this.loggedinUserId +
        "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
        "&category=" + this.category + "&type=" + this.type + "&period=" + this.period)
        .subscribe(data => {
          this.exportData=true;
          let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
          var url = window.URL.createObjectURL(b);
          //window.open(url);
  
          var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
          var anchor = document.createElement("a");
          anchor.download = "ShortClosure_"+this.type+"("+ this.period+")_"+nowDate+".xls";
          anchor.href = url;
          anchor.click();
  
        });
  
    }
    public barChartOptions: any = {
      scaleShowVerticalLines: false,
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName")
    };
  
    graphHover(e, array) {
      if(array.length > 0) {
          e.target.style.cursor = 'pointer';
      }else {
          e.target.style.cursor = '';
      }
  }
    public romBarChartOptions: any = {
      scaleShowVerticalLines: false,
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
    };
  
    onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
      const chart = activeElems[0]._chart;
      const activePoints = chart.getElementAtEvent(clickEvt);
      console.log(activePoints);
  
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const clickedPeridIndex = activePoints[0]._datasetIndex;
        console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);
  
        this.search(activePoints[0]._model.label, searchBy);
        this.searchText = activePoints[0]._model.label;
        this.activeIdString = "3";
  
      }
    }
  
    setActiveIndex(evt: any) {
      console.log("set index");
      this.activeIdString = evt.nextId;
    }
  
    public barChartType: string = 'bar';
    public barChartLegend: boolean = false;
  
    public regionBarChartColors: Array<any> = [
      {
        backgroundColor: 'rgba(105,159,177,0.2)',
        borderColor: 'rgba(105,159,177,1)',
        pointBackgroundColor: 'rgba(105,159,177,1)',
        pointBorderColor: '#fafafa',
        pointHoverBackgroundColor: '#fafafa',
        pointHoverBorderColor: 'rgba(105,159,177)'
      }
    ];
  
    public romBarChartColors: Array<any> = [
      {
        backgroundColor: 'rgba(77,20,96,0.3)',
        borderColor: 'rgba(77,20,96,1)',
        pointBackgroundColor: 'rgba(77,20,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,20,96,1)'
      }
    ];
  
    // events
    public chartClicked(e: any): void {
      console.log(e);
    }
  
    public chartHovered(e: any): void {
      console.log(e);
    }  
  
    public hbarChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "om")
    };
    public scHBarChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "scName")
    };
  
    onHBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
      const chart = activeElems[0]._chart;
      const activePoints = chart.getElementAtEvent(clickEvt);
      console.log(activePoints);
  
      if (activePoints.length > 0) {
        const clickedElementIndex = activePoints[0]._index;
        const clickedPeridIndex = activePoints[0]._datasetIndex;
        console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);
  
        this.activeIdString = "2";
        this.search(activePoints[0]._model.label, searchBy);
        this.searchText = activePoints[0]._model.label;
      }
    }
    public hbarChartType: ChartType = 'horizontalBar';
    public hbarChartLegend = true;
  
    public hbarChartData: ChartDataSets[] = [];
    public hBarChartSCData: ChartDataSets[] = [];
  
  }
  
  function sort(a, b, asc) {
    var result;
  
    /* Default ascending order */
    if (typeof asc == "undefined") asc = true;
  
    //console.log("sort " + a + " " + b);
    if (asc) {
      if (a == null) return -1;
      if (b == null) return 1;
    }
    else {
      if (b == null) return -1;
      if (a == null) return 1;
    }
    if (a === null && b === null) return 0;
  
    result = a - b;
  
    if (isNaN(result)) {
      return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
    }
    else {
      return (asc) ? result : -result;
    }
  }



