import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { visitAll } from '@angular/compiler';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import {SummaryCardComponent} from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';

@Component({
  selector: 'app-obd-nps',
  templateUrl: './obd-nps.component.html',
  styleUrls: ['./obd-nps.component.scss']
})
export class ObdNpsComponent implements OnInit {

  category:string="";
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems:Array<any>;
  reverse: boolean = false;
  items = [];
  pageOfItems: Array<any>;

  regionwiseNumber: any = [];
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  searchText:string="";
  chartLabel:string="NPS %";
  activeIdString: any = "1";
  isList:boolean=true;
  exportData:boolean=true;
  serviceType:string;
  prd:string;
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  barChartResourceData:any=[];
  barChartResourceLabels: any = [];
  resourceWiseList=[];

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService,private commonMethods:CommonMethods) {
    this.prd = this.activatedRoute.snapshot.params.prd;
    this.category=this.activatedRoute.snapshot.params.category;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }


  ngOnInit() {
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");  
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");

    this.getOBDNPSList();
  }

  getOBDNPSList(){this.isList=false;
    this.customHttpService.getDataWithAuthentication("/Task/GetOMNPSOBDList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&period=" + this.prd+"&serviceType="+this.category)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems=data.responseData;
        this.isList=true; 

      });

      this.customHttpService.getDataWithAuthentication("/Task/GetNPSOBDSummaryModel?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&period=" + this.prd+"&serviceType="+this.category)
      .subscribe(data => {       

        //new changes
          this.groupByRegion(data.responseData.regionList);
          this.groupByROM(data.responseData.romList);
          this.groupByOM(data.responseData.omList);
          this.groupBySC(data.responseData.scList);
          this.groupByResource(data.responseData.technicianList);

      });
  }
 

  groupByRegion(array) {

    let regionWiseChartData:ChartModel; 
    regionWiseChartData = this.commonMethods.groupByRegionPercent(array,this.chartLabel);
    this.barChartData = regionWiseChartData.chartData;
    this.mbarChartLabels = regionWiseChartData.chartlabels;
    this.regionWiseList = regionWiseChartData.list;

  }

  groupByROM(array) {
    let romWiseChartData:ChartModel; 
    romWiseChartData = this.commonMethods.groupByROMPercent(array,this.chartLabel);
    this.romBarChartData = romWiseChartData.chartData;
    this.romMbarChartLabels = romWiseChartData.chartlabels;
    this.romWiseList = romWiseChartData.list;
  }

  groupByOM(array) {

    let omWiseChartData:ChartModel; 
    omWiseChartData = this.commonMethods.groupByOMPercent(array,this.chartLabel);
    this.hbarChartData = omWiseChartData.chartData;
    this.hbarChartLabels = omWiseChartData.chartlabels;
    this.omWiseList = omWiseChartData.list;
  }

  groupBySC(array) {
    let scWiseChartData:ChartModel; 
    scWiseChartData = this.commonMethods.groupBySCPercent(array,this.chartLabel);
    this.hBarChartSCData = scWiseChartData.chartData;
    this.hBarChartSCLabels = scWiseChartData.chartlabels;
    this.scWiseList = scWiseChartData.list;
  }
  groupByResource(array) {
    let resourceWiseChartData:ChartModel; 
    resourceWiseChartData = this.commonMethods.groupByResourcePercent(array,this.chartLabel);
    this.barChartResourceData = resourceWiseChartData.chartData;
    this.barChartResourceLabels = resourceWiseChartData.chartlabels;
    this.resourceWiseList = resourceWiseChartData.list;
  }

  checkLoading(){
    if(this.items!=undefined && this.items!=null&& (this.items.length>0 || this.isList)&& this.exportData)
    {return true;
    }
    else
    {
      return false;
    }
    }

    search(value:string , searchBy: string):void{
      this.isList=false;
      if (searchBy == '') {
     if(value.length>=3){       
       this.pageOfItems= this.allPageOfItems.filter((val)=> //val.createdDateDisplayValue.toLowerCase().includes(value.toLowerCase())|| 
       (val.regionName !=null && val.regionName.toLowerCase().includes(value.toLowerCase()))||
       (val.rom !=null && val.rom.toLowerCase().includes(value.toLowerCase()))||
       (val.om !=null && val.om.toLowerCase().includes(value.toLowerCase()))||
       (val.scName !=null && val.scName.toLowerCase().includes(value.toLowerCase()))||
       (val.accountName !=null && val.accountName.toLowerCase().includes(value.toLowerCase()))||
       (val.orderNumber !=null && val.orderNumber.toLowerCase().includes(value.toLowerCase()))||
       (val.orderValue!=null && val.orderValue.toString().includes(value))||
       (val.jeopardyStatus!=null && val.jeopardyStatus.toLowerCase().includes(value.toLowerCase()))||
       (val.obdRating!=null&& val.obdRating.toString().includes(value.toLowerCase()))||
       (val.obdRemark!=null&& val.obdRemark.toLowerCase().includes(value.toLowerCase()))||
       (val.status!=null&& val.status.toLowerCase().includes(value.toLowerCase()))||
       (val.resourceName!=null&& val.resourceName.toLowerCase().includes(value.toLowerCase()))||
       (val.servicePlan!=null&& val.servicePlan.toLowerCase().includes(value.toLowerCase())));    
     }
     else{
       this.pageOfItems= this.allPageOfItems;
     }
    }
    else{
      this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
    }
     this.items=this.pageOfItems;
     this.isList=true;
    } 
  
    sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
      //sortCol(column: string): void {
      console.log("sort" + isASc);
  
      var target = event.target;
      var parent = target.parentElement;
      console.log(target);
      console.log(target.parentElement);
      parent.parentElement.classList.remove("selectBackColor");
      parent.parentElement.childNodes.forEach(function (item) {
        item.classList.remove("selectBackColor");
      });
  
      parent.classList.add("selectBackColor");
  
       //New change
  
       if (dataTyp != "date") {
       this.items.sort(function (a, b) { return sort(a[column], b[column], isASc) });
       this.reverse = !this.reverse;
       }
       else
       {
        if (isASc == true) {
          this.pageOfItems = this.items.sort((a, b) => new Date(a[column]) < new Date(b[column]) ? 1 : new Date(a[column]) > new Date(b[column]) ? -1 : 0)
         
        }
        else{
          this.pageOfItems = this.items.sort((a, b) => new Date(a[column]) > new Date(b[column]) ? 1 : new Date(a[column]) < new Date(b[column]) ? -1 : 0)
         
        }
        this.reverse = !this.reverse
        this.items = this.pageOfItems;
       }
   
       //END
  
    
      this.onChangePage(this.items.slice(0, 20));
    }

    fetchTab(evt: any) {
      console.log(evt); // has nextId that you can check to invoke the desired function
    }

    exportToExcel() {
      this.exportData=false;
      this.customHttpService.exportDataWithAuthentication("/Task/ExportNPSOBDList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&period=" + this.prd+"&serviceType="+this.category)
        .subscribe(data => {
          this.exportData=true;
          let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
          var url = window.URL.createObjectURL(b);
          //window.open(url);

          var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
          var anchor = document.createElement("a");
          anchor.download = "NPS_15Days_"+ this.category+"_"+nowDate+".xls";
          anchor.href = url;
          anchor.click();
  
        });
  
    }

    public barChartOptions: any = {
      scaleShowVerticalLines: false,
      responsive: true,
      scales:{yAxes: [{ ticks: {
        beginAtZero: true
    },    
  }]
},
onHover:this.graphHover,
onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName") 
    };
  
    graphHover(e, array) {
      if(array.length > 0) {
          e.target.style.cursor = 'pointer';
      }else {
          e.target.style.cursor = '';
      }
  }
  public romBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
  };

  onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

      this.activeIdString = "3";
      this.search(activePoints[0]._model.label, searchBy);
      this.searchText = activePoints[0]._model.label;
    }
  }

  public resourceBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "resourceName")
  };

  setActiveIndex(evt: any) {
    console.log("set index");
    this.activeIdString = evt.nextId;
  }

    public barChartType: string = 'bar';
    public barChartLegend: boolean = false;
  
    public regionBarChartColors: Array<any> = [
      {
        backgroundColor: 'rgba(3,189,59,0.2)',
        borderColor: 'rgba(3,189,59,1)',
        pointBackgroundColor: 'rgba(105,159,177,1)',
        pointBorderColor: '#fafafa',
        pointHoverBackgroundColor: '#fafafa',
        pointHoverBorderColor: 'rgba(105,159,177)'
      }
    ];
  
    public romBarChartColors: Array<any> = [
      {
        backgroundColor: 'rgba(77,20,96,0.3)',
        borderColor: 'rgba(77,20,96,1)',
        pointBackgroundColor: 'rgba(77,20,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,20,96,1)'
      }
    ];
  
    // events
    public chartClicked(e: any): void {
      console.log(e);
    }
  
    public chartHovered(e: any): void {
      console.log(e);
    }
  
  
    public hbarChartOptions: ChartOptions = {
      responsive: true,
      scales:{xAxes: [{ ticks: {
        beginAtZero: true
    }
  }]
} ,
onHover:this.graphHover,
onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "om")
    };

    public scHBarChartOptions: ChartOptions = {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      },
      onHover:this.graphHover,
      onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "scName")
    };

    public hbarChartType: ChartType = 'horizontalBar';
    public hbarChartLegend = true;
  
    public hbarChartData: ChartDataSets[] = [];
    public hBarChartSCData: ChartDataSets[] = [];
  
  }
  
  function sort(a, b, asc) {
    var result;
  
    /* Default ascending order */
    if (typeof asc == "undefined") asc = true;
  
    //console.log("sort " + a + " " + b);
    if (asc) {
      if (a == null) return -1;
      if (b == null) return 1;
    }
    else {
      if (b == null) return -1;
      if (a == null) return 1;
    }
    if (a === null && b === null) return 0;
  
    result = a - b;
  
    if (isNaN(result)) {
      return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
    }
    else {
      return (asc) ? result : -result;
    }
  }
  
  
  
 
