import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ComplaintComponent } from './data-tables/complaint/complaint.component';
import { InvoicesComponent } from './data-tables/invoices/invoices.component';
import { RedaccountComponent } from './data-tables/redaccount/redaccount.component';
import { SrbaseComponent } from './data-tables/srbase/srbase.component';
import { LoginComponent } from './login/login.component';
import{ AttendanceComponent} from './data-tables/attendance/attendance.component';
import{KarmaComponent} from './data-tables/karma/karma.component';
import { NpsComponent } from './data-tables/nps/nps.component';
import { DeliveryComponent } from './data-tables/delivery/delivery.component';
import { ServiceDeliveryComponent } from './data-tables/service-delivery/service-delivery.component';
import{ChemicalComsumptionComponent}from './data-tables/chemical-comsumption/chemical-comsumption.component';
import { DeliveryPercentComponent } from './data-tables/delivery-percent/delivery-percent.component';
import { MissDeliveryComponent } from './data-tables/miss-delivery/miss-delivery.component';
import { JeopardyComponent } from './data-tables/jeopardy/jeopardy.component';
import { ShortClosureComponent } from './data-tables/short-closure/short-closure.component';
import { TaskDeliveryComponent } from './data-tables/task-delivery/task-delivery.component';
import { ObdNpsComponent } from './data-tables/obd-nps/obd-nps.component';
import { HeProductivityComponent } from './data-tables/he-productivity/he-productivity.component';
import { OrderComponent } from './data-tables/order/order.component';
import { LeaderBoardComponent } from './leader-board/leader-board.component';
import { B2bOtifComponent } from './data-tables/b2b-otif/b2b-otif.component';
//import { PulseReportComponent } from './pulse-report/pulse-report.component';
import { OmromMappingComponent } from './data-tables/omrom-mapping/omrom-mapping.component';
import { TicketsComponent } from './data-tables/tickets/tickets.component';
import { TicketsDetailComponent } from './data-tables/tickets-detail/tickets-detail.component';
import { TicketsInteractionDetailComponent } from './data-tables/tickets-interaction-detail/tickets-interaction-detail.component';
import { ControlroomAdherenceComponent } from './data-tables/controlroom-adherence/controlroom-adherence.component';
import {GeoTaggerComponent} from './data-tables/geotagger/geotagger.component';
import {GeoTaggerDetailComponent} from './data-tables/geotagger-detail/geotagger-detail.component';
import { UploadFileComponent } from './data-tables/upload-file/upload-file.component';
import { DocumentMappingComponent } from './data-tables/document-mapping/document-mapping.component';
import { DocumentUploaderComponent } from './data-tables/document-uploader/document-uploader.component';
import { S3loginComponent } from './s3login/s3login.component';
import { GoogleAuthComponent } from './googleAuthLogin/google-auth/google-auth.component';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }, //for one login
  // { path: 'login', redirectTo: 'dashboard' }, //for one login
  // { path: '', redirectTo: 'login', pathMatch: 'full' },//for local login
  { path: 'login', component: LoginComponent },//for local login
  { path: 'dashboard', component: DashboardComponent, pathMatch:'full' },
  { path: 'complaint/:category/:type/:period', component: ComplaintComponent, pathMatch:'full' },
  { path: 'SVBase/:period/:status', component: SrbaseComponent, pathMatch:'full' },
  { path: 'RedAccount/:period/:category', component: RedaccountComponent, pathMatch:'full' },
  { path: 'Invoices/:period/:category', component: InvoicesComponent, pathMatch:'full' },
  {path:'Attendance/:type/:category',component:AttendanceComponent,pathMatch:'full'},
  {path:'Karma/:lifeIndex',component:KarmaComponent,pathMatch:'full'},
  {path:'Nps/:category/:type',component:NpsComponent,pathMatch:'full'},
  {path:'Delivery/:srStatus/:type/:period',component:DeliveryComponent,pathMatch:'full'},
  {path:'service_delivery/:category/:type/:period',component:ServiceDeliveryComponent,pathMatch:'full'},  
  {path:'chemical_comsumption/:category/:period',component:ChemicalComsumptionComponent,pathMatch:'full'},
  {path:'Delivery_percent/:srStatus/:type/:period',component:DeliveryPercentComponent,pathMatch:'full'},
  {path:'jeopardy/:category/:type/:period',component:JeopardyComponent,pathMatch:'full'},
  {path:'short_closure/:category/:type/:period',component:ShortClosureComponent,pathMatch:'full'},
  {path:'task_delivery/:category/:type/:period',component:TaskDeliveryComponent,pathMatch:'full'},
  {path:'miss_delivery/:category/:type/:period',component:MissDeliveryComponent,pathMatch:'full'},
  {path:'obd_Nps/:prd/:category',component:ObdNpsComponent,pathMatch:'full'},
  {path:'he_produtivity/:category/:type/:period',component:HeProductivityComponent,pathMatch:'full'},
  {path:'order/:category/:type',component:OrderComponent,pathMatch:'full'},
  {path:'leaderboard',component:LeaderBoardComponent,pathMatch:'full'},
  {path:'b2b_otif/:category/:type/:period',component:B2bOtifComponent,pathMatch:'full'},
  //{path:'pulseReport',component:PulseReportComponent,pathMatch:'full'},
  {path:'omrom_mapping',component:OmromMappingComponent,pathMatch:'full'},
  {path:'tickets',component:TicketsComponent,pathMatch:'full'},
  {path:'tickets_detail/:accountId',component:TicketsDetailComponent,pathMatch:'full'},
  {path:'tickets_interaction_detail/:ticketId',component:TicketsInteractionDetailComponent,pathMatch:'full'},
  {path:'controlroom_adherence/:category/:period/:status',component:ControlroomAdherenceComponent,pathMatch:'full'},
  {path:'geotagger',component:GeoTaggerComponent,pathMatch:'full'},
  {path:'geotagger/detail/:id',component:GeoTaggerDetailComponent,pathMatch:'full'},
  {path:'uploadfile',component:UploadFileComponent,pathMatch:'full'},
  {path:'document_mapping',component:DocumentMappingComponent,pathMatch:'full'},
  {path: 'documentuploader', component:DocumentUploaderComponent, pathMatch:'full'},
  {path:'s3login/:id',component:S3loginComponent,pathMatch:'full'},
  {path: 's3login', component:S3loginComponent, pathMatch: 'full'},
  //{ path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  {path: 'google-auth', component:GoogleAuthComponent, pathMatch: 'full'}  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
