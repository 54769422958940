import { Component, NgModule, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { CommonMethods } from "src/app/services/commonmethods";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { CustomhttpService } from "src/app/services/customhttp.service";
import { FormsModule } from "@angular/forms";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: "app-geotagger-detail",
  templateUrl: "./geotagger-detail.component.html",
  styleUrls: ["./geotagger-detail.component.scss"]
})
@NgModule({
  imports: [FormsModule]
})
export class GeoTaggerDetailComponent implements OnInit {
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  id: number;
  num: number = 1;
  name = "Angular";
  pointSize = 5;
  ctx: CanvasRenderingContext2D;
  canvas;
  table;
  myCanvas;
  imageSrc: string;
  imageDetailList = [];
  coordinateList = [];
  categoryList = [];
  category: string;
  imageDetail: any;
  file: any;
  imageTag: string;

  acceptableCriteria: number;
  isacceptableCriteriaDisabled: boolean;
  isImageTagDisabled: boolean;
  isDisabled: boolean;
  imageFile: any;
  responseModel: any;
  isBrowseButtonToBeHidden = false;
  canvasIsHidden = true;
  newImage: any;
  qrCodeId: string;
  coOrdId: number;
  isQRTextBoxDisabled: boolean;
  qrcode = [];
  isSaved: boolean = false;
  listOfNonUniqueQr = [];
  openModal: false;
  closeResult = '';
  deleteitem: any;
  customerResponseModel: any;
  customerId: string;
  customerName: string;
  createdBy: string;
  city: any;
  state: any;
  testName: string;
  newImageSrc: string;
  listOfNonUniqueBoxno = [];
  boxno: number;
  isEnlargeButtonHidden = true;
  getImageId: number;
  isCustomerSearchDisabled: boolean;
  mySaveModal = document.getElementById("mySaveModal");
  status: boolean;
  sortText: string = "";
  listItem: any[];

  imgWidth: number;
  imgHeight: number;
  imageType: string = '';
  onCoordItem: any ={};
  boxNoCheck: any;

  @ViewChild('savemodal', { static: false }) saveModal: TemplateRef<any>;
  @ViewChild('onpointsavemodal', { static: false }) onPointSaveModel: TemplateRef<any>;

  constructor(
    private customHttpService: CustomhttpService,
    private commonMethods: CommonMethods,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.id = this.activatedRoute.snapshot.params.id;
  }

  ngOnInit() {
    debugger;
    this.getImageDetail(this.id);
    this.getGeoTaggerCategoryList();
  }
  getGeoTaggerCategoryList() {
    debugger;
    this.customHttpService
      .getDataWithAuthentication("/GeoTagger/GetCategoryList")
      .subscribe((data) => {
        debugger;
        if (data.isSuccess) {
          this.categoryList = data.responseData;
          console.log('CategoryList', this.categoryList);
        }
      });
  }

  checkSaveButtonDisabled() {

    var checkInputFields = [];
    checkInputFields = this.coordinateList.filter(
      (x) => x.categoryId == undefined || x.boxno == "" || x.boxno == null || x.isBoxnoDisabled == false || x.isDisabled == false
    );
    if (
      this.imageTag != undefined &&
      this.imageTag != "" &&
      this.imageTag != null &&
      this.customerId != undefined &&
      this.customerId != "" &&
      this.customerId != null &&
      this.acceptableCriteria != undefined &&
      this.acceptableCriteria != null &&
      this.coordinateList.length > 0 &&
      checkInputFields.length == 0
    ) {
      return false;
    }
    return true;
  }

  saveAllImageDetails() {
    debugger;
    this.status = false;
    //to check unique box no while creating new Map (not works for points which are saved in DB)
    this.status = this.coordinateList.some(coord => {
      let counter = 0;
      for (const i of this.coordinateList) {
        if (i.boxno === coord.boxno) {
          counter += 1;
        }
      }
      return counter > 1;
    });
    
    //if box no is not unique it show alert
    if (this.status == true) {
      this.openSaveModal();
    }
    else {
      const formData = new FormData();
      if (this.id > 0) {
        formData.append("id", this.id.toString());
      }
      debugger;
      if (this.imgWidth > this.imgHeight) {
        this.imageType = 'Horizontal';
      } else if (this.imgHeight > this.imgWidth) {
        this.imageType = 'Vertical';
      }
      else {
        this.imageType = 'Square';
      }

      formData.append("file", this.imageFile);
      formData.append("coordinateList", JSON.stringify(this.coordinateList));
      formData.append("imageTag", this.imageTag);
      formData.append("customerId", this.customerId);
      formData.append("customerName", this.customerName);


      formData.append("acceptableCriteria", this.acceptableCriteria.toString());
      //formData.append("UserId", this.loggedinUserId);
      formData.append("createdBy", this.loggedinUserId);
      formData.append("imageType", this.imageType);

      this.customHttpService
        .postData("/GeoTagger/UploadImageDetails", formData)
        .subscribe((data) => {

          console.log(data);
          this.isSaved = true;
          var response = data.responseData.filter(x => x.id == 0 || x.boxNoIsActive == false);
          this.listOfNonUniqueBoxno = [];
          for (let item of response) {
            this.boxno = item.boxno;

            //below condition is for object which has only "Image id" in it.
            if (item.categoryId > 0) {
              this.listOfNonUniqueBoxno.push(this.boxno);
            }
          }
          //console.log(this.listOfNonUniqueBoxno);

          //get image id of current image saved in DB
          var getImageIdArray = data.responseData.filter(x => x.boxno == null && x.imageId > 0);
          this.getImageId = getImageIdArray.imageId;
          for (let i of getImageIdArray) {
            this.getImageId = i.imageId;
          }
          if (this.listOfNonUniqueBoxno.length > 0) {
            this.openSaveModal();
          } else {
            this.openSaveModal();
          }
        });
    }
  }

  getImageDetail(id: number) {
    debugger;
    if (this.id > 0) {
      this.canvasIsHidden = false;
      this.isBrowseButtonToBeHidden = true;
      this.isImageTagDisabled = true;
      this.isEnlargeButtonHidden = false;
      this.isCustomerSearchDisabled = true;
      this.isacceptableCriteriaDisabled = false;

      //API to get image detail by id
      this.customHttpService
        .getDataWithAuthentication(
          "/GeoTagger/GetImageDetailById?imageId=" + id
        )
        .subscribe((data) => {
          if (data.isSuccess) {
            debugger;
            this.imageDetail = data.responseData.imageDetail;
            this.coordinateList = data.responseData.coordinateList;
            this.imageTag = this.imageDetail.imageName;
            this.imageSrc = this.imageDetail.imageFileUrl;
            this.customerId = this.imageDetail.customerId;

            this.acceptableCriteria = this.imageDetail.acceptableCriteria;
            //console.log(this.coordinateList);

            console.log(this.coordinateList);
            this.coordinateList = this.coordinateList.sort(function (a, b) { return (parseInt(a.boxno) - parseInt(b.boxno)) });


            this.loadCanvas();
          }
        });
    }
  }

  open(deletemodal, item) {
    debugger;
    this.deleteitem = item;
    this.loggedinUserId;
    this.modalService.open(deletemodal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openCustomerModal(customermodal) {

    this.modalService.open(customermodal, { ariaLabelledBy: 'modal-basic-title',windowClass:'customer-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openSaveModal() {

    this.modalService.open(this.saveModal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deleteStation() {
    
    this.deleteCoordinate(this.deleteitem,this.loggedinUserId);

    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
       } 
    else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onOkClick() {

    if (this.listOfNonUniqueBoxno.length > 0) {
      this.getImageDetail(this.id);
      //this.show = false;
      this.modalService.dismissAll();
      this.listOfNonUniqueBoxno = [];
      if (this.id == 0) {
        window.location.href = "geotagger/detail/" + this.getImageId;
      }
    }
    else if (this.status == true) {
      this.modalService.dismissAll();
    }
    else {
      window.location.href = "geotagger/";
    }
    this.status = false;
  }

  loadCanvas() {
    debugger;
    this.canvas = <HTMLCanvasElement>document.getElementById("canvas");
    this.table = <HTMLCanvasElement>document.getElementById("table");
    this.ctx = this.canvas.getContext("2d");
    this.sortText = "Box No (Ascending)";

    let context = this.ctx;
    var img = new Image();
    let canvas = this.canvas;

    var loadCoordinate = this.coordinateList;
    var contx = this.ctx;

    var point = this.pointSize;
    if (this.id > 0) {
      var fileUrl = this.imageSrc;
      img.src = fileUrl;
    } else {
      img.src = this.newImage;
    }
    img.onload = () => {

      // var width = 950;
      // var height = 750;

      var width = img.width;
      var height = img.height;

      this.imgWidth = img.width;
      this.imgHeight = img.height;
      console.log('imgWidth, imgHeight', this.imgWidth, this.imgHeight);


      if (width > height) {
        width = 1230;
        height = img.height * (width / img.width);
      } else if (height > width) {
        width = 1000;
        height = img.height * (width / img.width);
      } else {
        var width = 1200;
        var height = 1200;
      }
      canvas.width = width;
      canvas.height = height;
      context.drawImage(img, 0, 0, width, height);

      if (loadCoordinate != null) {
        for (var i = 0; i < loadCoordinate.length; i++) {
          // console.log(coords);
          if (loadCoordinate[i].id > 0) {
            //for data received from DB

            loadCoordinate[i].isDisabled = true;
            //to check box no is unique or not and display popup accordingly
            if (loadCoordinate[i].boxno == "" || loadCoordinate[i].boxno == null || loadCoordinate[i].boxno == undefined) {
              loadCoordinate[i].isBoxnoDisabled = false;


              // {
              //   listItem.sort(function(a,b){return isASc?(parseInt(a[boxno])-parseInt(b[boxno])):(parseInt(b[boxno])-parseInt(a[boxno])) });
              //   isReverse = !isReverse;}
              // else
              // {
              //       //listItem.sort(function (a, b) { return sort(a[boxno], b[boxno], isASc) });
              //       isReverse = !isReverse;
              // }           
             }
            else {
              loadCoordinate[i].isBoxnoDisabled = true;
            }

          } else if (
            loadCoordinate[i].id == 0 &&
            loadCoordinate[i].isDisabled == false
          ) {
            // while creating new data
            loadCoordinate[i].isDisabled = false;
          }
          const grd = contx.createLinearGradient(0, 0, 170, 0);
          contx.beginPath();

          contx.arc(loadCoordinate[i].coordX, loadCoordinate[i].coordY, point, 0, Math.PI * 2, true);
          contx.fillStyle = "blue";
          contx.fill();

          if (
            loadCoordinate[i].id == 0 &&
            loadCoordinate[i].isDisabled == false
          ) {
            contx.font = "10px Arial";
            contx.textBaseline = "middle";
            contx.fillStyle = "brown";
            contx.fillText(
              //fillText for Points placeholders
              "Point " + loadCoordinate[i].coOrdinateId,
              Number(loadCoordinate[i].coordX) - 15,
              Number(loadCoordinate[i].coordY) - 15
            );
          }

          if (loadCoordinate[i].isDisabled == true) {
            debugger;
            contx.font = "13px Arial";
            contx.textBaseline = "middle";
            contx.fillStyle = "blue";

            contx.fillText(
              //fillText for coordinate boxno
              loadCoordinate[i].boxno,

              Number(loadCoordinate[i].coordX) + 7,
              Number(loadCoordinate[i].coordY)
            );
          }
        }
      }
    };
  }

  getPosition(event) {
    debugger;
    //get cursor position on canvas
    this.canvas = <HTMLCanvasElement>document.getElementById("canvas");
    this.table = <HTMLCanvasElement>document.getElementById("table");
    this.ctx = this.canvas.getContext("2d");
    let curleft = 0, curtop = 0;

    //get X & Y co-ordinates of event
    curleft += event.offsetX;
    curtop += event.offsetY;

    //creating dynamic coordinate list on drawing points
    var tempCordList = this.coordinateList.filter(x => (x.coordX + 10 >= curleft) && (x.coordX - 10 <= curleft)
      && (x.coordY + 10 >= curtop) && (x.coordY - 10 <= curtop));

    if (tempCordList == null || tempCordList == undefined || tempCordList.length == 0) {
      this.drawCoordinates(curleft, curtop);

      this.ctx.font = "12px Arial";
      this.ctx.fillStyle = "red";
      this.ctx.fillText('01 Jan 0001', curleft + 15, curtop);

      this.coordinateList.push({
        id: 0,
        coOrdinateId: this.num,
        categoryId: this.category,
        isDisabled: false,
        boxno: "",
        boxNoIsActive: false,
        coordX: curleft,
        coordY: curtop,
      });
      this.num += 1;
    }
    console.log('getPositionMethod', this.coordinateList);

    //open pop up when clicked on canvas   
    this.onCoordItem = this.coordinateList.filter(x => (x.coordX + 10 >= curleft) && (x.coordX - 10 <= curleft)
      && (x.coordY + 10 >= curtop) && (x.coordY - 10 <= curtop))[0];

    console.log("onCoordItem", this.onCoordItem);
    if (this.onCoordItem != null && this.onCoordItem != undefined) {
      this.openOnPointSaveModal();
    }
  }

  saveCoordinate(item) {
    debugger;
    this.status = false;
    if (
      item.boxno != null ||
      item.boxno != undefined ||
      item.boxno != ""
    ) {

      // checks unique box no by comparing from points which have not been saved in DB.
      // this.status = this.coordinateList.some(coord => {
      //   let counter = 0;
      //   for (const i of this.coordinateList) {
      //     if (i.boxno === coord.boxno) {
      //       counter += 1;
      //     }
      //   }
      //   return counter > 1;
      // });

      // checks unique box no by comparing from points which have not been saved in DB.
      var checkBoxNoPointsCreatedNow = this.coordinateList.filter(x => x.boxno == item.boxno && x.id == 0 && x.coOrdinateId != item.coOrdinateId)[0];
      checkBoxNoPointsCreatedNow != null && checkBoxNoPointsCreatedNow != undefined ? this.status = true : false;

      //checks unique box no by comparing from points which are saved in DB.
      this.boxNoCheck = this.coordinateList.filter(x => x.boxno == item.boxno && x.id > 0 && x.id != item.id)[0];

      //if box no is not unique it show alert
      if (this.status == true) {
        item.boxno = null;
        this.openSaveModal();
      } else if (this.boxNoCheck != null && this.boxNoCheck != undefined) {
          this.status = true;
          item.boxno = null;
          this.openSaveModal();
      }
      else {
        var currentPoint: any;
        if (item.coOrdinateId != undefined) {
          currentPoint = this.coordinateList.filter(
            (x) =>
              x.coOrdinateId == item.coOrdinateId && x.category == item.category
          )[0];
        }
        else {
          currentPoint = this.coordinateList.filter(x => x.id == item.id)[0];
        }
        currentPoint.isDisabled = true;
        currentPoint.isBoxnoDisabled = true;
        //after clicking on save co-ordinate, boxno is displayed on canvas
        this.ctx.font = "11px Arial";
        this.ctx.textBaseline = "middle";
        this.ctx.fillStyle = "blue";

        this.ctx.fillText(
          currentPoint.boxno,
          Number(currentPoint.coordX),
          Number(currentPoint.coordY) + 13
        );

        this.modalService.dismissAll();
         this.loadCanvas();
      }
    }
  }



  deleteCoordinate(item, userId:string) {
    debugger;

    if (item.id == 0) {
      debugger;
      //Deleting points without main save
      this.coordinateList = this.coordinateList.filter(
        (x) => x.coOrdinateId != item.coOrdinateId
      );
      this.loadCanvas();
     } 
    // else if (item.id > 0) {
    //   debugger;
    //   //Delete on API Call
    //   this.customHttpService
    //     .getDataWithAuthentication(
    //       "/GeoTagger/DeleteImageCoordinate?Id=" + item.id
    //     )
    //     .subscribe((data) => {
    //       if (data.isSuccess) {
    //         this.responseModel = data.responseData;
    //        this.ngOnInit();
    //       }
    //     });
    // }
    else if(item.id > 0) {
      debugger;
      //Delete on API Call
      this.customHttpService
        .getDataWithAuthentication(
          "/GeoTagger/DeleteImageCoordinate?Id=" + item.id +"&UserId="+item.userId
        )
        .subscribe((data) => {
          if (data.isSuccess) {
            this.responseModel = data.responseData;
            // this.ngOnInit();
            this.coordinateList = this.coordinateList.filter(x=>x.id != item.id);
            console.log('DBBoxNoDelete', this.coordinateList);
            //load canvas again to remove deleted point and render remaining points
            this.loadCanvas();
          }
        });
    }
  }

  selectCustomerId(id: string) {
    debugger;
    //API call for getting details by customer id
    this.customHttpService
      .getDataWithAuthentication(
        "/GeoTagger/GetCustomerDetailsById?CustomerId=" + id
      )
      .subscribe((data) => {
        if (data.isSuccess) {
          this.customerResponseModel = data.responseData;
          if (this.customerResponseModel.length > 0) {
            this.customerResponseModel = this.customerResponseModel[0];
            this.customerId = this.customerResponseModel.customer_id__c;
            this.customerName = this.customerResponseModel.name;
            this.city = this.customerResponseModel.billingCity;
            this.state = this.customerResponseModel.billingState;
            //console.log(this.customerId, this.customerName, this.city, this.state);
          }
          else {
            this.customerName = "Please enter Valid Customer Id";
          }
        }
      });
  }

  drawCoordinates(x, y) {
    debugger;
    const grd = this.ctx.createLinearGradient(0, 0, 170, 0);
    //draw co-ordinate on canvas
    this.ctx.beginPath();
    this.ctx.arc(Number(x), Number(y), this.pointSize, 0, Math.PI * 2, true);
    this.ctx.fillStyle = "blue";
    this.ctx.fill();
    this.ctx.font = "10px Arial";
    this.ctx.textBaseline = "middle";
    this.ctx.fillStyle = "brown";
    this.ctx.fillText("Point" + this.num, Number(x) - 15, Number(y) - 15);
    const coord = "x=" + x + ", y=" + y;
  }

  readURL(event): void {
    debugger;
    this.coordinateList = [];
    this.imageTag = "";
    this.customerId = "";
    this.customerName = "";
    this.acceptableCriteria = null;
    console.log(event);

    if (event.target.files && event.target.files[0]) {
      //get image file in base64 format
      debugger;
      this.imageFile = event.target.files[0];
      for (let item of event.target.files) {
        var imageSize = item.size;
      }
      //check image size greater or equal to 10MB
      if (imageSize <= 10000000) {
        debugger;
        this.canvasIsHidden = false;
        this.canvas = <HTMLCanvasElement>document.getElementById("canvas");
        this.ctx = this.canvas.getContext("2d");
        let canvas = this.canvas;
        let context = this.ctx;
        debugger;
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.newImage = e.target.result;
          //console.log(this.newImage);
          const img = new Image();

          img.onload = () => {
            debugger;
            //console.log("width", img.width);

            //auto adjust as per image with max limits of canvas height and width
            // var maxWidth = 950;
            // var maxHeight = 750;
            // var width = img.width;
            // var height = img.height;
            // if (width > height) {
            //   if (width > maxWidth) {
            //     height *= maxWidth / width;
            //     width = maxWidth;
            //   }
            // } else {
            //   if (height > maxHeight) {
            //     width *= maxHeight / height;
            //     height = maxHeight;
            //   }
            // }

            //adjust image as per fixed canvas height and width

            // var width = 950;
            // var height = 750;

            var width = img.width;
            var height = img.height;

            this.imgWidth = img.width;
            this.imgHeight = img.height;
            console.log('imgWidth, imgHeight', this.imgWidth, this.imgHeight);


            if (width > height) {
              width = 1230;
              height = img.height * (width / img.width);
            } else if (height > width) {
              width = 1000;
              height = img.height * (width / img.width);
            } else {
              var width = 1200;
              var height = 1200;
            }

            canvas.width = width;
            canvas.height = height;
            context.drawImage(img, 0, 0, width, height);
          };

          //get base64URL(DataURL) of image
          img.src = reader.result.toString();
          debugger;
          this.newImageSrc = img.src;
        };
        document.getElementById("fileName").innerHTML = this.imageFile.name;
        reader.readAsDataURL(event.target.files[0]);
        this.isEnlargeButtonHidden = false;
      } 
      else {
        alert("Please Upload Image Size below 10MB!!");
      }
    }
  }

  openOnPointSaveModal() {
    this.modalService.open(this.onPointSaveModel, {backdrop: 'static',
    keyboard: false, windowClass: 'custom-class'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  openViewListModal(coordlistview) {
    debugger;
    this.modalService.open(coordlistview, { windowClass: 'custom-class' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onModalClose() {
    debugger;
    console.log('onCloseCoordItem', this.onCoordItem);
    if (this.onCoordItem.isDisabled == false && this.onCoordItem.id == 0) {
      this.deleteCoordinate(this.onCoordItem,this.loggedinUserId);
    } else if (this.onCoordItem.boxNoIsActive == false && this.onCoordItem.id > 0 && this.onCoordItem.isBoxnoDisabled == false) {
      this.deleteCoordinate(this.onCoordItem,this.loggedinUserId);
    }
    this.modalService.dismissAll();
  }

  checkLoading() {
    return true;
  }
}

