import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})
export class GoogleAuthComponent implements OnInit {

  googleCode: string = "";
  userInfo:any={};

  constructor(private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private router:Router) { 
    this.googleCode= this.activatedRoute.snapshot.queryParamMap.get('code');
  }

  ngOnInit() {
    if(this.googleCode !=undefined ||this.googleCode !=null||this.googleCode !=""){
      this.validateGoogleUserByCode();
      }
  }

  validateGoogleUserByCode(){
    this.customHttpService.getData("/user/ValidateGoogleUserByCode?code="+this.googleCode)
    .subscribe(data=>{
      if(data.isSuccess){
        localStorage.setItem("omloggedInMobileNumber",data.responseData.mobile);
        localStorage.setItem("omgcbstoretoken",data.responseData.accessToken);
        localStorage.setItem("omloggedinemail",data.responseData.email);
        localStorage.setItem("omloggedinusername",data.responseData.name);
        localStorage.setItem("omloggedinuserid",data.responseData.id);
        localStorage.setItem("omloggedinusertype",data.responseData.typeName);

        this.setUserInfo();
        this.router.navigate(["dashboard"]);
      }
      else{
        alert(data.errorMessage);
      }
     
    });
  }

  setUserInfo(){
    this.userInfo.omloggedInMobileNumber=localStorage.getItem("omloggedInMobileNumber");
    this.userInfo.omgcbstoretoken= localStorage.getItem("omgcbstoretoken");
    this.userInfo.omgcbstoretoken=localStorage.getItem("omloggedinemail");
    this.userInfo.omloggedinusername=localStorage.getItem("omloggedinusername");
    this.userInfo.omloggedinuserid=localStorage.getItem("omloggedinuserid");
    this.userInfo.omloggedinusertype= localStorage.getItem("omloggedinusertype");

    return this.userInfo;
  }
}
