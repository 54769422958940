import { HttpClient } from '@angular/common/http';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class CommonProperties {

  public static sortList: any=[];
  public static  property:string="hello";

static setSortList(sortListData:any){
  debugger;
  this.sortList = sortListData;
}

static getSortList(){
  return this.sortList;
}
}
 