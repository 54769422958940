import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomhttpService } from 'src/app/services/customhttp.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;
  loggedInUserName: string = "";
  loggedinUserEmail: string = "";
  loggedinUserMobile: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  followupNotificationList: any[];

  constructor(config: NgbDropdownConfig, private router: Router, private customHttpService: CustomhttpService) {
    config.placement = 'bottom-right';
    config.autoClose = false;

    // this.loadPage.setUserInfo().subscribe(response=>{
    //   this.loggedInUserName =response.omloggedinusername;
    //   this.loggedinUserEmail =response.omloggedinemail;
    //   this.loggedinUserMobile = response.omloggedInMobileNumber;
    //   this.loggedinUserType =response.omloggedinusertype;
    //   this.loggedinUserId = response.omloggedinuserid;
    // });
    this.loggedInUserName = localStorage.getItem("omloggedinusername");
    this.loggedinUserEmail = localStorage.getItem("omloggedinemail");
    this.loggedinUserMobile = localStorage.getItem("omloggedInMobileNumber");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
  }

  ngOnInit() {

   
      this.loadFollowupNotification();
   
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if ((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if (this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if (this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  toggleRightSidebar() {
    document.querySelector('#right-sidebar').classList.toggle('open');
  }

  logout() {
    localStorage.removeItem("omgcbstoretoken");
    window.close();
    //this.router.navigate(["/login"]);

  }

  loadFollowupNotification() {
   
    //   this.followupNotificationList=[{"ticketNo":"343","accountId":"953953/29.11.2021","accountName":"TSB-S315-SKYMARK ONE- NOIDA",
    // "reason":"Test followup for account","isClose":false},
    // {"ticketNo":"35678","accountId":"960479/10.01.2022","accountName":"Kiranakart_Jayanagar",
    // "reason":"Availability followup for account","isClose":false}
    // ];

    this.customHttpService.getDataWithAuthentication("/Complaint/GetAllFollowupComplaints?userId=" + this.loggedinUserId)
      .subscribe(data => {
       
        this.followupNotificationList = data.responseData;

        for (var i = 0; i < this.followupNotificationList.length; i++) {
          this.followupNotificationList[i].isClose = false;
        }        
      })
   
  }

  removeFollowup(item) {
   
    this.customHttpService.postData("/Complaint/UpdateFollowupStatus",
     {
      "Id":item.id,      
      "UserId":this.loggedinUserId
    })
      .subscribe(data => {
      
    item.isClose = true;    
  })
  
}

IsNotificationToBeAutoOpened()
{
  if((this.router.url.includes("/dashboard") || this.router.url.includes("/tickets_detail") )&& this.followupNotificationList!=undefined && this.followupNotificationList!=null && this.followupNotificationList.length>0)
  {
  var follCount = this.followupNotificationList.filter(x => x.isClose == false).length;
  return follCount;
  }else return 0;
}

  getFollowupCount() {
    if( this.followupNotificationList!=undefined && this.followupNotificationList!=null && this.followupNotificationList.length>0)
    {
    var follCount = this.followupNotificationList.filter(x => x.isClose == false).length;
    return follCount;
    }else return 0;
  }
  redirectToTicketDetail(ticketNo) {
    var url = "tickets_interaction_detail/" + ticketNo;
    window.open(url, "_blank");
  }
}
