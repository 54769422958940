import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { formatDate } from '@angular/common';
import { SummaryCardComponent } from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { SrbaseComponent } from '../srbase/srbase.component';


@Component({
  selector: 'app-omrom-mapping',
  templateUrl: './omrom-mapping.component.html',
  styleUrls: ['./omrom-mapping.component.scss']
})
export class OmromMappingComponent implements OnInit {

  omRomList = [];
  items = [];
  pageOfItems: Array<any>;
  allPageOfItems: Array<any>;
  isEdit: boolean = false;
  isSave: boolean = false;
  closeResult = '';
  userType: string = '';
  region: string;
  serviceCenter: string;
  fullName: string;
  mobile: string;
  email: string;
  errorMsg: string;
  successMsg: string;
  isSuccess:boolean=false;
  isError: boolean = true;
  sortText: string;
  reverse: boolean = false;
  scIsDisabled: boolean = false;
  isSaveDisabled: boolean = true;
  userId:number=0;
  isDisabled:boolean=false;
  popupName:string;
  searchText="";
  isRegionDisabled: boolean = false;
  id: number;
  userIdForDelete: number = 0;
  typeName: string = '';
  loggedinUserEmail: string = '';
  isUserDeleted: boolean = false;
  message: string = "";
  isDeleteSuccess : boolean = false;
  tableColumn = {
    typeName: "Type", regionName: "Region", scName: "Service Center", fullName: "Full Name",
    mobileNumber: "Mobile Number",
    eMail: "Email"
  };

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService, private commonMethods: CommonMethods,
    private modalService: NgbModal) {

  }

  ngOnInit() {
    this.loggedinUserEmail = localStorage.getItem("omloggedinemail");
    console.log(this.loggedinUserEmail);
    this.getOMROMMappingList();
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  getOMROMMappingList() {
  debugger
    this.customHttpService.getDataWithAuthentication("/User/GetOMROMMappingList")
      .subscribe(data => {

        console.log(data.responseData)
       
        // for (let i = 0; i < data.responseData.length; i++) {
        //   data.responseData[i].isSave = false;
        // }
       
        this.omRomList = data.responseData;
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, null, 'regionName', true);
        this.sortText = "Region (Ascending)";
      });


  }
  checkLoading() {
    if (this.items != undefined && this.items != null && this.items.length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  editUser(item) {
    debugger;
    item.isEdit = true;
    this.isEdit = true;
  }

  cancelItem(item) {
    item.isEdit = false;
  }

  openAddUser(content, item: any) {
    debugger;
    this.errorMsg ="";
    this.successMsg="";
    this.isError=false;

    if(item==null ||item==undefined){
      console.log("item null");
    this.userType = null;
    this.region = "";
    this.serviceCenter = "";
    this.fullName = "";
    this.mobile = "";
    this.email = "";
    this.isDisabled=false;
    this.isRegionDisabled = false;
    this.userId=0;
    this.popupName="Add New User";
    }
    else{
      console.log("item not null");
      this.userType = item.typeName;
    this.region = item.regionName;
    this.serviceCenter = item.scName;
    this.fullName =item.fullName
    this.mobile = item.mobileNumber;
    this.email =item.eMail;
    this.userId=item.id;
    this.checkUserTyp();
    this.isDisabled=true;
    this.isRegionDisabled = true;
    this.scIsDisabled=true;
    this.popupName="Edit User";
    }
    console.log("type" + this.userType);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

 

  addUser() {

    debugger;
    if (this.email.toLowerCase().includes('@hicare.in') || this.email.toLowerCase().includes('@rentokil')) {
      // var response=await this.commonUpdateUser(0,this.userType,this.region,this.serviceCenter,this.fullName,this.mobile,this.email);

      this.customHttpService.postData("/User/UpdateUser",
        {
          "Id": this.userId,
          "TypeName": this.userType,
          "RegionName": this.region,
          "SCName": this.serviceCenter,
          "FullName": this.fullName,
          "MobileNumber": this.mobile,
          "EMail": this.email
        }).subscribe(data => {
          debugger;
          if (data.isSuccess) {
            this.isError = false;           
            this.isSuccess=true;

            this.successMsg =(this.userId==0)? "User Saved Successfully!":"User Updated Successfully!";           
            setTimeout(() => { document.getElementById("btnCloseModel").click() }, 5000);
          
            this.omRomList = [];
            this.getOMROMMappingList();
          }
          else {
            this.isError = true;
            this.errorMsg =data.errorMessage;
          }
        });
    }
    else {
      this.isError = true;
      this.errorMsg = "Please enter valid Email!"
    }
  }

  closeModel() {

  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(parent.parentElement.classList);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, event, column, isASc, dataTyp);
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }



  onKeyPressCheckSpecialCharacter(event: any) {
    return this.commonMethods.commonCheckSpecialCharacter(event);

  }
  onKeyPressCheckForMobile(event: any) {
    return this.commonMethods.commonCheckForMobileNo(event);
  }
  onKeyPressCheckForEmailSpecialCharacter(event: any) {
    return this.commonMethods.commonCheckForEmail(event);
  }
  changeCheckTxt(event: any) {
    debugger;
    var chkRemark = this.commonMethods.commonValidateString(event);
    //item.isSpecialChar= !chkRemark;
  }
  changeCheckMobile(event: any) {
    debugger;
    var chkRemark = this.commonMethods.commonValidateMobileString(event);
  }

  search(value: string) {

    var tableColumnObj = this.tableColumn;

    if (value.length >= 3) {
      this.pageOfItems = this.allPageOfItems.filter(function (item) {
        console.warn("item", item);

        for (var key in tableColumnObj) {
          if (item[key] != null && item[key] != undefined &&
            item[key].toString().toLowerCase().includes(value.toLowerCase()))
            return true;
        }
        return false;
      });
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
    this.items = this.pageOfItems;
    //this.isList=true;

  }

  checkUserTyp  () {
    debugger
    if (this.userType == "ROM" || this.userType == "SROM" || this.userType == "ADMIN") {
      this.scIsDisabled = true;
      this.serviceCenter = '';
      //this.isDisabled=true;
    }
    else {
      this.scIsDisabled = false;
      //this.isDisabled=false;
    }

    if (this.userType == "ADMIN") {
      this.isRegionDisabled = true;
      this.region = '';
    }
    else {
      this.isRegionDisabled = false;
    }
  }

  checkSaveDisabled() {

    debugger;
    if (this.userType!=undefined && this.userType!=null && this.userType != '' && this.region != '' && this.fullName != ''

      && this.mobile != '' && this.email != '') {

     if (this.userType == "ROM"  || this.userType == "SROM") {
        return true;
      }
      // return this.serviceCenter != '';
      else {
       
        if (this.serviceCenter != '') {
          return true;
        }
        else {
          return false;
        }
      }
    }

    if(this.userType=="ADMIN"  && this.fullName != ''  && this.mobile != '' && this.email != ''){
      return true;
    }
    else
    {
      return false;
    }
  }

  open(content, id, fullName, typeName){
  debugger;
  this.userIdForDelete = id;
  this.fullName = fullName;
  this.typeName = typeName;
  this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    this.closeResult = `Closed with: ${result}`;
  }, (reason) => {
    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  });
  }

  deleteUser() {
    debugger;
    console.log(this.userIdForDelete);
    this.customHttpService.postData("/User/DeleteUser", this.userIdForDelete).subscribe(data => {
    if(data.isSuccess) {
      this.isDeleteSuccess = true;
      this.isUserDeleted = true;
      this.message = "User Deleted Successfully!";
      this.getOMROMMappingList();
    }
    else {
      this.isDeleteSuccess = false;
      this.isUserDeleted = false;
      this.message = "Something went wrong!";
    }   
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
