import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CommonMethods } from 'src/app/services/commonmethods';
import { formatDate } from '@angular/common';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { NgbAccordionConfig, NgbDate, NgbDateParserFormatter,NgbTimepickerModule  } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from 'src/app/services/dateformat';


@Component({
  selector: 'app-tickets-detail',
  templateUrl: './tickets-detail.component.html',
  styleUrls: ['./tickets-detail.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ]
})
export class TicketsDetailComponent implements OnInit {
  category: string = "";
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  period: string = "";
  sortText: string = "";
  loggedInUserName: string = "";
  isList: boolean = true;
  exportData: boolean = true;
  accountId: string;
  isCollapsed = true;
  breadCrumbAccountId: string;
  breadCrumbAccountName: string;
  loggedinUserEmailid: string;
  isSave: boolean = false;
  statusList = [];
  items = [];
  interactionList = [];
  pageOfItems: Array<any>;
  searchText = "";
  isShowComplaint: boolean = false;
  statusSummaryList = [];
  chartlabels = [];
  countArry = [];
  isShowSummary: boolean = true;
  closeResult = '';
  selectedTicketId:string='';
  selectedSubject:string='';
  followupList=[];  
  pickerDate: NgbDate;
  selectedCriteria:string="All";

  tableColumn = {
    ticketCreatedOn: "Created On", ticketModifiedOn: "Modified On",
    subject: "Title", //descriptionText: "Description",
    fdTicketId: "Ticket Id",
    complaintType: "Complaint Type", status: "Status", duration: "Age", action: "Action",
    accountId: "Account Id", accountName: "Account",followupDisplayDate:"Follow Up"
  };

  ageCriteriaList=["Greater than 15 days"];

  constructor(private activatedRoute: ActivatedRoute,
    private customHttpService: CustomhttpService,


    private modalService: NgbModal, private commonMethods: CommonMethods) {
    this.accountId = this.activatedRoute.snapshot.params.accountId;
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
    var date = new Date();
    this.pickerDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());

    // this.breadCrumbAccountId="Tickets Detail For Account ";
  }

  

  onChangePage(pageOfItems: Array<any>) {
    debugger
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedInUserName = localStorage.getItem("omloggedinusername");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.loggedinUserEmailid = localStorage.getItem("omloggedinemail");
 
    //this.statusList = this.commonMethods.ticketStatusList;
    //this.getTicketsStatus();
    if (this.accountId != "0") {
      this.isShowComplaint = true;
    }
    this.getTicketsCompalintAccountDetailList();
  }


  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length > 0 || this.isList) && this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    debugger
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(target.parentElement);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if (item.classList != undefined) {
        item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, event, column, isASc, dataTyp);
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse;

    if(column=="ticketCreatedOn"){
      if(isASc)this.sortText="Age (Descending)";
      else this.sortText="Age (Ascending)";
    }

    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  search(value: string): void {
    debugger;
    this.isList = false;

    if (value.length >= 3) {
      this.pageOfItems = this.commonMethods.commonSearch(this.tableColumn, this.allPageOfItems, value);
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
    console.log(this.pageOfItems);
    this.items = this.pageOfItems;
    this.isList = true;

    this.filterByAutoGenerated();
  }
  filterByAutoGenerated() {
    if (this.isShowComplaint) {
      //this.items = this.allPageOfItems;
    }
    else {
      this.items = this.items.filter((val) =>
        (val.subject != null && !val.subject.toLowerCase().includes('autoemailer')));
    }

    if(this.selectedCriteria=="Greater than 15 days"){

      this.items = this.items.filter((val) =>
      (val.ticketCreatedAgingInDay!= null && parseInt(val.ticketCreatedAgingInDay)>15));
    }
    this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'duration',false,'date'); 
    
    this.sortText = "Age (Ascending)";    
    console.log("final"+this.items.length);
  }

  searchByStatus(value: string): void {
    this.pageOfItems = this.allPageOfItems.filter((val) => (val.status != null && val.status == value));
    this.items = this.pageOfItems;
    this.isList = true;
    this.searchText = value;
  }
  getTicketsCompalintAccountDetailList() {
    debugger
    this.isList = false;
    var url = "/Complaint/GetTicketComplaintDetails?accountId=" + this.accountId;
    if (this.accountId == "0") {
      url = "/Complaint/GetMyTicketComplaints?userId=" + this.loggedinUserId +
        "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC;
    }

    this.customHttpService.getDataWithAuthentication(url)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList = true;
        if (this.accountId != "0") {
          this.breadCrumbAccountId = data.responseData[0].accountId;
          this.breadCrumbAccountName = data.responseData[0].accountName;
        }
        debugger;
        let currentDate = new Date();
        for (let i = 0; i < this.items.length; i++) {
          this.items[i].duration = this.commonMethods.calculateDateDiff(this.items[i].ticketCreatedOn);
          this.items[i].sortOrder = this.items[i].status == "Closed" ? 0 : 1;
          //this.items[i].dayAge=this.commonMethods.calculateDayDiff(this.items[i].ticketCreatedOn);
        }

        this.commonMethods.commonSortBy2Column(this.items, "sortOrder", false, "ticketCreatedOn", false);
        //this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'status',false); 
        //this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'subject',true); 
        this.sortText = "Not Closed Complaints, Duration";
        this.showAutoComplaints(null);
        // if (this.loggedinUserType == "TECHS" || this.loggedinUserType == "TECHADMIN") {
        //   setTimeout(() => {
        //     this.searchText = "Treated - Re-inspection Needed";
        //     this.search("Treated - Re-inspection Needed");
        //   }, 2000);

        // }
        //this.getStatusWiseSummary();
      });
  }

  getStatusWiseSummary() {
    debugger;
    this.statusSummaryList = this.commonMethods.groupByCommon(this.allPageOfItems, "status");
    this.statusSummaryList.sort(function (a, b) { return sort(a["value"], b["value"], false) });

    for (let i = this.statusSummaryList.length - 1; i >= 0; i--) {
      this.chartlabels.push(this.statusSummaryList[i].Name);
      this.countArry.push(this.statusSummaryList[i].value);
    }
  }

  showAutoComplaints(event) {
    debugger;
    if (event != null) {
      this.isShowComplaint = event.currentTarget.checked;
    }

    this.search(this.searchText);

    // if (this.isShowComplaint) {
    //   this.items = this.allPageOfItems;
    // }
    // else {
    //   this.items = this.allPageOfItems.filter((val) =>
    //     (val.subject != null && !val.subject.toLowerCase().includes('autoemailer')));

    // }
    // this.commonMethods.commonSortBy2Column(this.items, "sortOrder", false, "ticketCreatedOn", false);
    // this.sortText = "Not Closed Complaints, Duration";
    // this.searchText = "";
  }

  showSummary(event) {
    debugger;
    this.isShowSummary = event.currentTarget.checked;

  }

  // getInteractionDetails(item) {

  //   console.log(item.isCollapsed);
  //   if (!item.isCollapsed) {
  //     this.customHttpService.getDataWithAuthentication("/Complaint/GetB2bComplaintInteractionDetails?accountId=" + this.accountId +
  //       "&complaintId=" + item.complaintId)
  //       .subscribe(data => {
  //         console.log(data.responseData)
  //         this.interactionList = data.responseData;

  //         item.isCollapsed = true;

  //       });
  //   }
  //   else {
  //     item.isCollapsed = false;
  //   }
  // }

  exportToExcel() {
    debugger;
    var callMethod: string = "";
    this.exportData = false;
    var url = "/Complaint/ExportTicketComplaintDetail?accountId=" + this.accountId+"&isShowAutoGenerated="+this.isShowComplaint
    +"&ageCriteria="+this.selectedCriteria;

    if (this.accountId == "0") {
      url = "/Complaint/ExportMyTicketComplaintDetail?userId=" + this.loggedinUserId +
        "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + 
        "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC+"&isShowAutoGenerated="+this.isShowComplaint
        +"&ageCriteria="+this.selectedCriteria;
    }


    this.customHttpService.exportDataWithAuthentication(url)
      .subscribe(data => {


        console.log(data);
        this.exportData = true;       
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });        
        
        var url = window.URL.createObjectURL(b);
        //window.open(url);
        var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = "TicketComplaint_Detail" + nowDate + ".xls";
        anchor.href = url;
        anchor.click();
      });
  }

  navigateToInteractionDetail(ticketId: number) {
    var url = "tickets_interaction_detail/" + ticketId;
    window.open(url, "_blank");
  }

  navigateToAccountDetail(accountId:string){
    var accountNo= accountId.split('/')[0];
    var url = "tickets_detail/"+accountNo;
    window.open(url, "_blank");
  }
  saveStatus(item) {
    debugger;
    console.log(item.action);
    if (item.action != "Closed") {

      this.customHttpService.getDataForAbsoluteUrl(this.commonMethods.ticketDomain + "/Ticket/ChangeStatus?ticketId=" + item.fdTicketId + "&status=" + item.action.replace("-", "").replace(" ", ""))
        .subscribe(data => {
          // if(data.isSuccess)
          // {
          debugger;
          this.customHttpService.postData("/Complaint/ChangeComplaintStatus", { "Status": item.action, "TicketId": item.fdTicketId }).subscribe(data => {
            this.isSave = true;
            setTimeout(() => { this.isSave = false; }, 5000);
          });

          // }
        });
      // this.isSave = true;
      // item.status=item.action;
      // setTimeout(() => { this.isSave = false; }, 5000);
    }
  }
  checkStatus(status) {
    if (status == 'Closed' || status == 'Resolved')
      return false;
    else
      return true;
  }
  getTicketsStatus() {
    debugger;
    this.customHttpService.getDataWithAuthentication("/Complaint/GetComplaintStatusMasterList?userTypeName=" + this.loggedinUserType)
      .subscribe(data => {
        console.log(data.responseData);

        this.statusList = data.responseData;

      })
  }

  openFollowupModel(content, item: any){    
    debugger;
    this.selectedTicketId=item.fdTicketId;
    this.selectedSubject=item.subject;

  this.loadFollowupList(item);

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',windowClass:"myCustomModalClass"  }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    }); 
    
  }

  onPieChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      this.searchByStatus(activePoints[0]._model.label);
      this.searchText = activePoints[0]._model.label;
    }
  }

  editFollowup(item){
item.isEdit=true;
  }

  cancelItem(item){
    
    var followDate=new Date(item.followUpDate);
    item.displayNgbFollowDate=new NgbDate(followDate.getFullYear(),followDate.getMonth()+1,followDate.getDate());
    item.displayReason=item.reason;
    item.isEdit=false;
  }

  loadFollowupList(item){
    this.followupList=[];
    this.customHttpService.getDataWithAuthentication("/Complaint/GetComplaintFollowupList?ticketId=" + item.fdTicketId)
    .subscribe(data => {
      console.log(data.responseData);

      this.followupList = data.responseData;
      for(var i=0;i<this.followupList.length;i++){
       var followDate=new Date( this.followupList[i].followUpDate);
        this.followupList[i].displayNgbFollowDate=new NgbDate(followDate.getFullYear(),followDate.getMonth()+1,followDate.getDate());
        this.followupList[i].displayReason=this.followupList[i].reason;
        this.followupList[i].isEdit=false;
      }

    })

  }
  UpdateFollowup(item){
   
  const followDate=new Date(item.displayNgbFollowDate.year,item.displayNgbFollowDate.month-1,item.displayNgbFollowDate.day+1);
    this.customHttpService.postData("/complaint/SaveTicketComplaintFollowUp" ,
    {
      "Id":item.id,
      "FdTicketId":item.fdTicketId,
      "FollowUpDate":followDate,
      "Reason":item.displayReason,
      "UserId":this.loggedinUserId,
      "UserEmail":this.loggedinUserEmailid
    }).subscribe(data => {
      if (data.isSuccess) {
        // this.isFollowupSave=true;
        // setTimeout(() => { this.isFollowupSave = false; }, 5000);

        this.loadFollowupList(item); 
      }})
      
  }

  deleteFollowup(item){

    this.customHttpService.postData("/complaint/DeleteTicketComplaintFollowUp" ,
   item.id).subscribe(data => {
      if (data.isSuccess) {
        // this.isFollowupSave=true;
        // setTimeout(() => { this.isFollowupSave = false; }, 5000);

        this.loadFollowupList(item); 
      }})
      

  }

  filterByAge(){
    debugger;
    this.search(this.searchText);
  }
  public pieChartOptions: ChartOptions = {
    responsive: true,
    onClick: (clickEvt, activeElems) => this.onPieChartClick(clickEvt, activeElems, "status")
  };
  public pieChartLabels: Label[] = this.chartlabels;
  public pieChartData: SingleDataSet = this.countArry;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }

}
