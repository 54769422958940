import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { SummaryCardComponent } from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-controlroom-adherence',
  templateUrl: './controlroom-adherence.component.html',
  styleUrls: ['./controlroom-adherence.component.scss']
})
export class ControlroomAdherenceComponent implements OnInit {
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  category: string = "";
  period: string = "";
  exportData: boolean = true;
  isList: boolean = true;
  totalCount: number;
  taskType: string;

  items = [];
  pageOfItems: Array<any>;

  amtIn: number = 100000;
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  barChartResourceLabels: any = [];
  searchText: string = "";
  chartLabel: string = "Percentage";
  activeIdString: any = "1";
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  resourceWiseList = [];
  barChartResourceData: any = [];
  sortText: string = "";
  isShowInCorrect: boolean = false;
  status: string;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService, private commonMethods: CommonMethods) {
debugger;
    this.category = this.activatedRoute.snapshot.params.category;
    this.period = this.activatedRoute.snapshot.params.period;
    this.status = this.activatedRoute.snapshot.params.status;
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }


  ngOnInit() {
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");

    this.getAdherenceList();
  }
  getAdherenceList() {
    this.isList = false;

    this.customHttpService.getDataWithAuthentication("/Task/GetControlRoomAdherenceList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category + "&period=" + this.period+"&status="+this.status)
      .subscribe(data => {
        debugger;
        this.items = data.responseData;

        this.allPageOfItems =  this.items;

        this.totalCount = this.items.length;
        this.isList = true;

        this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, null, 'accountName', true);
        this.sortText = "Account (Ascending)";
        //this.showInCorrectData();
      });


    this.customHttpService.getDataWithAuthentication("/Task/GetControlRoomAdherenceSummary?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category + "&period=" + this.period+"&status="+this.status)
      .subscribe(data => {

        console.log(data.responseData.regionList);
        //new changes
        this.groupByRegion(data.responseData.regionList);
        this.groupByROM(data.responseData.romList);
        this.groupByOM(data.responseData.omList);
        this.groupBySC(data.responseData.scList);
        //new 
        this.groupByResource(data.responseData.technicianList);
      });

  }
  groupByRegion(array) {

    let regionWiseChartData: ChartModel;

    regionWiseChartData = this.commonMethods.groupByRegionPercent(array, this.chartLabel);

    this.barChartData = regionWiseChartData.chartData;
    this.mbarChartLabels = regionWiseChartData.chartlabels;
    this.regionWiseList = regionWiseChartData.list;
  }

  groupByOM(array) {

    let omWiseChartData: ChartModel;

    omWiseChartData = this.commonMethods.groupByOMPercent(array, this.chartLabel);

    this.hbarChartData = omWiseChartData.chartData;
    this.hbarChartLabels = omWiseChartData.chartlabels;
    this.omWiseList = omWiseChartData.list;
  }
  groupByROM(array) {
    let romWiseChartData: ChartModel;

    romWiseChartData = this.commonMethods.groupByROMPercent(array, this.chartLabel);

    this.romBarChartData = romWiseChartData.chartData;
    this.romMbarChartLabels = romWiseChartData.chartlabels;
    this.romWiseList = romWiseChartData.list;
  }

  groupBySC(array) {
    let scWiseChartData: ChartModel;

    scWiseChartData = this.commonMethods.groupBySCPercent(array, this.chartLabel);

    this.hBarChartSCData = scWiseChartData.chartData;
    this.hBarChartSCLabels = scWiseChartData.chartlabels;
    this.scWiseList = scWiseChartData.list;
  }

  groupByResource(array) {
    console.log("by technician");
    let resourceWiseChartData: ChartModel;

    resourceWiseChartData = this.commonMethods.groupByResourcePercent(array, this.chartLabel);

    console.log(resourceWiseChartData);
    this.barChartResourceData = resourceWiseChartData.chartData;
    this.barChartResourceLabels = resourceWiseChartData.chartlabels;
    this.resourceWiseList = resourceWiseChartData.list;
  }
  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length > 0 || this.isList) && this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }
  //pending
  search(value: string, searchBy: string): void {
    debugger;
    this.isList = false;
    if (searchBy == '') {
      if (value.length >= 3) {
        this.pageOfItems = this.allPageOfItems.filter((val) =>
          (val.orderNumber != null && val.orderNumber.toLowerCase().includes(value.toLowerCase())) ||
          (val.regionName != null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
          (val.account != null && val.account.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountName != null && val.accountName.toLowerCase().includes(value.toLowerCase())) ||
          (val.taskId != null && val.taskId.toLowerCase().includes(value.toLowerCase())) ||
          // (val.adherenceType != null && val.adherenceType.toLowerCase().includes(value.toLowerCase())) ||
          //(val.status != null && val.status.toLowerCase().includes(value.toLowerCase())) ||
          (val.rom != null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
          (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
          (val.createdOnDisplayDate != null && val.createdOnDisplayDate.toLowerCase().includes(value.toLowerCase())) || 
          (val.scName != null && val.scName.toLowerCase().includes(value.toLowerCase()))
        );
      }
      else {
        this.pageOfItems = this.allPageOfItems;
      }
    }
    else {
      this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
    }
    this.items = this.pageOfItems;
    this.isList = true;

    this.filterByIsInCorrect();
  }

  filterByIsInCorrect() {
    if (this.isShowInCorrect) {
      //this.items = this.allPageOfItems;
    }
    else {
      this.items = this.items.filter((val) =>
        (val.isCorrect != null && val.isCorrect));

    }
    this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, null, 'accountName', true);
    this.sortText = "Account (Ascending)";

  }
  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    console.log(target);
    console.log(parent.parentElement.classList);
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");

    var sortResponse = this.commonMethods.commonSort(this.items, this.pageOfItems, this.reverse, event, column, isASc, dataTyp);
    this.sortText = sortResponse.sortText;
    this.reverse = sortResponse.isReverse
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  showInCorrectData() {
    debugger;
    if (this.isShowInCorrect) {
      this.items = this.allPageOfItems;
    }
    else {
      this.items = this.allPageOfItems.filter((val) =>
        (val.isCorrect != null && val.isCorrect == true));
    }

    this.search(this.searchText, '');
  }

  exportToExcel() {
    this.exportData = false;
    this.customHttpService.exportDataWithAuthentication("/Task/ExportControlRoomAdherenceList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&category=" + this.category + "&period=" + this.period+"&status="+this.status)
      .subscribe(data => {
        this.exportData = true;
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);

        var nowDate = formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = "ControlRoomAdherence" + "_" + this.category + "_" + this.status + "(" + this.period + ")_" + nowDate + ".xls";
        anchor.href = url;
        anchor.click();

      });

  }

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover: this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName")
  };

  graphHover(e, array) {
    if (array.length > 0) {
      e.target.style.cursor = 'pointer';
    } else {
      e.target.style.cursor = '';
    }
  }
  public romBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover: this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
  };

  onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

      this.search(activePoints[0]._model.label, searchBy);
      this.searchText = activePoints[0]._model.label;
      this.activeIdString = "3";

    }
  }

  setActiveIndex(evt: any) {
    console.log("set index");
    this.activeIdString = evt.nextId;
  }

  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  public regionBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105,159,177,0.2)',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];

  public romBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(77,20,96,0.3)',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public hbarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover: this.graphHover,
    onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "om")
  };
  public scHBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover: this.graphHover,
    onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "scName")
  };

  public resourceBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover: this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "resourceName")
  };


  onHBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

      this.activeIdString = "3";
      this.search(activePoints[0]._model.label, searchBy);
      this.searchText = activePoints[0]._model.label;
    }
  }
  public hbarChartType: ChartType = 'horizontalBar';
  public hbarChartLegend = true;

  public hbarChartData: ChartDataSets[] = [];
  public hBarChartSCData: ChartDataSets[] = [];


}

