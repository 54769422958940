import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import {SummaryCardComponent} from '../summary-card/summary-card.component';
import { ChartModel, CommonMethods } from 'src/app/services/commonmethods';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{DatePipe }from '@angular/common';

@Component({
  selector: 'app-service-delivery',
  templateUrl: './service-delivery.component.html',
  styleUrls: ['./service-delivery.component.scss'],
  providers: [DatePipe]
})
export class ServiceDeliveryComponent implements OnInit {

  category: string = "";
  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  period: string = "";
  sortText:string="";

  items = [];
  pageOfItems: Array<any>;

  regionwiseNumber: any = [];
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  label: string = "Percentage";
  searchText:string="";
breadCrumbCategory:string="";
activeIdString: any = "1";
isList:boolean=true;
  totCount:number;
  exportData:boolean=true;
  regionWiseList = [];
  scWiseList = [];
  omWiseList = [];
  romWiseList = [];
  barChartResourceData:any=[];
  barChartResourceLabels: any = [];
  resourceWiseList = [];
  remarkList = [];
  isSave: boolean = false;
  loggedInUserName:string="";
  selectedRemark:string;
  selectedDescription:string="";
  closeResult = '';
  isSelectSR:boolean=false;

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, 
    private customHttpService: CustomhttpService,private commonMethods:CommonMethods,
    private modalService: NgbModal,private datePipe:DatePipe) {
    this.category = this.activatedRoute.snapshot.params.category;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;

    console.log(this.category);

    if (this.category == "Service") {
      this.label="First Service Delivery %";
      this.breadCrumbCategory="First Service Delivery";
    }
    else if(this.category=="OTIFPer"){
      
      this.label="OTIF %";
      this.breadCrumbCategory="OTIF Percentage";
    }
    else if(this.category=="NonOTIFPer"){
      this.label="Non OTIF %";
      this.breadCrumbCategory="Non OTIF Percentage";
    }
    else if(this.category=="NonOTIFCount"){
      this.label="Non OTIF Count";
      this.breadCrumbCategory="Non OTIF";
    }
    else {
      this.label="OTIF Count";
      this.breadCrumbCategory="OTIF";
    }
    // else{
    //   this.label="Non OTIF %";
    //    this.breadCrumbCategory="Non OTIF";
    // }
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {
    console.log("sadsf");
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedInUserName = localStorage.getItem("omloggedinusername");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");
    this.remarkList =   this.commonMethods.nonOtifRemarkList;

    //   ["Customer is not taking the service",
    //  "Delayed start of an order",
    //  "Delivered but closure is pending",
    //  "Hicare missed",
    //  "Service is scheduled by customer after plan date",
    //  "Service Stopped by HiCare, Payment Pending",
    //  "SR Blocked"];

    this.getTaskList();
  }

  getNonOtifRemark(otifRemark){
    if(otifRemark==null){
      return "SR Blocked";
    }
    else return otifRemark;
  }

  getTaskList() {
    debugger;
    this.isSelectSR=false;
    console.log("Category" + this.category);
    var method;
    if (this.category == "Service") {
      method = "GetFirstServiceDeliveryList";
    }
    else {
      method = "GetOtifList";
    }
    
    this.isList=false;
    this.customHttpService.getDataWithAuthentication("/Task/" + method + "?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
     "&category="+this.category+ "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList=true;    
        this.totCount=this.items.length;

        //new changes
        if(this.category!="OTIFPer" && this.category!="NonOTIFPer"){
        this.groupByRegion(data.responseData);
        this.groupByROM(data.responseData);
        this.groupByOM(data.responseData);
        this.groupBySC(data.responseData);
        this.groupByResource(data.responseData);
        }
        this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null,'account_Name',true); 
        this.sortText="Account Name (Ascending)";  
      });

    if (this.category == "OTIFPer" ||this.category=="NonOTIFPer") {     
      this.customHttpService.getDataWithAuthentication("/Task/GetOtifPercentage?userId=" + this.loggedinUserId +
        "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
       "&category="+this.category+ "&type=" + this.type + "&period=" + this.period)
        .subscribe(data => {

          //new changes
          console.log(data.responseData.regionList);
          this.groupByRegion(data.responseData.regionList);
          this.groupByROM(data.responseData.romList);
          this.groupByOM(data.responseData.omList);
          this.groupBySC(data.responseData.scList);
          this.groupByResource(data.responseData.technicianList);

        });
    }
  }

  checkIsNonOtif(){
    debugger
  if(this.category=="NonOTIFPer"){
    return true;
  }
  else
    return false;
}
saveRemark(item){  
  debugger;
  if(item.nonOtifRemark!=null){
    var chkDescription= this.commonMethods.commonValidateString(item.nonOtifDescription); 
    item.isSpecialChar= !chkDescription;

    if(chkDescription){
    this.customHttpService.postData("/task/UpdateOtifRemark" ,
    {
      "AccountNo":item.account,
      "NonOtifRemark":item.nonOtifRemark,
      "NonOtifDescription":item.nonOtifDescription,
      "UserName":this.loggedInUserName,
      "UserId":this.loggedinUserId,
      "ExternalReferanceId":item.external_Reference_Id
    }).subscribe(data => {
      if (data.isSuccess) {
this.isSave = true;
var updatedDate =new Date();
let latest_date =this.datePipe.transform(updatedDate, 'dd MMM yyyy');
item.remarkLastUpdatedOnDisplayDate=latest_date.toString();
item.lastUpdatedBy=this.loggedInUserName;
    setTimeout(() => { this.isSave = false; }, 5000);
      }
    });
  }
}
}
  
changeCheckTxt(event:any,item){
  debugger;
  var chkRemark=  this.commonMethods.commonValidateString(event); 
  item.isSpecialChar= !chkRemark;
}

  groupByCommon(array, criteria: string) {
    //New change for FirstDelivery Percentage
    var distinctRegionList = array.map(x => x[criteria]).filter((value, index, self) => self.indexOf(value) == index);
    let result = [];

    let countArry = [];
    distinctRegionList.forEach(function (a) {
      var individualRegionList = [];
      individualRegionList = array.filter(x => x[criteria] == a);
      var taskCnt = individualRegionList.filter(x => x.scheduling_Status == "Completed").length;
      console.log(array.length + "taskCnt" + taskCnt);

      result.push({
        xAxis: individualRegionList[0][criteria],
        yAxis: (taskCnt / individualRegionList.length) * 100
      });
    }, Object.create(null));
  return result;
  }

  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
  }

  groupByRegion(array) {

    let regionWiseChartData:ChartModel; 
    if (this.category == "OTIFPer"||this.category=="NonOTIFPer") {
      regionWiseChartData = this.commonMethods.groupByRegionPercent(array,"Percentage");
      }
      else if(this.category=="OTIFCount"||this.category=="NonOTIFCount"){
        regionWiseChartData = this.commonMethods.groupByRegion(array,"Count");
      }
      else {
        regionWiseChartData = this.commonMethods.groupByRegionPercent(this.groupByCommon(array,"regionName"),"Percentage");
      }
    this.barChartData = regionWiseChartData.chartData;
    this.mbarChartLabels = regionWiseChartData.chartlabels;
    this.regionWiseList = regionWiseChartData.list;

  }

  groupByROM(array) {
    debugger
    let romWiseChartData:ChartModel; 
    if (this.category == "OTIFPer"||this.category=="NonOTIFPer") {
      romWiseChartData = this.commonMethods.groupByROMPercent(array,"Percentage");
      }
      else if(this.category=="OTIFCount"||this.category=="NonOTIFCount"){
        romWiseChartData = this.commonMethods.groupByROM(array,"Count");
      }
      else {
        romWiseChartData = this.commonMethods.groupByROMPercent(this.groupByCommon(array,"rom"),"Percentage");
      }
    this.romBarChartData = romWiseChartData.chartData;
    this.romMbarChartLabels = romWiseChartData.chartlabels;
    this.romWiseList = romWiseChartData.list;
  }

  groupByOM(array) {
    debugger

    let omWiseChartData:ChartModel; 
    if (this.category == "OTIFPer"||this.category=="NonOTIFPer") {
      omWiseChartData = this.commonMethods.groupByOMPercent(array,"Percentage");
      }
      else if(this.category=="OTIFCount"||this.category=="NonOTIFCount"){
        omWiseChartData = this.commonMethods.groupByOM(array,"Count");
      }
      else {
        omWiseChartData = this.commonMethods.groupByOMPercent(this.groupByCommon(array,"om"),"Percentage");
      }
    this.hbarChartData = omWiseChartData.chartData;
    this.hbarChartLabels = omWiseChartData.chartlabels;
    this.omWiseList = omWiseChartData.list;
  }

  groupBySC(array) {
    debugger
    let scWiseChartData:ChartModel; 
    if (this.category == "OTIFPer"||this.category=="NonOTIFPer") {
    scWiseChartData = this.commonMethods.groupBySCPercent(array,"Percentage");
    }
    else if(this.category=="OTIFCount"||this.category=="NonOTIFCount"){
      scWiseChartData = this.commonMethods.groupBySC(array,"Count");
    }
    else {
      scWiseChartData = this.commonMethods.groupBySCPercent(this.groupByCommon(array,"scName"),"Percentage");
    }
    this.hBarChartSCData = scWiseChartData.chartData;
    this.hBarChartSCLabels = scWiseChartData.chartlabels;
    this.scWiseList = scWiseChartData.list;
  }
  groupByResource(array){
    debugger
    let resourceWiseChartData:ChartModel; 
    if (this.category == "OTIFPer"||this.category=="NonOTIFPer") {
      resourceWiseChartData = this.commonMethods.groupByResourcePercent(array,"Percentage");
    }
    else if(this.category=="OTIFCount"||this.category=="NonOTIFCount"){
      resourceWiseChartData = this.commonMethods.groupByResource(array,"Count");
    }
    else {
      resourceWiseChartData = this.commonMethods.groupByResourcePercent(this.groupByCommon(array,"resourceName"),"Percentage");
    }
    this.barChartResourceData = resourceWiseChartData.chartData;
    this.barChartResourceLabels = resourceWiseChartData.chartlabels;
    this.resourceWiseList = resourceWiseChartData.list;
  }
 
  checkLoading() {
    if (this.items != undefined && this.items != null&& (this.items.length>0 || this.isList)&& this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }

  search(value: string, searchBy: string): void {
    this.isList=false;
    if (searchBy == '') {
    if (value.length >= 3) {
      this.pageOfItems = this.allPageOfItems.filter((val) =>
      (val.regionName!=null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
      (val.rom!=null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
        (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
      (val.account_Name != null && val.account_Name.toLowerCase().includes(value.toLowerCase())) ||
      (val.scName!=null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
      (val.resourceName!=null && val.resourceName.toLowerCase().includes(value.toLowerCase())) ||
      (val.originalAssStartdatetimeDisplayValue!=null && val.originalAssStartdatetimeDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
      (val.originalAssEnddatetimeDisplayValue!=null && val.originalAssEnddatetimeDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
      //  (val.onSiteDatetime!=null && val.onSiteDatetime.toLowerCase().includes(value.toLowerCase())) ||
      (val.completionDatetimeDisplayValue!=null && val.completionDatetimeDisplayValue.toLowerCase().includes(value.toLowerCase())) ||
      (val.order_Number != null && val.order_Number.toLowerCase().includes(value.toLowerCase())) ||
      (val.scheduling_Status != null && val.scheduling_Status.toLowerCase().includes(value.toLowerCase())) ||      
      (val.nonOtifRemark != null && val.nonOtifRemark.toLowerCase().includes(value.toLowerCase())) ||      
      (val.nonOtifRemarkBy != null && val.nonOtifRemarkBy.toLowerCase().includes(value.toLowerCase())) ||     
      
      (val.nonOtifDescription != null && val.nonOtifDescription.toLowerCase().includes(value.toLowerCase())) ||
      (val.service_Plan !=null && val.service_Plan.toLowerCase().includes(value.toLowerCase())));
    }
    else {
      this.pageOfItems = this.allPageOfItems;
    }
  }
  else{
    this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
  }
    this.items = this.pageOfItems;
    this.isList=true;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
      debugger;
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    var colName="";

    if (event.srcElement.nodeName == 'SPAN') {   
      var index=event.srcElement.innerHTML.indexOf( "<i" );
      colName=event.srcElement.innerHTML.substr(0,index);
      console.log(colName);
     }
     var sortedBy;
     if(isASc){
       sortedBy="Ascending";
     }
     else{
        sortedBy="Descending";
     }
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      if(item.classList!=undefined){
      item.classList.remove("selectBackColor");
      }
    });

    parent.classList.add("selectBackColor");

    var sortResponse=  this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event,column,isASc,dataTyp);
    this.sortText=sortResponse.sortText; 
    this.reverse=sortResponse.isReverse;
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }

  
exportToExcel(){
  debugger
  var zrmId = localStorage.getItem("SelectedZRMId");
  var rmId = localStorage.getItem("SelectedRMId");
  var region = localStorage.getItem("SelectedRegion");

  var method;
    if (this.category == "Service") {
      method = "ExportFirstServiceDeliveryList";
    }
    else {
      method = "ExportOtifList";
    }

    this.exportData=false;
  this.customHttpService.exportDataWithAuthentication("/Task/" + method + "?userId=" + this.loggedinUserId +
  "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
  "&category="+this.category+ "&type=" + this.type + "&period=" + this.period)
  .subscribe(data=>{
    this.exportData=true;
    let b: any = new Blob([data], {type: 'application/vnd.ms-excel' });
    var url = window.URL.createObjectURL(b);
    //window.open(url);
    
    var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
    var anchor = document.createElement("a");
    anchor.download = this.breadCrumbCategory +"_"+this.type+"("+ this.period+")_"+nowDate+".xls";
    anchor.href = url;
    anchor.click();

  });

}
onKeyPressCheckSpecialCharacter(event: any){
  debugger
  return this.commonMethods.commonCheckSpecialCharacter(event);         
}
onPaste(event:any) {
  return this.commonMethods.commonRestrictPaste(event); 
}

public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName") 
  };

  graphHover(e, array) {
    if(array.length > 0) {
        e.target.style.cursor = 'pointer';
    }else {
        e.target.style.cursor = '';
    }
}
public romBarChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  onHover:this.graphHover,
  onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
};

onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
  debugger
  const chart = activeElems[0]._chart;
  const activePoints = chart.getElementAtEvent(clickEvt);
  console.log(activePoints);

  if (activePoints.length > 0) {
    const clickedElementIndex = activePoints[0]._index;
    const clickedPeridIndex = activePoints[0]._datasetIndex;
    console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

    this.activeIdString = "3";
    this.search(activePoints[0]._model.label, searchBy);
    this.searchText = activePoints[0]._model.label;
   

  }
}
public resourceBarChartOptions: ChartOptions = {
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  onHover:this.graphHover,
  onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "resourceName")
};

setActiveIndex(evt: any) {
  console.log("set index");
  this.activeIdString = evt.nextId;
}

  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  public regionBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105,159,177,0.2)',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];

  public romBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(77,20,96,0.3)',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];

  // events
  public chartClicked(e: any): void {
    //console.log(e);
  }

  public chartHovered(e: any): void {
    //console.log(e);
  }

  public hbarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "om")
  };

  public scHBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "scName")
  };

  public hbarChartType: ChartType = 'horizontalBar';
  public hbarChartLegend = true;

  public hbarChartData: ChartDataSets[] = [];
  public hBarChartSCData: ChartDataSets[] = [];

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}


