import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';

@Component({
  selector: 'app-delivery-percent',
  templateUrl: './delivery-percent.component.html',
  styleUrls: ['./delivery-percent.component.scss']
})
export class DeliveryPercentComponent implements OnInit {

  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  srStatus: string = "";
  period: string = "";
  exportData:boolean=true;
  isList:boolean=true;
  totCount:number;

  items = [];
  pageOfItems: Array<any>;

  amtIn: number = 100000;
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  searchText: string = "";
  chartLabel: string = "Delivery Percentage %";
  activeIdString: any = "1";

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService) {
    this.srStatus = this.activatedRoute.snapshot.params.srStatus;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;
  }
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  ngOnInit() {
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");

    console.log("deliveryPercentage");

    this.getDeliveryPercentage();
  }

  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length>0 || this.isList)&& this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }
  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
  }

  getDeliveryPercentage(){
    this.isList=false;

    this.customHttpService.getDataWithAuthentication("/Delivery/GetDeliveryPercentageSummary?userId=" + this.loggedinUserId +
    "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
    "&srStatus=" + this.srStatus + "&type=" + this.type + "&period=" + this.period)
    .subscribe(data => {
      
      this.groupByRegion(data.responseData.regionList);
      this.groupByROM(data.responseData.romList);
      this.groupByOM(data.responseData.omList);
      this.groupBySC(data.responseData.scList);

      this.isList=true;
    });

  }

  groupByRegion(array) {
    // var distinctRegionList = array.map(x => x.regionName).filter((value, index, self) => self.indexOf(value) == index);
    // let result = [];

     let countArry = [];

    
    //var result= this.groupByCommon(array,"regionName");
    var result = this.generatePercentCommon(array);

    for (let i = result.length - 1; i >= 0; i--) {
      this.mbarChartLabels.push(result[i].Name);
      countArry.push(this.roundNumber( result[i].totalPresent));
    }
    this.barChartData = [
      { data: countArry, label: this.chartLabel}
     // {data:[12,23,45],label:'Hhjhjh', stack: 'a'}
    ];

  }

  groupByROM(array) {

    // var distinctROMList = array.map(x => x.rom).filter((value, index, self) => self.indexOf(value) == index);
    // let result = [];
     let countArry = [];

    //var result= this.groupByCommon(array,"rom");
    var result = this.generatePercentCommon(array);

    for (let i = result.length - 1; i >= 0; i--) {
      this.romMbarChartLabels.push(result[i].Name);
      countArry.push(this.roundNumber( result[i].totalPresent));
    }
    this.romBarChartData = [
      { data: countArry, label: this.chartLabel}
    ];

  }

  groupByOM(array) {

    // var distinctOMList = array.map(x => x.om).filter((value, index, self) => self.indexOf(value) == index);
    // let result = [];
     let countArry = [];


    //var result=this.groupByCommon(array,"om");
    var result = this.generatePercentCommon(array);
    
    for (let i = result.length - 1; i >= 0; i--) {
      this.hbarChartLabels.push(result[i].Name);
      countArry.push(this.roundNumber( result[i].totalPresent));
    }
    this.hbarChartData = [
      { data: countArry, label: this.chartLabel }
    ];    

    this.omWiseData = result;
  }

  groupBySC(array){
    // var distinctSCList = array.map(x => x.serviceCenter).filter((value, index, self) => self.indexOf(value) == index);
    // let result = [];
     let countArry = [];

    

    //var result=this.groupByCommon(array,"serviceCenter");
    var result = this.generatePercentCommon(array);

    for (let i = result.length - 1; i >= 0; i--) {
      //console.log(result[i].sc);
      this.hBarChartSCLabels.push(result[i].Name);
      countArry.push(this.roundNumber( result[i].totalPresent));
    }
    this.hBarChartSCData = [
      { data: countArry, label: this.chartLabel}
    ];
    //this.scWiseData = result;

  }

  generatePercentCommon(array) {

    let result = [];
    let countArry = [];

    array.forEach(function (a) {
      result.push({
        Name: a.xAxis,
        totalPresent: a.yAxis
      });
    }, Object.create(null));

    console.log(result);

    return result;    

  }


public barChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true,
  scales:{yAxes: [{ ticks: {
    beginAtZero: true
}

}]} ,
series:{stacking: 'normal'},
onHover:this.graphHover,
//onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems,"regionName")
};

public romBarChartOptions: any = {
  scaleShowVerticalLines: false,
  responsive: true,
  scales: {
    yAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  onHover:this.graphHover,
 // onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
};

onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
  const chart = activeElems[0]._chart;
  const activePoints = chart.getElementAtEvent(clickEvt);
  console.log(activePoints);

  if (activePoints.length > 0) {
    const clickedElementIndex = activePoints[0]._index;
    const clickedPeridIndex=activePoints[0]._datasetIndex;      
    console.log( "active"+this.activeIdString+" "+activePoints[0]._model.label);
    
    this.activeIdString="2";     
    //this.search(activePoints[0]._model.label,searchBy);
    this.searchText=activePoints[0]._model.label;       
  }
}
setActiveIndex(evt: any) {
  console.log("set index");
  this.activeIdString = evt.nextId;
}
public barChartType: string = 'bar';
public barChartLegend: boolean = false;

public regionBarChartColors: Array<any> = [
  {
    backgroundColor: 'rgba(105,159,177,0.2)',
    borderColor: 'rgba(105,159,177,1)',
    pointBackgroundColor: 'rgba(105,159,177,1)',
    pointBorderColor: '#fafafa',
    pointHoverBackgroundColor: '#fafafa',
    pointHoverBorderColor: 'rgba(105,159,177)'
  }
];

public romBarChartColors : Array<any> = [    
  {
    backgroundColor: 'rgba(77,20,96,0.3)',
    borderColor: 'rgba(77,20,96,1)',
    pointBackgroundColor: 'rgba(77,20,96,1)',
    pointBorderColor: '#fff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(77,20,96,1)'
  }
];

// events
public chartClicked(e: any): void {
  console.log(e);
}

public chartHovered(e: any): void {
  console.log(e);
}


public hbarChartOptions: ChartOptions = {
  responsive: true,
  scales:{xAxes: [{ ticks: {
    beginAtZero: true
}
}]} ,
onHover:this.graphHover,
//onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems,"om")
  
};
public scHBarChartOptions: ChartOptions = {
  responsive: true,
  scales: {
    xAxes: [{
      ticks: {
        beginAtZero: true
      }
    }]
  },
  onHover:this.graphHover,
  //onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "scName")
};

graphHover(e, array) {
  if(array.length > 0) {
      e.target.style.cursor = 'pointer';
  }else {
      e.target.style.cursor = '';
  }
}

public hbarChartType: ChartType = 'horizontalBar';
public hbarChartLegend = true;

public hbarChartData: ChartDataSets[] =[];
public hBarChartSCData: ChartDataSets[] =[];

}