import { Component, OnInit } from '@angular/core';
import { CustomhttpService } from 'src/app/services/customhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import {formatDate } from '@angular/common';
import { SortService } from 'src/app/services/sortservice';
import { CommonMethods } from 'src/app/services/commonmethods';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

  selectedSC: string = "";
  selectedROM: string = "";
  selectedROMName: string = "";
  selectedOM: string = "";
  selectedOMName: string = "";
  selectedRegion: string = "";
  loggedinUserType: string = "";
  loggedinUserId: string = "";
  type: string = "";
  allPageOfItems: Array<any>;
  reverse: boolean = false;
  srStatus: string = "";
  period: string = "";
  exportData:boolean=true;
  isList:boolean=true;
  totCount:number;

  items = [];
  pageOfItems: Array<any>;

  amtIn: number = 100000;
  mbarChartLabels: any = [];
  barChartData: any = [];
  romMbarChartLabels: any = [];
  romBarChartData: any = [];
  omWiseData: any = [];
  scWiseData: any = [];
  hbarChartLabels: any = [];
  hBarChartSCLabels: any = [];
  searchText: string = "";
  chartLabel: string = "Total SV Value";
  activeIdString: any = "1";
  sortText:string="";

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute, private customHttpService: CustomhttpService,private sortService:SortService,private commonMethods:CommonMethods) {
    this.srStatus = this.activatedRoute.snapshot.params.srStatus;
    this.type = this.activatedRoute.snapshot.params.type;
    this.period = this.activatedRoute.snapshot.params.period;
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  ngOnInit() {
    this.selectedSC = localStorage.getItem("SelectedSC");
    this.selectedROM = localStorage.getItem("SelectedROM");
    this.selectedROMName = localStorage.getItem("SelectedROMName");
    this.selectedOM = localStorage.getItem("SelectedOM");
    this.selectedOMName = localStorage.getItem("SelectedOMName");
    this.selectedRegion = localStorage.getItem("SelectedRegion");
    this.loggedinUserId = localStorage.getItem("omloggedinuserid");
    this.loggedinUserType = localStorage.getItem("omloggedinusertype");

    this.getDeliveryList();
  }

  getDeliveryList() {

    this.isList=false;

    this.customHttpService.getDataWithAuthentication("/Delivery/GetOMDeliveryPlanSRList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&srStatus=" + this.srStatus + "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => {
        console.log(data.responseData)
        this.items = data.responseData;
        this.allPageOfItems = data.responseData;
        this.isList=true;

        this.totCount= this.items.reduce(function(prev,cur){
          return prev+cur.svValue;
        },0);

        //new changes
        this.groupByRegion(data.responseData);
        this.groupByROM(data.responseData);
        this.groupByOM(data.responseData);
        this.groupBySC(data.responseData);

        this.commonMethods.commonSort(this.items, this.pageOfItems,this.reverse,null, 'accountName',true); 
        this.sortText="Account Name (Ascending)";  
      });
  }

  groupByCommon(array, criteria: string) {

    debugger;
    var distinctRegionList = array.map(x => x[criteria]).filter((value, index, self) => self.indexOf(value) == index);
    let result = [];

    let countArry = [];

    distinctRegionList.forEach(function (a) {
      var individualRegionList = [];
      individualRegionList = array.filter(x => x[criteria] == a);

      result.push({
        Name: individualRegionList[0][criteria],
        //totalPresent: individualRegionList.length
        value: individualRegionList.reduce(function (prev, cur) {
          return prev + cur.svValue;
        }, 0),
      });
    }, Object.create(null));

    console.warn("delivery", result);

    //Sort changes    
    if(criteria=="regionName") criteria="region";
    if(criteria=="scName")criteria="serviceCenter";

    var sortResult= this.sortService.sortListByConfiguration(result,criteria);   
    return sortResult;
  }

  groupByRegion(array) {

    let countArry = [];
    var result = this.groupByCommon(array, "regionName");     
    
    for (let i = result.length - 1; i >= 0; i--) {
      this.mbarChartLabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value / this.amtIn));
      //console.log(result[i].deliveryPer);

      

    }
    this.barChartData = [
      { data: countArry, label: this.chartLabel }
    ];

  }

  groupByROM(array) {
    let countArry = [];
    var result = this.groupByCommon(array, "rom");

    for (let i = result.length - 1; i >= 0; i--) {
      this.romMbarChartLabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value / this.amtIn));
    }
    this.romBarChartData = [
      { data: countArry, label: this.chartLabel }
    ];

  }

  groupByOM(array) {

    let countArry = [];

    var result = this.groupByCommon(array, "om");

    for (let i = result.length - 1; i >= 0; i--) {
      this.hbarChartLabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value / this.amtIn));
    }
    this.hbarChartData = [
      { data: countArry, label: this.chartLabel }
    ];

    this.omWiseData = result;
  }

  groupBySC(array) {

    let countArry = [];
    var result = this.groupByCommon(array, "scName");

    for (let i = result.length - 1; i >= 0; i--) {
      //console.log(result[i].sc);
      this.hBarChartSCLabels.push(result[i].Name);
      countArry.push(this.roundNumber(result[i].value / this.amtIn));
    }
    this.hBarChartSCData = [
      { data: countArry, label: this.chartLabel }
    ];
    //this.scWiseData = result;

  }

  roundNumber(no: any) {
    return parseFloat(no).toFixed(2);
  }
  listRoundNumber(no: any) {
    return Math.round(no);
  }
  checkLoading() {
    if (this.items != undefined && this.items != null && (this.items.length>0 || this.isList)&& this.exportData) {
      return true;
    }
    else {
      return false;
    }
  }

  search(value: string, searchBy: string): void {
    this.isList=false;
    if (searchBy == '') {
      if (value.length >= 3) {

        this.pageOfItems = this.allPageOfItems.filter((val) => val.planDateDisplayValue.toLowerCase().includes(value.toLowerCase()) ||
          (val.srName != null && val.srName.toLowerCase().includes(value.toLowerCase())) ||
          (val.orderNno != null && val.orderNno.toLowerCase().includes(value.toLowerCase())) ||
          (val.scName != null && val.scName.toLowerCase().includes(value.toLowerCase())) ||
          (val.rom != null && val.rom.toLowerCase().includes(value.toLowerCase())) ||
          (val.om != null && val.om.toLowerCase().includes(value.toLowerCase())) ||
          (val.regionName != null && val.regionName.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountNo != null && val.accountNo.toLowerCase().includes(value.toLowerCase())) ||
          (val.accountName != null && val.accountName.toLowerCase().toString().includes(value.toLowerCase())) ||
          (val.accountType != null && val.accountType.toLowerCase().includes(value.toLowerCase())) ||
          (val.status != null && val.status.toString().toLowerCase().includes(value.toLowerCase())) ||
          (val.svValue != null && val.svValue.toString().includes(value)));
      }
      else {
        this.pageOfItems = this.allPageOfItems;
      }
    }
    else {
      this.pageOfItems = this.allPageOfItems.filter((val) => (val[searchBy] != null && val[searchBy].toLowerCase().includes(value.toLowerCase())));
     
    
    }
    this.items = this.pageOfItems;
    this.isList=true;
  }

  sortCol(column: string, isASc: boolean, event, dataTyp?: any): void {
    //sortCol(column: string): void {
    console.log("sort" + isASc);

    var target = event.target;
    var parent = target.parentElement;
    var colName="";
   
    parent.parentElement.classList.remove("selectBackColor");
    parent.parentElement.childNodes.forEach(function (item) {
      item.classList.remove("selectBackColor");
    });

    parent.classList.add("selectBackColor");
   
    var sortResponse= this.commonMethods.commonSort(this.items,this.pageOfItems,this.reverse,event, column,isASc,dataTyp);
    this.sortText=sortResponse.sortText; 
    this.reverse=sortResponse.isReverse;
    this.onChangePage(sortResponse.listItem.slice(0, 20));
  }
  
  exportToExcel() {
    this.exportData=false;
    this.customHttpService.exportDataWithAuthentication("/Delivery/ExportOMDeliveryList?userId=" + this.loggedinUserId +
      "&userTypeName=" + this.loggedinUserType + "&region=" + this.selectedRegion + "&rom=" + this.selectedROM + "&om=" + this.selectedOM + "&servicecenter=" + this.selectedSC +
      "&srStatus=" + this.srStatus + "&type=" + this.type + "&period=" + this.period)
      .subscribe(data => {
        this.exportData=true;
        let b: any = new Blob([data], { type: 'application/vnd.ms-excel' });
        var url = window.URL.createObjectURL(b);
        //window.open(url);

        var nowDate= formatDate(new Date(), 'ddMMyy_THHmmss', 'en-US', '+0530');
        var anchor = document.createElement("a");
        anchor.download = "Delivery_" +this.srStatus.replace('SR','') +"_"+this.type+"("+ this.period+")_"+nowDate+".xls";
        anchor.href = url;
        anchor.click();

      });

  }

  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "regionName")
  };

  graphHover(e, array) {
    if(array.length > 0) {
        e.target.style.cursor = 'pointer';
    }else {
        e.target.style.cursor = '';
    }
}
  public romBarChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onBarChartClick(clickEvt, activeElems, "rom")
  };

  onBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

      this.search(activePoints[0]._model.label, searchBy);
      this.searchText = activePoints[0]._model.label;
      this.activeIdString = "2";

    }
  }

  setActiveIndex(evt: any) {
    console.log("set index");
    this.activeIdString = evt.nextId;
  }

  public barChartType: string = 'bar';
  public barChartLegend: boolean = false;

  public regionBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(105,159,177,0.2)',
      borderColor: 'rgba(105,159,177,1)',
      pointBackgroundColor: 'rgba(105,159,177,1)',
      pointBorderColor: '#fafafa',
      pointHoverBackgroundColor: '#fafafa',
      pointHoverBorderColor: 'rgba(105,159,177)'
    }
  ];

  public romBarChartColors: Array<any> = [
    {
      backgroundColor: 'rgba(77,20,96,0.3)',
      borderColor: 'rgba(77,20,96,1)',
      pointBackgroundColor: 'rgba(77,20,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,20,96,1)'
    }
  ];

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }


  public hbarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "om")
  };
  public scHBarChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    onHover:this.graphHover,
    onClick: (clickEvt, activeElems) => this.onHBarChartClick(clickEvt, activeElems, "scName")
  };

  onHBarChartClick(clickEvt: MouseEvent, activeElems: Array<any>, searchBy: string) {
    const chart = activeElems[0]._chart;
    const activePoints = chart.getElementAtEvent(clickEvt);
    console.log(activePoints);

    if (activePoints.length > 0) {
      const clickedElementIndex = activePoints[0]._index;
      const clickedPeridIndex = activePoints[0]._datasetIndex;
      console.log("active" + this.activeIdString + " " + activePoints[0]._model.label);

      this.activeIdString = "2";
      this.search(activePoints[0]._model.label, searchBy);
      this.searchText = activePoints[0]._model.label;
    }
  }
  public hbarChartType: ChartType = 'horizontalBar';
  public hbarChartLegend = true;

  public hbarChartData: ChartDataSets[] = [];
  public hBarChartSCData: ChartDataSets[] = [];

}

function sort(a, b, asc) {
  var result;

  /* Default ascending order */
  if (typeof asc == "undefined") asc = true;

  //console.log("sort " + a + " " + b);
  if (asc) {
    if (a == null) return -1;
    if (b == null) return 1;
  }
  else {
    if (b == null) return -1;
    if (a == null) return 1;
  }
  if (a === null && b === null) return 0;

  result = a - b;

  if (isNaN(result)) {
    return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
  }
  else {
    return (asc) ? result : -result;
  }
}
